/*
 * File: AvailabilityChip.tsx
 * Project: meki
 * File Created: Thursday, 22nd December 2022 5:51:54 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Saturday, 28th January 2023 9:19:53 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { styled } from '@mui/system';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import StoreIcon from '@mui/icons-material/Store';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { AvailabilityType } from '@interfaces';
import { ReactNode } from 'react';
import { getWeekdayName, next24hrsDay } from '@utils';
import { addDays } from 'date-fns';
import { usePermissions } from '@hooks/usePermissions';

const background: Record<AvailabilityType, string> = {
  AVAILABLE: '#E6F3FA',
  AVAILABLE_24_HRS: '#eff5ef',
  CHECK: '#E6F3FA',
  HIDDEN: '#FFE2E2',
  REPRESENTATION: '#E6F3FA',
  SOLD_OUT: '#FFE2E2',
  TO_CONFIRM: '#FFF0D6',
  UNAVAILABLE: '#FFE2E2',
};
const color: Record<AvailabilityType, string> = {
  AVAILABLE: '#15365F',
  AVAILABLE_24_HRS: '#284525',
  CHECK: '#2D0076',
  HIDDEN: '#602D11',
  REPRESENTATION: '#15365F',
  SOLD_OUT: '#602D11',
  TO_CONFIRM: '#603E11',
  UNAVAILABLE: '#602D11',
};
const fill: Record<AvailabilityType, string> = {
  AVAILABLE: '#0277BD',
  AVAILABLE_24_HRS: '#00A650',
  CHECK: '#731EFF',
  HIDDEN: '#FF0000',
  REPRESENTATION: '#0277BD',
  SOLD_OUT: '#FF0000',
  TO_CONFIRM: '#FFA200',
  UNAVAILABLE: '#FF0000',
};
const label: Record<AvailabilityType, string> = {
  AVAILABLE: '',
  get AVAILABLE_24_HRS() {
    const next = next24hrsDay();
    const today = new Date(Date.now());
    if (today.toDateString() === next.toDateString()) return 'Recibe hoy';
    const tomorrow = addDays(today, 1);
    if (tomorrow.toDateString() === next.toDateString()) return 'Recibe mañana';
    return 'Recibe el ' + getWeekdayName(next).toLowerCase();
  },
  CHECK: 'Venta presencial',
  HIDDEN: 'Agotado',
  REPRESENTATION: '',
  SOLD_OUT: 'Agotado',
  TO_CONFIRM: 'A pedido',
  UNAVAILABLE: 'Agotado',
};
const localLabel: Record<AvailabilityType, string> = {
  AVAILABLE: 'Venta online',
  AVAILABLE_24_HRS: 'Disponible',
  CHECK: 'Disponible',
  HIDDEN: 'Agotado',
  REPRESENTATION: 'Venta online',
  SOLD_OUT: 'Agotado',
  TO_CONFIRM: 'A pedido',
  UNAVAILABLE: 'Agotado',
};

const icon: Record<AvailabilityType, ReactNode> = {
  AVAILABLE: '',
  AVAILABLE_24_HRS: <LocalShippingOutlinedIcon />,
  CHECK: <StoreIcon />,
  HIDDEN: <WarningAmberIcon />,
  REPRESENTATION: '',
  SOLD_OUT: <WarningAmberIcon />,
  TO_CONFIRM: <AccessTimeIcon />,
  UNAVAILABLE: <WarningAmberIcon />,
};
const localIcon: Record<AvailabilityType, ReactNode> = {
  AVAILABLE: <PhoneIphoneIcon />,
  AVAILABLE_24_HRS: <StoreIcon />,
  CHECK: <StoreIcon />,
  HIDDEN: <WarningAmberIcon />,
  REPRESENTATION: <PhoneIphoneIcon />,
  SOLD_OUT: <WarningAmberIcon />,
  TO_CONFIRM: <AccessTimeIcon />,
  UNAVAILABLE: <WarningAmberIcon />,
};
const Container = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -8px;
`;
const ChipContainer = styled('div')<{ availability: AvailabilityType }>`
  background-color: ${(props) => background[props.availability]};
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  border-radius: 16px;
  margin: auto;
  width: fit-content;
  margin: 10px auto;
`;
const IconContainer = styled('div')<{ availability: AvailabilityType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    height: 14px;
    width: 14px;
    font-size: 9px;
    fill: ${(props) => fill[props.availability]};
  }
`;
const Label = styled('span')<{ availability: AvailabilityType }>`
  color: ${(props) => color[props.availability]};
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
`;
const EmptyChip = styled('div')`
  height: 21px;
  margin: 10px auto;
`;
interface AvailabilityChipProps {
  availability: AvailabilityType;
  isCheck?: boolean;
}

export const AvailabilityChip = (props: AvailabilityChipProps) => {
  const { availability, isCheck } = props;
  const { userCan } = usePermissions();
  const isLocalVendor = userCan('LOCAL_VENDOR');
  const parsedAvailability = ['UNAVAILABLE', 'SOLD_OUT', 'HIDDEN'].includes(
    availability,
  )
    ? availability
    : isCheck && availability === 'AVAILABLE_24_HRS' && isLocalVendor
    ? availability
    : isCheck
    ? 'CHECK'
    : availability;
  const text = isLocalVendor
    ? localLabel[parsedAvailability]
    : label[parsedAvailability];
  if (!text) return <EmptyChip />;
  return (
    <Container>
      <ChipContainer availability={parsedAvailability}>
        <IconContainer availability={parsedAvailability}>
          {isLocalVendor
            ? localIcon[parsedAvailability]
            : icon[parsedAvailability]}
        </IconContainer>
        <Label availability={parsedAvailability}>{text}</Label>
      </ChipContainer>
    </Container>
  );
};
