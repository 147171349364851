/*
 * File: commune.ts
 * Project: meki
 * File Created: Friday, 23rd April 2021 11:13:43 am
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Wednesday, 12th October 2022 4:45:14 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { normalizeString } from './strings';

interface DeliveryZone {
  zoneType: 'ZONE_A' | 'ZONE_B' | 'ZONE_C' | 'NO_ZONE';
  deliveryCost?: number;
}

interface Commune {
  name: string;
  zone: DeliveryZone;
}

export const communeZones: Commune[] = [
  {
    name: 'Lo Barnechea',
    zone: { zoneType: 'ZONE_A', deliveryCost: 2990 },
  },
  {
    name: 'Vitacura',
    zone: { zoneType: 'ZONE_A', deliveryCost: 2990 },
  },
  {
    name: 'Las Condes',
    zone: { zoneType: 'ZONE_A', deliveryCost: 2990 },
  },
  {
    name: 'Providencia',
    zone: { zoneType: 'ZONE_A', deliveryCost: 2990 },
  },
  {
    name: 'La Reina',
    zone: { zoneType: 'ZONE_A', deliveryCost: 2990 },
  },
  {
    name: 'Ñuñoa',
    zone: { zoneType: 'ZONE_A', deliveryCost: 2990 },
  },
  {
    name: 'Huechuraba',
    zone: { zoneType: 'ZONE_A', deliveryCost: 2990 },
  },
  {
    name: 'Conchalí',
    zone: { zoneType: 'ZONE_A', deliveryCost: 2990 },
  },
  {
    name: 'Independencia',
    zone: { zoneType: 'ZONE_A', deliveryCost: 2990 },
  },
  {
    name: 'Recoleta',
    zone: { zoneType: 'ZONE_A', deliveryCost: 2990 },
  },
  {
    name: 'Santiago',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Macul',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Peñalolén',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Quilicura',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Renca',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'San Miguel',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'San Joaquin',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'La Granja',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'La Florida',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Quinta Normal',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Pedro Aguirre Cerda',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'La Cisterna',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'San Ramón',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'La Pintana',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Cerro Navia',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Lo Prado',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Lo Espejo',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Estación Central',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Cerrillos',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Maipú',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Pudahuel',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'El Bosque',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Colina',
    zone: { zoneType: 'ZONE_B', deliveryCost: 2990 },
  },
  {
    name: 'Lampa',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Til-Til',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Puente Alto',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'San José de Maipo',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Pirque',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'San Bernardo',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Calera de Tango',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Buin',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Paine',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Alhué',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Curacaví',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'María Pinto',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Melipilla',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'San Pedro',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Talagante',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Isla de Maipo',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Padre Hurtado',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'El Monte',
    zone: { zoneType: 'NO_ZONE' },
  },
  {
    name: 'Peñaflor',
    zone: { zoneType: 'NO_ZONE' },
  },
];

export function getCommuneZone(communeName?: string): DeliveryZone {
  if (!communeName) return null;
  const communeInfo = communeZones.find(
    (comm) => normalizeString(comm.name) == normalizeString(communeName),
  );
  return communeInfo?.zone ?? { zoneType: 'ZONE_B' };
}
