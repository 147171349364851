/*
 * File: getUTM.ts
 * Project: meki
 * File Created: Wednesday, 17th August 2022 1:36:31 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Wednesday, 17th August 2022 1:37:04 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

export function getUTM() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.r) {
    return Object.assign({}, params, {
      utm_source: 'offline',
      utm_medium: 'a',
    });
  }
  if (params.gclid) {
    return Object.assign({}, params, {
      utm_source: 'google',
      utm_medium: 'cpc',
    });
  }
  return params;
}
