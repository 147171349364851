import { Address } from '@interfaces';

export const formatCreditCardNumber = (input: string | number) =>
  String(input).replace(/\D/g, '').padStart(12, '● ');
export const formatAddress = (address?: Address): string => {
  return address
    ? (address.street ?? '') +
        ' ' +
        (address.streetNumber ? address.streetNumber?.toString() + ', ' : '') +
        address.commune ?? ''
    : '';
};
export const normalizeString = (str: string) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};
export const capitalizeWord = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const ignoreWords = [
  'recombinante',
  'humana',
  'clorhidrato',
  'propionato',
  'fumorato',
];
export function cleanActivePrinciples(activePrinciple = ''): string {
  return activePrinciple
    .replace(/ de /gi, ' ')
    .replace(new RegExp('(' + ignoreWords.join('|') + ')', 'ig'), '')
    .replace(/\s+/g, ' ')
    .toLowerCase()
    .trim();
}

export function cleanQuery(query: string): string {
  const clean = (query || '')
    .replace(/\s+/g, ' ')
    .replace(/\//g, '')
    .replace(/ de /gi, ' ');
  if (clean.split(/\s+/).length > 1)
    return clean.replace(/(caja|ampolla|cajas|ampollas)/gi, '').trim();
  return clean.trim();
}
