/*
 * File: customTheme.ts
 * Project: meki
 * File Created: Tuesday, 15th November 2022 10:11:29 am
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Monday, 20th November 2023 12:15:29 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#0277BD',
      light: '#58A5F0',
      dark: '#004C8C',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00848F',
      light: '#4FB3BF',
      dark: '#005662',
      contrastText: '#fff',
    },
    success: {
      main: '#4CAF50',
      light: '#81C784',
      dark: '#388E3C',
    },
    info: {
      main: '#2196F3',
      light: '#BBDEFB',
      dark: '#0B79D0',
    },
    warning: {
      main: '#FF9800',
      light: '#FFB74D',
      dark: '#F57C00',
    },
    error: {
      main: '#F44336',
      light: '#E57373',
      dark: '#D32F2F',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontSize: '93px',
      lineHeight: '131px',
      fontWeight: 300,
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: '58px',
      lineHeight: '81px',
      fontWeight: 400,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontSize: '46px',
      lineHeight: '64px',
      fontWeight: 400,
      letterSpacing: '0px',
    },
    h4: {
      fontSize: '33px',
      lineHeight: '47px',
      fontWeight: 400,
      letterSpacing: '0.25px',
    },
    h5: {
      fontSize: '23px',
      lineHeight: '32px',
      fontWeight: 400,
      letterSpacing: '0px',
    },
    h6: {
      fontSize: '19px',
      lineHeight: '27px',
      fontWeight: 500,
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: '15px',
      lineHeight: '21px',
      fontWeight: 400,
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '13px',
      lineHeight: '19px',
      fontWeight: 500,
      letterSpacing: '0.1px',
    },
    body1: {
      fontSize: '15px',
      lineHeight: '21px',
      fontWeight: 400,
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '13px',
      lineHeight: '19px',
      fontWeight: 400,
      letterSpacing: '0.15px',
    },
    caption: {
      fontSize: '12px',
      lineHeight: '17px',
      fontWeight: 400,
      letterSpacing: '0.4px',
    },
    overline: {
      fontSize: '10px',
      lineHeight: '15px',
      fontWeight: 400,
      letterSpacing: '1.5px',
    },
    button: {
      fontSize: '13px',
      lineHeight: '19px',
      fontWeight: 500,
      letterSpacing: '1.25px',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        html {
         -webkit-font-smoothing: auto;
       }`,
    },
    MuiInputBase: {
      styleOverrides: {
        root: { fontSize: 16 },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: 25,
          height: 42,
          '& .Mui-disabled': {
            backgroundColor: grey[300],
          },
        },
        outlined: {
          borderRadius: 25,
          height: 42,
          fontSize: 14,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: 'rgb(237, 247, 237)',
          '& .MuiAlert-icon': {
            color: '#4caf50',
          },
        },
        standardInfo: {
          backgroundColor: 'rgb(229, 246, 253)',
          '& .MuiAlert-icon': {
            color: '#03a9f4',
          },
        },
        standardWarning: {
          backgroundColor: 'rgb(255, 244, 229)',
          '& .MuiAlert-icon': {
            color: '#ff9800',
          },
        },
        standardError: {
          backgroundColor: 'rgb(253, 237, 237)',
          '& .MuiAlert-icon': {
            color: '#ef5350',
          },
        },
        filledSuccess: {
          backgroundColor: '#2e7d32',
          '& .MuiAlert-icon': {
            color: 'red',
          },
        },
        filledInfo: {
          backgroundColor: '#0288d1',
        },
        filledWarning: {
          backgroundColor: '#ed6c02',
        },
        filledError: {
          backgroundColor: '#d32f2f',
        },
      },
    },
  },
});
export default customTheme;
