/*
 * File: u
 * Project: meki
 * File Created: Tuesday, 29th September 2020 10:10:48 am
 * Author: Vicente Melin (vicente@inventures.cl)
 * -----
 * Last Modified: Saturday, 15th October 2022 12:54:28 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { ResolverParams } from '@queries/queries.interfaces';
import { OrderDetail } from '@interfaces';

// TODO: Remove order attributes included due cache issue. Maybe the other query is requesting unnecesarry attributes
export const updateProductInOrder = (name: string) => gql`
mutation ${name}($params: UpdateProductInOrderParams!){
  updateProductInOrder(params: $params){
    id
    quantity
    order {
      id
      uuid
      status
      orderDetails {
        id
        product {
          id
          uuid
        }
        order {
          id
        }
      }
    }
  }
}    
`;

export type UpdateProductInOrderMutationParams = ResolverParams<{
  orderUuid: string;
  productUuid: string;
  quantity?: number;
  status?: string;
}>;

export type UpdateProductInOrderMutationResponse = {
  updateProductInOrder: Pick<
    OrderDetail,
    'id' | 'quantity' | '__typename' | 'order'
  >;
};
