/*
 * File: deleteProduct.tsx
 * Project: meki
 * File Created: Tuesday, 29th September 2020 12:02:35 pm
 * Author: Vicente Melin (vicente@inventures.cl)
 * -----
 * Last Modified: Wednesday, 14th April 2021 10:57:22 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { ResolverParams } from '@queries/queries.interfaces';
import { Order } from '@interfaces';

export const deleteProductInOrder = (name: string) => gql`
mutation ${name}($params: DeleteProductInOrderParams!){
    deleteProductInOrder(params: $params){
      id
      uuid
      orderDetails {
        id
        product {
          id
        }
      }
    }
  }    
`;

export type DeleteProductInOrderMutationParams = ResolverParams<{
  orderUuid: string;
  productUuid: string;
}>;

export type DeleteProductInOrderMutationResponse = {
  deleteProductInOrder: Order;
};
