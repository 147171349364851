import React, { Fragment, useCallback, useState, useMemo } from 'react';
import Link from 'next/link';
import { getRoute } from '@utils';
import { useAuth } from '@hooks/useAuth';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  MenuButtonListItem,
  ListItemTypography,
  MenuListItemButton,
  LogoutButton,
  CloseSesionDialog,
  CloseSessionButton,
  CloseSessionTextDialogTitle,
  CancelModalButton,
  DialogContainer,
} from '../styles';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import {
  GetUserInsurancesResponse,
  GetUserInsurancesParams,
  getUserInsurances,
} from '@queries/userInsurance/queries';
import { INSURANCE_EMAILS_ALLOWED } from '@config/environment';

interface UserInfoSectionProps {
  closeDrawerMenu: () => void;
}

export function UserInfoSection(props: UserInfoSectionProps) {
  const { closeDrawerMenu } = props;
  const { appUser: user } = useAuth();

  const { data: insurancesData } = useQuery<
    GetUserInsurancesResponse,
    GetUserInsurancesParams
  >(getUserInsurances(), {
    fetchPolicy: 'cache-and-network',
    variables: { params: { userId: user?.id } },
  });
  const hasInsurances = insurancesData?.getUserInsurances?.length > 0 || false;

  const userOptions = useMemo(() => {
    const isInsurancePermittedUser = !INSURANCE_EMAILS_ALLOWED
      ? true
      : INSURANCE_EMAILS_ALLOWED.includes(user?.email ?? '');

    return [
      {
        name: 'Mis pedidos',
        route: getRoute('orders'),
      },
      {
        name: 'Mis recetas',
        route: getRoute('prescriptions'),
      },
      {
        name: 'Mis direcciones',
        route: getRoute('accountAddresses'),
      },
      {
        name: 'Métodos de pago',
        route: getRoute('accountPaymentMethods'),
      },
      {
        name: 'Mis datos',
        route: getRoute('accountProfile'),
      },
      isInsurancePermittedUser && {
        name: hasInsurances ? 'Mis seguros' : '¡Inscribe tu seguro!',
        route: hasInsurances
          ? getRoute('myInsurances')
          : getRoute('insuranceIntroduction'),
      },
      {
        name: 'Ajustes',
        route: getRoute('settings'),
      },
      {
        name: '¡Comparte y gana descuentos!',
        route: getRoute('referral'),
      },
    ].filter(Boolean);
  }, [hasInsurances]);

  const { logout } = useAuth();
  const [logoutDialogIsOpen, setLogoutDialogIsOpen] = useState(false);
  const router = useRouter();
  const handleLogoutDialog = useCallback(() => {
    setLogoutDialogIsOpen(!logoutDialogIsOpen);
  }, [logoutDialogIsOpen]);

  const handleCloseModalSession = useCallback(() => {
    setLogoutDialogIsOpen(false);
  }, []);

  const handleCloseUserSession = useCallback(async () => {
    handleCloseModalSession();
    await logout();
    void router.push(getRoute('home'));
  }, [handleCloseModalSession, logout, router]);

  const handleCloseDrawerMenu = useCallback(() => {
    closeDrawerMenu();
  }, [closeDrawerMenu]);

  return (
    <>
      {logoutDialogIsOpen && (
        <>
          <DialogContainer>
            <CloseSesionDialog open={logoutDialogIsOpen}>
              <DialogContent>
                <CloseSessionTextDialogTitle>
                  ¿Estás seguro que quieres cerrar sesión?
                </CloseSessionTextDialogTitle>
              </DialogContent>
              <DialogActions>
                <CloseSessionButton
                  onClick={() => {
                    handleCloseModalSession();
                    handleCloseDrawerMenu();
                    void handleCloseUserSession();
                  }}
                >
                  Cerrar sesión
                </CloseSessionButton>

                <CancelModalButton onClick={handleCloseModalSession}>
                  Cancelar
                </CancelModalButton>
              </DialogActions>
            </CloseSesionDialog>
          </DialogContainer>
        </>
      )}

      {userOptions.map((option, index, row) => {
        if (!option) return null;
        return (
          <Fragment key={option.name}>
            <Link {...option.route?.toLink()}>
              <MenuButtonListItem
                name={option.name}
                lastItem={index === row.length - 1}
                onClick={handleCloseDrawerMenu}
              >
                <ListItemTypography>{option.name}</ListItemTypography>
                <MenuListItemButton rotate="left">
                  <ExpandMore />
                </MenuListItemButton>
              </MenuButtonListItem>
            </Link>
          </Fragment>
        );
      })}

      <LogoutButton onClick={handleLogoutDialog}>Cerrar sesión</LogoutButton>
    </>
  );
}
