import React, { useCallback, useState } from 'react';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { DrawerMenuContent } from './DrawerMenuContent';
import {
  MenuIconButton,
  DrawerMenuSwipeableDrawer,
  ButtonName,
} from './styles';

interface DrawerMenuProps {
  menuIconColorChange?: boolean;
  openDrawer?: boolean;
  barChange?: boolean;
  isFromMenuName?: string;
}

export function DrawerMenu(props: DrawerMenuProps) {
  const { barChange, isFromMenuName, menuIconColorChange } = props;

  const [toggleDrawer, setToggleDrawer] = useState(false);

  const handleCloseDrawer = useCallback(() => {
    setToggleDrawer(false);
  }, []);
  const handleOpenDrawer = useCallback(() => {
    setToggleDrawer(true);
  }, []);

  return (
    <div>
      {!isFromMenuName ? (
        <MenuIconButton
          menuIconColorChange={menuIconColorChange}
          onClick={handleOpenDrawer}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <MenuRoundedIcon />
        </MenuIconButton>
      ) : (
        <ButtonName barChange={barChange} onClick={handleOpenDrawer}>
          {isFromMenuName}
        </ButtonName>
      )}
      <DrawerMenuSwipeableDrawer
        anchor={'left'}
        open={toggleDrawer}
        onClose={handleCloseDrawer}
        onOpen={handleOpenDrawer}
      >
        <DrawerMenuContent handleCloseDrawer={handleCloseDrawer} />
      </DrawerMenuSwipeableDrawer>
    </div>
  );
}
