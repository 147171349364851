/*
 * File: PayButton.hooks.ts
 * Project: meki
 * File Created: Monday, 29th November 2021 1:42:34 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Friday, 1st December 2023 2:27:18 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useTagManagerEvent } from '@hooks/useTagManagerEvent';
import { useCallback } from 'react';
import { Order } from '@interfaces';
import { useSegment } from '@hooks/useSegment';

interface TrackPaymentParams {
  orders: Order[];
  orderId: number;
  totalPrice: number;
  deliveryPrice: number;
}
interface GroupedItem {
  item_brand: string;
  item_name: string;
  item_id: string;
  price: number;
  quantity: number;
}
export function useTrackPayment() {
  const { addEcommerceEvent } = useTagManagerEvent();
  const { track } = useSegment();
  const trackPayment = useCallback(
    ({ orders, orderId, totalPrice, deliveryPrice }: TrackPaymentParams) => {
      const allOrderDetails = orders.flatMap((order) => order.orderDetails);
      const groupedItems: GroupedItem[] = allOrderDetails.reduce(
        (accumulator: GroupedItem[], detail) => {
          const existingItem = accumulator.find(
            (item) => item.item_id === String(detail.product.id),
          );
          if (!existingItem) {
            accumulator.push({
              item_brand: detail.product.laboratory,
              item_name: detail.product.name,
              item_id: String(detail.product.id),
              price: detail.product.price,
              quantity: 1,
            });
          }
          return accumulator;
        },
        [],
      );
      addEcommerceEvent('transaction', {
        totalPrice,
        purchase: {
          affiliation: 'mekiapp',
          transaction_id: String(orderId),
          value: `${totalPrice}.00`,
          shipping: `${deliveryPrice}.00`,
          tax: `0.00`,
          currency: 'CLP',
        },
        items: groupedItems,
      });
      void track('transaction', {
        total: totalPrice,
        paid: totalPrice,
        mekiCredits: orders,
      });
    },
    [addEcommerceEvent, track],
  );
  return trackPayment;
}
