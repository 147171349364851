import { Typography } from '@mui/material';
import { styled } from '@mui/system';

interface subtitleProps {
  addMarginTop?: boolean;
}

const ProductList = styled('div')({
  width: 'fit-content',
  maxWidth: '100%',
  position: 'relative',
  backgroundColor: '#FFFFFF',
  border: '1px solid #EAEAEA',
  borderRadius: '10px',
  marginBottom: '20px',
});

const List = styled('div')({
  display: 'flex',
  overflowX: 'auto',
  scrollSnapType: 'x mandatory',
  '& > a: not(:last-child)': {
    borderRight: '1px solid #EAEAEA',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
});
const TapButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: 50,
  cursor: 'pointer',
  height: 'calc(100% - 70px);',
  marginTop: 47,
  marginBottom: 47,
  zIndex: 3,

  top: 0,
  background:
    'linear-gradient(271.71deg, #FFFFFF 1.17%, rgba(255, 255, 255, 0.67) 67.42%, rgba(255, 255, 255, 0) 98.27%);',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    border: '2px solid',
    borderColor: '#0A7ABE',
    borderRadius: '100%',
    padding: 2,
    width: 25,
    height: 25,
  },
  [theme.breakpoints.down(700)]: {
    display: 'none',
  },
}));
const ForwardButton = styled(TapButton)({
  right: 0,
});
const BackButton = styled(TapButton)({
  left: 0,
  transform: 'scale(-1, 1)',
});
const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
const TitleContainer = styled('div')({
  width: '100%',
  backgroundColor: 'transparent',
  padding: '12px 15px',
  borderBottom: '1px solid #eaeaea',
  display: 'flex',
  flexDirection: 'column',
});
const Title = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '21px',
  letterSpacing: '0.15px',
});
const Subtitle = styled('span', {
  shouldForwardProp: (prop) => prop !== 'addMarginTop',
})<subtitleProps>(({ addMarginTop }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: '#414046',
  marginTop: addMarginTop ? '8px' : '0px',
}));
const IconContainer = styled('div')({
  position: 'relative',
  minHeight: '22px',
  minWidth: '22px',
  marginRight: '10px',
});
const SeeAllContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: 1,
});
const LinkColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '15px',
});

export {
  ProductList,
  List,
  TapButton,
  ForwardButton,
  BackButton,
  Row,
  Column,
  TitleContainer,
  Title,
  Subtitle,
  IconContainer,
  SeeAllContainer,
  LinkColumn,
};
