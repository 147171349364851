import {
  IconContainer,
  ContentDiv,
  DialogTitleTypography,
  DialogInfoTypography,
  ActionButton,
  ActionButtonDiv,
  HelpButton,
  DialogStyled,
} from './allPrescriptionTypeCheckModal.styles';
import React, { useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

export function AllPrescriptionTypeCheckModal() {
  const router = useRouter();

  // Local States
  const { q } = router.query;
  const searchString = q ? decodeURI(q as string) : '';
  const [open, setOpen] = useState(true);
  return (
    <>
      <DialogStyled open={open} onClose={() => setOpen(false)}>
        <ContentDiv>
          <IconContainer>
            <Image
              layout="fill"
              src="/assets/images/home/closeicon.webp"
              alt="closeIcon"
              objectFit="contain"
              onClick={() => setOpen(false)}
            />
          </IconContainer>
          <DialogTitleTypography>Venta presencial</DialogTitleTypography>
          <DialogInfoTypography>
            Estos productos sólo se venden en el local. Si quieres saber más
            información, puedes comunicarte con nosotros a través de{' '}
            <HelpButton
              href={`https://api.whatsapp.com/send?phone=56977212932&text=${encodeURIComponent(
                'Hola! Me gustaría saber más información del medicamento ' +
                  searchString,
              )}`}
            >
              WhatsApp
            </HelpButton>
          </DialogInfoTypography>
          <ActionButtonDiv>
            <ActionButton variant="contained" onClick={() => setOpen(false)}>
              Aceptar
            </ActionButton>
          </ActionButtonDiv>
        </ContentDiv>
      </DialogStyled>
    </>
  );
}
