/*
 * File: useRouterHistory.tsx
 * Project: meki
 * File Created: Tuesday, 25th October 2022 11:58:58 am
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Tuesday, 25th October 2022 5:33:02 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Console } from '@utils';

declare global {
  interface Window {
    historyMeki: string[];
  }
}
const TAG = '[ROUTER_HISTORY] ';
export const useRouterHistory = () => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      Console.log(
        TAG +
          `App is changing to ${url as string} ${
            shallow ? 'with' : 'without'
          } shallow routing`,
      );
      window.historyMeki = window.historyMeki
        ? [...window.historyMeki, url as string]
        : [url as string];
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};
