/*
 * File: parseUsers.ts
 * Project: meki
 * File Created: Monday, 22nd February 2021 4:21:38 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Friday, 11th November 2022 1:14:00 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { User } from '@interfaces';
import { EmailValidator } from '@utils/validators/EmailValidator';

export type ParseUsersResponse = {
  id: number;
  email: string;
  phone: string;
  showInfo: string;
};
const emailValidator = new EmailValidator('');
const showInfoEmail = (email: string) => {
  if (!emailValidator.validate(email)) return '';
  return email;
};

export function parseUsers(userList: User[]): ParseUsersResponse[] {
  const result = userList.map((user) => ({
    id: user.id,
    email: user.email,
    phone: user.phone,
    showInfo: `${user.name ? user.name : 'Sin Nombre'} ${user.lastNames}${
      user.email ? ' • ' + showInfoEmail(user.email) : ''
    }${user.rut ? ' • ' + user.rut : ''}`,
  }));
  return result;
}
