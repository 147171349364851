/*
 * File: AddButton.tsx
 * Project: meki
 * File Created: Friday, 19th November 2021 2:51:09 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Wednesday, 30th November 2022 11:38:55 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, {
  useState,
  useCallback,
  useRef,
  MouseEvent,
  useEffect,
} from 'react';
import { Icon } from '@mui/material';
import { usePermissions } from '@hooks/usePermissions';
import {
  UpdateQuantityBoxDiv,
  DecrementIconButton,
  QuantityTextTypography,
  IncrementIconButton,
  ButtonContainerDiv,
  PlusQuantityIconButton,
} from './addButton.styles';

interface AddButtonProps {
  addOnClick?: (event: MouseEvent<HTMLElement>) => void;
  cartQuantity?: number;
  incrementOnClick?: (event: MouseEvent<HTMLElement>) => void;
  decrementOnclick?: (event: MouseEvent<HTMLElement>) => void;
  productStatus?: string;
  MAX_AMOUNT?: number;
  prescriptionType?: string;
  index?: number;
}

const productStatusList = ['UNAVAILABLE', 'HIDDEN', 'SOLD_OUT'];

export function AddButton(props: AddButtonProps) {
  const {
    cartQuantity,
    incrementOnClick,
    decrementOnclick,
    addOnClick,
    productStatus,
    prescriptionType,
    MAX_AMOUNT,
  } = props;
  const { userCan } = usePermissions();
  const isLocalVendor = userCan('LOCAL_VENDOR');
  const [tmpCart, setTmpCart] = useState(0);
  const boxRef = useRef();
  const disabledProduct =
    (prescriptionType === 'CHECK' && !isLocalVendor) ||
    productStatusList.includes(productStatus);

  const handleClickBoxQuantity = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
    },
    [],
  );
  const handleAddProductOnClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault(); // prevents the click to trigger Link component on parent
      if (isLocalVendor && productStatus !== 'AVAILABLE_24_HRS') return;
      setTmpCart(1);
      if (!disabledProduct) {
        addOnClick(event);
      }
    },
    [addOnClick, disabledProduct, isLocalVendor, productStatus],
  );

  const handleDecrementOnClick = (event: React.MouseEvent<HTMLElement>) => {
    if (cartQuantity > 0) {
      decrementOnclick(event);
    }
  };

  useEffect(() => {
    if (cartQuantity > 0) {
      setTmpCart(0);
    }
  }, [cartQuantity]);

  if (disabledProduct) return null;
  return (
    <>
      {cartQuantity > 0 || tmpCart > 0 ? (
        <UpdateQuantityBoxDiv ref={boxRef} onClick={handleClickBoxQuantity}>
          <DecrementIconButton
            aria-label="decrement"
            onClick={handleDecrementOnClick}
            size="large"
          >
            <Icon>remove</Icon>
          </DecrementIconButton>
          <QuantityTextTypography>
            {cartQuantity > 0 ? cartQuantity : tmpCart}
          </QuantityTextTypography>
          <IncrementIconButton
            aria-label="increment"
            onClick={incrementOnClick}
            disabled={cartQuantity >= MAX_AMOUNT}
            size="large"
          >
            <Icon>add</Icon>
          </IncrementIconButton>
        </UpdateQuantityBoxDiv>
      ) : (
        <>
          <ButtonContainerDiv onClick={handleAddProductOnClick}>
            <PlusQuantityIconButton
              disabledProduct={disabledProduct}
              aria-label="+"
              size="large"
            >
              <Icon>add</Icon>
            </PlusQuantityIconButton>
          </ButtonContainerDiv>
        </>
      )}
    </>
  );
}
