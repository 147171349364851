/*
 * File: productCards.ts
 * Project: meki
 * File Created: Tuesday, 22nd December 2020 2:20:15 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Thursday, 16th November 2023 1:09:26 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2020 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React from 'react';
import { Product } from '@interfaces';
import { FetchOrderSearchQueryResponse } from '@queries/order/queries';
import { findProductInOrder } from './productUtils';
import { CurrencyFormatter } from '@utils/formatters/CurrencyFormatter';
import { Console } from './logger';
import { CheckBadge, ToConfirmBadge } from '@screens/search';

const availabilityOrderPriorities = {
  AVAILABLE_24_HRS: 5, // TODO: change to 5 when enable 24hours feature
  AVAILABLE: 4,
  TO_CONFIRM: 3,
  SOLD_OUT: 2,
  UNAVAILABLE: 1,
  HIDDEN: 0,
};

export const orderProductsByAvailability = (productList: Product[]) => {
  if (!productList) return productList;

  const newArray = [...productList]
    .filter(Boolean)
    .sort((prodA, prodB) =>
      availabilityOrderPriorities[prodA?.availability?.status] >
      availabilityOrderPriorities[prodB?.availability?.status]
        ? -1
        : availabilityOrderPriorities[prodB?.availability?.status] >
          availabilityOrderPriorities[prodA?.availability?.status]
        ? 1
        : 0,
    );
  return newArray;
};
export const orderProductsByAvailabilityAndPrice = (productList: Product[]) => {
  if (!productList) return productList;
  const newArray = [...productList]
    .filter(Boolean)
    .sort((prodA, prodB) =>
      availabilityOrderPriorities[prodA?.availability?.status] >
      availabilityOrderPriorities[prodB?.availability?.status]
        ? -1
        : availabilityOrderPriorities[prodB?.availability?.status] >
          availabilityOrderPriorities[prodA?.availability?.status]
        ? 1
        : prodA.price > prodB.price
        ? 1
        : prodA.price < prodB.price
        ? -1
        : 0,
    );
  return newArray;
};

export const parseProducts = (
  productList: Product[] = [],
  headerData: FetchOrderSearchQueryResponse,
  handleClick: (slug: string) => void | Promise<void>,
  icon: React.ReactElement,
  errorBadge?: React.ReactElement,
  warningBadge?: React.ReactElement,
  deliveryBadge?: React.ReactElement,
  sortFunction?: (products: Product[]) => Product[],
  classes?: Record<string, string>,
) => {
  const currencyFormatter = new CurrencyFormatter({
    fallback: ' ',
  });
  const result = (sortFunction ? sortFunction(productList) : productList).map(
    (product) => {
      if (!product)
        return {
          uuid: '',
          title: '',
          imageUrl: '/assets/images/default.webp',
          fallbackUrl: '/assets/images/default.webp',
          subtitle: '',
          details: '',
          description: '',
          price: currencyFormatter.format(0),
          key: '',
        };
      if (!product.uuid)
        Console.error({ msg: 'parseProduct error. No uuid', product });
      return {
        uuid: product.uuid,
        title: product.name,
        imageUrl: product.imagesUrl?.[1],
        fallbackUrl: '/assets/images/default.webp',
        subtitle: product.activePrinciple
          ?.split(';')
          .map((activePrinciple, i) => {
            const concentration = product.concentration?.split(';')[i];
            return `${activePrinciple ?? ''} ${concentration ?? ''}`.trim();
          })
          .join(' · '),
        description: product.laboratory,
        details: `${product.quantity} ${product.unit}`,
        price: currencyFormatter.format(product.price ?? 0),
        tagText: undefined,
        tagIcon: undefined,
        badgeContent: findProductInOrder(
          product.uuid,
          headerData?.order?.orderDetails,
        ),
        badgeColor: '#F44336',
        badgeTextColor: '#FFFFFF',
        leftBadge:
          product.availability?.status === 'AVAILABLE_24_HRS' ? (
            product.prescriptionType === 'CHECK' ? (
              <CheckBadge />
            ) : (
              deliveryBadge
            )
          ) : ['UNAVAILABLE', 'HIDDEN', 'SOLD_OUT'].includes(
              product?.availability.status,
            ) ? (
            errorBadge
          ) : product.availability?.status === 'SOLD_OUT' ||
            product.prescriptionType === 'CHECK' ? (
            warningBadge
          ) : product.availability?.status === 'TO_CONFIRM' ? (
            <ToConfirmBadge />
          ) : null,
        key: product.slug,
        slug: product.slug,
        classes: classes
          ? { leftBadgeDiv: classes?.leftBadgeDiv, root: classes?.rootCard }
          : {},
        isCheapestActivePrinciple: product.isCheapestActivePrinciple,
      };
    },
  );
  return result;
};
