import React, {
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Root,
  TextBox,
  EndInputAdornmentIconButton,
  SearchSuggestionsDiv,
  SearchItemsDiv,
  SearchSuggestionHeaderDiv,
  SuggestionsTitle,
  Divider,
  SearchBoxDiv,
  SearchForm,
} from './searchBox.styles';
import {
  searchBarQuery,
  SearchBarQueryResponse,
} from '@queries/search/queries';
import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { useOpenModal } from '@hooks/useModalOpenState';
import { useWindowSize } from '@hooks/useWindowSize';
import { getRoute } from '@utils';
import { SearchBackdrop } from '@screens/search/searchBackdrop';
import clsx from 'clsx';
import { SearchElementItem } from './searchElementItem';
import { useScroll } from '@hooks/useScroll';
import { useFocusSearchBox } from '@hooks/useFocusSearchBox';
import { Console } from '@utils';
import { isSafari } from '@config/environment';
import { capitalizeWord } from '@utils/strings';
import { getSuggestions } from '@services/meiliSearch';
import { useDebounce } from '@hooks/useDebounce';
import { USE_ALGOLIA_SUGGESTIONS } from '@config/environment';

interface SearchBoxProps {
  searchBoxWidth: number;
  searchBoxHeight: number;
  searchBoxPadding: number;
  changeBackgroundColor: boolean;
  autoScroll: boolean;
  autoFocusSearchBox?: boolean;
  className?: string;
  fixedPosition?: boolean;
}

export function SearchBox(props: SearchBoxProps) {
  const client = useApolloClient();
  const {
    searchBoxWidth,
    searchBoxHeight,
    searchBoxPadding,
    changeBackgroundColor,
    autoScroll,
    autoFocusSearchBox,
    fixedPosition,
  } = props;
  const size = useWindowSize();
  const desktop = useMemo(() => {
    if (!size.width) return true;
    return size.width >= 768;
  }, [size.width]);
  const [searchBoxWidthDesktop, setSearchBoxWidthDesktop] = useState(800);
  useEffect(() => {
    const newSize = Math.min(size.width - 370, 800);
    setSearchBoxWidthDesktop(newSize);
  }, [size.width]);

  const router = useRouter();
  const openModal = useOpenModal();
  const { push } = router;
  const startOpen = Boolean(router.query.s);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [toggleBackdrop, setToggleBackdrop] = useState<boolean>(startOpen);
  const [searchInput, setSearchInput] = useState('');
  const [topSearchResults, setTopSearchResults] = useState<string[]>();
  const [historySearchResults, setHistorySearchResults] = useState<string[]>();
  const [suggestedResults, setSuggestedResults] = useState<string[]>();
  const debouncedValue = useDebounce<string>(searchInput, 500);

  const rootRef = useRef<HTMLHeadingElement>(null);
  const scrollStatus = useScroll();
  const focusSearchBox = useFocusSearchBox();
  const search = useMemo(() => {
    if (router.query.q) {
      return decodeURIComponent(router.query.q as string);
    }
    return '';
  }, [router.query.q]);
  const transparentToggle = router.pathname != '/' && desktop;
  const inputRef = useRef<HTMLInputElement>(undefined);
  const noSuggestions =
    suggestedResults?.length == 1 &&
    suggestedResults?.filter((product) => product !== searchInput).length == 0;
  const searchInputQuery = useCallback(
    async (input: string) => {
      setLoading(true);
      if (USE_ALGOLIA_SUGGESTIONS) {
        const response = await client.query<SearchBarQueryResponse>({
          query: searchBarQuery('searchBar'),
          variables: { params: { query: input } },
        });
        setTopSearchResults(
          response.data.productSuggestions
            .filter((suggestion) => suggestion.type === 'top')
            .map((suggestion) => suggestion.text),
        );
        setHistorySearchResults(
          response.data.productSuggestions
            .filter((suggestion) => suggestion.type === 'history')
            .map((suggestion) => suggestion.text),
        );
        setSuggestedResults(
          response.data.productSuggestions
            .filter((suggestion) => suggestion.type === 'suggested')
            .map((suggestion) => suggestion.text),
        );
      } else {
        if (input.length === 0) {
          const response = await client.query<SearchBarQueryResponse>({
            query: searchBarQuery('searchBar'),
            variables: { params: { query: input } },
          });
          setTopSearchResults(
            response.data.productSuggestions
              .filter((suggestion) => suggestion.type === 'top')
              .map((suggestion) => suggestion.text),
          );
          setHistorySearchResults(
            response.data.productSuggestions
              .filter((suggestion) => suggestion.type === 'history')
              .map((suggestion) => suggestion.text),
          );
        }
        if (input.length > 0) {
          const suggestedResults = await getSuggestions(input);
          setSuggestedResults(suggestedResults.hits.map((s) => s.text));
        }
      }
      setLoading(false);
      setShowResults(true);
    },
    [client, setShowResults],
  );

  useEffect(() => {
    Console.log({ msg: '[SearchHeader] search prop change', search });
    if (search) {
      setSearchInput(search);
    }
  }, [search, setSearchInput]);

  useEffect(() => {
    void searchInputQuery(searchInput);
  }, [searchInput, searchInputQuery]);

  // Descomentar para usar el debounce y comentar el useEffect de arriba
  // useEffect(() => {
  //   void searchInputQuery(debouncedValue);
  // }, [debouncedValue, searchInputQuery]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (searchInput.length > 0)
        void push(getRoute('search', { q: encodeURI(searchInput) }));
      setShowResults(false);
      setToggleBackdrop(false);
      focusSearchBox.onblur();
      scrollStatus.allowScroll('submit');
    },
    [searchInput, push, scrollStatus, focusSearchBox],
  );

  const handleResultClick = (newQuery: string) => {
    void push(getRoute('search', { q: encodeURI(newQuery) }));
    setSearchInput(newQuery);
    setShowResults(false);
    setToggleBackdrop(false);
    focusSearchBox.onblur();
    scrollStatus.allowScroll('result');
  };

  const handleWrite = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = String(e.target.value).replace('@', ' ');
      setSearchInput(newValue);
      router.query.q = encodeURI(newValue);
    },
    [setSearchInput, router.query],
  );

  const handleFocus = useCallback(() => {
    if (!desktop && autoScroll) {
      if (!isSafari) {
        document.querySelector('html').scroll(0, rootRef.current.offsetTop);
      }
    }
    setToggleBackdrop(true);
    setShowResults(true);
    focusSearchBox.onfocus();
    scrollStatus.disableScroll('searchFocus');
  }, [setToggleBackdrop, desktop, scrollStatus, focusSearchBox, autoScroll]);

  const handleOutFocus = useCallback(
    (tag: string) => {
      setToggleBackdrop(false);
      setShowResults(false);
      focusSearchBox.onblur();
      if (!openModal.isModalOpen) {
        scrollStatus.allowScroll('blur: ' + tag);
      }
    },
    [focusSearchBox, openModal.isModalOpen, scrollStatus],
  );

  const handleClose = useCallback(() => {
    handleWrite({
      target: {
        value: '',
      },
    } as unknown as ChangeEvent<HTMLInputElement>);
    handleOutFocus('close');
  }, [handleOutFocus, handleWrite]);

  useEffect(() => {
    if (!focusSearchBox.isFocus) {
      handleOutFocus('isFocus effect');
      inputRef.current?.blur();
    }
  }, [focusSearchBox.isFocus, handleOutFocus]);

  useEffect(() => {
    const handleScrollSuggestions = () => {
      if (!desktop) {
        inputRef.current?.blur();
      }
    };
    document.addEventListener('scroll', handleScrollSuggestions);
    return () => {
      document.removeEventListener('scroll', handleScrollSuggestions);
    };
  }, [desktop]);
  return (
    <>
      <Root
        className={props.className}
        changeBackgroundColor={changeBackgroundColor}
        fixedPosition={fixedPosition}
        ref={rootRef}
        desktop={desktop}
      >
        <SearchBoxDiv
          desktop={desktop}
          focusSearchBox={focusSearchBox.isFocus && !noSuggestions}
        >
          <SearchForm onSubmit={handleSubmit} onFocus={handleFocus} noValidate>
            <TextBox
              variant="outlined"
              placeholder="¿Qué necesitas?"
              inputRef={inputRef}
              autoFocus={autoFocusSearchBox || false}
              value={searchInput}
              desktop={desktop}
              searchBoxWidthDesktop={searchBoxWidthDesktop}
              searchBoxWidth={searchBoxWidth}
              searchBoxHeight={searchBoxHeight}
              searchBoxPadding={searchBoxPadding}
              changeBackgroundColor={changeBackgroundColor}
              focusSearchBox={focusSearchBox.isFocus && !noSuggestions}
              onChange={handleWrite}
              type="search"
              inputProps={{
                'aria-autocomplete': 'both',
                'aria-haspopup': 'false',
                autoCapitalize: 'off',
                autoComplete: 'off',
                autoCorrect: 'off',
                role: 'combobox',
                title: 'Buscar',
                'aria-label': 'Buscar',
                type: 'search',
                spellCheck: false,
                enterKeyHint: 'search',
              }}
              InputProps={{
                endAdornment: searchInput ? (
                  <InputAdornment position="end">
                    <EndInputAdornmentIconButton
                      aria-label="clear search"
                      onClick={handleClose}
                    >
                      <CloseRoundedIcon fontSize="inherit" />
                    </EndInputAdornmentIconButton>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </SearchForm>
          <SearchSuggestionsDiv
            desktop={desktop}
            searchBoxWidthDesktop={searchBoxWidthDesktop}
            focusSearchBox={focusSearchBox.isFocus}
          >
            {showResults && focusSearchBox.isFocus && (
              <SearchItemsDiv
                desktop={desktop}
                searchBoxWidthDesktop={searchBoxWidthDesktop}
                className={clsx('mobile-min-100vh', 'fix-mobile-min-vh')}
              >
                {!noSuggestions && <Divider />}
                {showResults && searchInput && suggestedResults?.length == 0 && (
                  <div>
                    <SearchElementItem
                      value={capitalizeWord(searchInput)}
                      onClick={() =>
                        handleResultClick(capitalizeWord(searchInput))
                      }
                      index={0}
                    />
                  </div>
                )}
                {showResults && searchInput.length == 0 && (
                  <>
                    {loading && (
                      <>
                        <SearchSuggestionHeaderDiv desktop={desktop}>
                          <SuggestionsTitle
                            variant="body2"
                            color="textPrimary"
                            align="left"
                          >
                            Búsquedas recientes
                          </SuggestionsTitle>
                        </SearchSuggestionHeaderDiv>
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                      </>
                    )}
                    {!loading && historySearchResults?.length > 0 && (
                      <>
                        <SearchSuggestionHeaderDiv desktop={desktop}>
                          <SuggestionsTitle
                            variant="body2"
                            color="textPrimary"
                            align="left"
                          >
                            Búsquedas recientes
                          </SuggestionsTitle>
                        </SearchSuggestionHeaderDiv>
                        {historySearchResults.map((result, i) => (
                          <SearchElementItem
                            key={i}
                            value={result}
                            onClick={() => handleResultClick(result)}
                          />
                        ))}
                      </>
                    )}
                    {!loading && topSearchResults?.length > 0 && (
                      <>
                        {historySearchResults?.length > 0 && <Divider />}

                        <SearchSuggestionHeaderDiv desktop={desktop}>
                          <SuggestionsTitle
                            variant="body2"
                            color="textPrimary"
                            align="left"
                          >
                            Te podría interesar
                          </SuggestionsTitle>
                        </SearchSuggestionHeaderDiv>
                        {topSearchResults.map((result, i) => (
                          <SearchElementItem
                            key={i}
                            value={result}
                            onClick={() => handleResultClick(result)}
                          />
                        ))}
                      </>
                    )}
                  </>
                )}
                {showResults &&
                  searchInput &&
                  (!loading || suggestedResults?.length > 0) && (
                    <>
                      {suggestedResults
                        ?.filter((product) => product !== searchInput)
                        .map((result, i) => (
                          <SearchElementItem
                            key={i}
                            index={i}
                            value={result}
                            onClick={() => handleResultClick(result)}
                          />
                        )) ?? []}
                    </>
                  )}
                {desktop
                  ? loading &&
                    Boolean(searchInput) &&
                    suggestedResults?.length == 0 && (
                      <>
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                      </>
                    )
                  : !!searchInput &&
                    showResults &&
                    loading && (
                      <>
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                        <SearchElementItem value={searchInput} loading />
                      </>
                    )}
              </SearchItemsDiv>
            )}
          </SearchSuggestionsDiv>
        </SearchBoxDiv>
      </Root>
      <SearchBackdrop
        toggleBackdrop={toggleBackdrop && (!desktop || focusSearchBox.isFocus)}
        handleToggleClick={() => handleOutFocus('backdrop')}
        trasparent={transparentToggle}
      />
    </>
  );
}
