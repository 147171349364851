/*
 * File: toast.hook.tsx
 * Project: meki
 * File Created: Friday, 11th November 2022 12:36:51 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Friday, 6th January 2023 11:31:47 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import 'regenerator-runtime/runtime.js';

import React, {
  useCallback,
  useRef,
  createContext,
  useContext,
  useReducer,
  forwardRef,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/system';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface SnackbarProps {
  marginBottom: string;
}
const ToastSnackbar = styled(Snackbar)<SnackbarProps>(({ marginBottom }) => ({
  marginBottom: marginBottom ? marginBottom : '',
}));

type ToastTypes = 'warning' | 'error' | 'info' | 'success';
type ToastContextValue = {
  showToast: (data: {
    message: string;
    type?: ToastTypes;
    duration?: number;
    anchorOriging?: {
      horizontal: 'center' | 'left' | 'right';
      vertical: 'bottom' | 'top';
    };
    marginBottom?: string;
  }) => void;
};
type ToastProps = {
  message: string;
  type?: ToastTypes;
  exit?: boolean;
};
type ShowToastParams = ToastProps & {
  key: number;
  duration?: number;
  anchorOriging?: {
    horizontal: 'center' | 'left' | 'right';
    vertical: 'bottom' | 'top';
  };
  marginBottom?: string;
};
type ToastReducerState = {
  toasts: (ToastProps & ShowToastParams)[];
};
type ToastAction = {
  type: string;
  payload: number | ShowToastParams;
};
type ToastProviderProps = {
  children: React.ReactNode;
};

const ToastContext = createContext<ToastContextValue>({
  showToast: () => {},
});
export const useToast = () => {
  const { showToast } = useContext(ToastContext);
  return { showToast };
};
function reducer(
  state: ToastReducerState = { toasts: [] },
  action: ToastAction,
): ToastReducerState {
  switch (action.type) {
    case 'add_toast':
      return { toasts: [...state.toasts, action.payload as ShowToastParams] };
    case 'exit_toast': {
      const index = state.toasts.findIndex(
        (toast) => toast.key === (action.payload as number),
      );
      return {
        toasts: [
          ...state.toasts.slice(0, index),
          { ...state.toasts[index], exit: true },
          ...state.toasts.slice(index + 1),
        ],
      };
    }
    case 'remove_toast': {
      const index = state.toasts.findIndex(
        (toast) => toast.key === (action.payload as number),
      );
      return {
        toasts: [
          ...state.toasts.slice(0, index),
          ...state.toasts.slice(index + 1),
        ],
      };
    }
    default:
      return state;
  }
}
export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [{ toasts }, dispatch] = useReducer(reducer, { toasts: [] });
  const i = useRef(1);
  const addToast = useCallback(
    (data: {
      message: string;
      type?: ToastTypes;
      key: number;
      anchorOriging?: {
        horizontal: 'center' | 'left' | 'right';
        vertical: 'bottom' | 'top';
      };
      marginBottom?: string;
    }) => {
      dispatch({ type: 'add_toast', payload: data });
    },
    [dispatch],
  );
  const hideToast = useCallback(
    (key: number) => {
      dispatch({ type: 'exit_toast', payload: key });
      setTimeout(() => dispatch({ type: 'remove_toast', payload: key }), 1000);
    },
    [dispatch],
  );
  const showToast = useCallback(
    (data: {
      message: string;
      type?: ToastTypes;
      duration?: number;
      anchorOriging?: {
        horizontal: 'center' | 'left' | 'right';
        vertical: 'bottom' | 'top';
      };
      marginBottom?: string;
    }) => {
      const key = i.current;
      const { duration, message, type, anchorOriging, marginBottom } = data;
      addToast({ message, type, key, anchorOriging, marginBottom });
      i.current += 1;
      setTimeout(() => {
        hideToast(key);
      }, duration || 5000);
    },
    [addToast, hideToast],
  );
  const toastHandler = { showToast };
  return (
    <ToastContext.Provider value={toastHandler}>
      {children}
      {toasts.map(({ key, ...toast }) => {
        return (
          <ToastSnackbar
            autoHideDuration={toast.duration}
            open={true}
            key={key}
            anchorOrigin={
              toast.anchorOriging
                ? toast.anchorOriging
                : { vertical: 'bottom', horizontal: 'left' }
            }
            marginBottom={toast.marginBottom}
          >
            <Alert severity={toast.type}>{toast.message}</Alert>
          </ToastSnackbar>
        );
      })}
    </ToastContext.Provider>
  );
};
