/*
 * File: useBottomReach.ts
 * Project: meki
 * File Created: Wednesday, 28th December 2022 9:01:35 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Tuesday, 12th September 2023 12:17:57 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useEffect, useRef, useState } from 'react';

export function useBottomReach(
  callback: () => Promise<void>,
  threshold = 200,
  elementToListenScroll?: HTMLElement,
  extractTarget: (element: HTMLElement) => HTMLElement = (element) =>
    element.children[0] as HTMLElement,
) {
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(loading);
  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);
  useEffect(
    function handleScroll() {
      if (loading || !callback) return;
      const listener = (event: Event) => {
        try {
          const target = extractTarget(event.target as HTMLElement);
          const bottom =
            target?.scrollHeight - target?.scrollTop - target?.clientHeight <=
            threshold;
          if (bottom && !loadingRef.current) {
            setLoading(true);
            void callback().finally(() => setLoading(false));
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          setLoading(false);
        }
      };
      const element = elementToListenScroll ? elementToListenScroll : document;
      element.addEventListener('scroll', listener, { passive: false });
      element.addEventListener('touchmove', listener, { passive: false }); // Added touchmove event for mobile
      return () => {
        element.removeEventListener('scroll', listener);
        element.removeEventListener('touchmove', listener); // Remember to remove touchmove listener as well
      };
    },
    [loading, callback, threshold, elementToListenScroll, extractTarget],
  );

  return { loading };
}
