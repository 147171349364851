/*
 * File: firebase.ts
 * Project: meki
 * File Created: Monday, 28th September 2020 12:37:18 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Tuesday, 14th March 2023 5:09:57 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { initializeApp, FirebaseApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  getRedirectResult as firebaseGetRedirectResult,
  getAuth,
  AuthError,
  signInWithRedirect,
} from 'firebase/auth';
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE,
  FIREBASE_PROJECT_ID,
  FIREBASE_SENDER_ID,
  FIREBASE_STORAGE_BUCKET,
} from '@config/environment';
import { useToast } from '@hooks/toast.hook';

const FIREBASE: { app: FirebaseApp } = { app: null };
export const firebase: FirebaseApp = ((): FirebaseApp => {
  if (FIREBASE.app) return FIREBASE.app;
  FIREBASE.app = initializeApp({
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    databaseURL: FIREBASE_DATABASE,
    projectId: FIREBASE_PROJECT_ID,
    appId: FIREBASE_APP_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_SENDER_ID,
  });
  return FIREBASE.app;
})();

export const getFirebaseAuth = (() => {
  const auth = getAuth(firebase);
  return () => auth;
})();

export const signinWithGoogle = () => {
  const googleProvider = new GoogleAuthProvider();
  googleProvider.addScope('profile');
  googleProvider.addScope('email');
  return signInWithRedirect(getFirebaseAuth(), googleProvider);
};

export const getRedirectResult = async () => {
  try {
    const result = await firebaseGetRedirectResult(getFirebaseAuth());
    return result?.user;
  } catch (error) {
    return false;
  }
};

export function isAuthError(error: unknown): error is AuthError {
  if (!error) return false;
  if ((error as { code: string })?.code?.startsWith('auth')) return true;
  return false;
}

interface UseShowFirebaseAuthErrorResponse {
  showError: (error: AuthError) => void;
}
export const useShowFirebaseAuthError =
  (): UseShowFirebaseAuthErrorResponse => {
    const { showToast } = useToast();
    const showError = (error: AuthError) => {
      if (error.code === 'auth/invalid-phone-number') {
        showToast({
          type: 'error',
          message: 'El telefono ingresado no es valido. Intenta de nuevo.',
        });
      } else if (error.code === 'auth/quota-exceeded') {
        showToast({
          type: 'error',
          message: 'Ups, hubo un error. Intenta de nuevo.',
        });
      } else if (error.code === 'auth/user-disabled') {
        showToast({
          type: 'error',
          message:
            'Tu usuario esta deshabilitado. Si esto es un error contacta a la asistente por Whatsapp',
        });
      } else if (error.code === 'auth/too-many-requests') {
        showToast({
          type: 'error',
          message:
            'Ya se han enviado muchos sms a este numero. Intenta ingresar con tu email',
        });
      } else {
        showToast({
          type: 'error',
          message: 'Ups, hubo un error. Intenta de nuevo.',
        });
      }
    };
    return { showError };
  };

export function isValidGoogleSigninNavigator() {
  if (typeof window === 'undefined') return false;
  if (navigator.userAgent.includes('Chrome')) return true;
  if (navigator.userAgent.includes('CriOS')) return true; // iOS Chrome
  if (navigator.userAgent.includes('Firefox')) return true;
  if (navigator.userAgent.includes('Safari')) return true;
  return false;
}
