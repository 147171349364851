/*
 * File: getPillBoxUser.tsx
 * Project: meki
 * File Created: Friday, 21st January 2022 2:07:33 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Tuesday, 26th July 2022 5:28:20 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { PillBox } from '@interfaces';

export const getPillBoxUser = () => gql`
  query PillBox {
    pillBox {
      pillBoxDetails {
        id
        lastMovementBalance
        userStock
        frequency
        remainingBalanceDays
        lastOrderDate
        speedConsumption
        consumptionUnit
        product {
          id
          uuid
          name
          format
          unit
          activePrinciple
          slug
          concentration
          availability {
            status
            stock
            statusWithoutStock
          }
          laboratory
          prescriptionType
          price
          isMedicine
          thumbnailUrl
          categories {
            id
            name
          }
        }
        fewPills
        stillHave
        allowedFormat
        hidden
      }
    }
  }
`;

export interface GetPillBoxUserResponseQuery {
  pillBox: PillBox;
}
