import React from 'react';
import Image from 'next/image';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useRouter } from 'next/router';

const ErrorContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  paddingTop: 20,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 600,
  margin: 'auto',
  '@media (min-width: 1024px)': {
    paddingTop: '10%',
  },
});

const IconContainer = styled('div')({
  position: 'relative',
  width: '246px',
  height: '145px',
  marginBottom: '30px',
});

const MainTextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const TextTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '30px',
  lineHeight: '45px',
  textAlign: 'center',
  color: '#414046',
  [theme.breakpoints.down(570)]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

const LinkTypography = styled('a')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  color: '#0A7ABE',
  textDecoration: 'underline',
  cursor: 'pointer',
  [theme.breakpoints.down(570)]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

const SmallTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  color: '#414046',
  [theme.breakpoints.down(570)]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

const LinkContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '6px',
  marginTop: '40px',
  [theme.breakpoints.down(570)]: {
    flexDirection: 'column',
    gap: '0px',
  },
}));
export const SearchError = () => {
  const { query } = useRouter();
  return (
    <ErrorContainer>
      <IconContainer>
        <Image
          src="/assets/images/errorIcon.webp"
          alt="error"
          layout="fill"
          objectFit="contain"
          priority
        />
      </IconContainer>
      <MainTextContainer>
        <TextTypography>Error en la búsqueda</TextTypography>
      </MainTextContainer>

      <LinkContainer>
        Intenta de nuevo o
        <LinkTypography
          href={`https://api.whatsapp.com/send?phone=56977212932&text=${encodeURI(
            `Hola! Tuve un problema buscando "${query.q as string}"`,
          )}`}
          target="_blank"
        >
          pincha aquí
        </LinkTypography>
        <SmallTypography>
          para recibir ayuda de nuestro asistente
        </SmallTypography>
      </LinkContainer>
    </ErrorContainer>
  );
};
