/*
 * File: useAuth.tsx
 * Project: meki
 * File Created: Monday, 28th September 2020 12:35:27 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Friday, 19th January 2024 11:37:15 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';

import { getFirebaseAuth } from '@services/firebase';
import { Console, removeUndefineds } from '@utils';
import { useRouter } from 'next/router';
import { getRoute } from '@utils/routes';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  currentUserInfo,
  CurrentUserInfoResponse,
} from '@queries/user/queries';
import { User } from '@interfaces';
import { GlobalApolloClient } from '@config/apollo';
import { useLoggedReducer } from './useLoggedReducer';
import { useModalRouter } from './useModalRouter';
import { useTagManagerEvent } from './useTagManagerEvent';
import { onIdTokenChanged, User as FirebaseUser } from 'firebase/auth';
import { useSegment } from './useSegment';
import { getUTM } from '@utils/getUTM';
import {
  identifyUser,
  IdentifyUserParams,
  IdentifyUserResponse,
} from '@queries/user/mutations/identifyUser';
import {
  ValidateAuthMailMutation,
  ValidateAuthMailParams,
  ValidateAuthMailMutationResponse,
} from '@queries/authMail/mutations/validateAuthMail';
import { signInWithCustomToken } from 'firebase/auth';
import { getSentry } from '@services/sentry';
import nookies from 'nookies';
import { FIREBASE_API_KEY } from '@config/environment';

declare global {
  interface Window {
    userId?: string;
  }
}
interface AuthState {
  firebaseUser?: FirebaseUser;
  appUser?: User;
  loading: boolean;
  queryLoading: boolean;
  firebaseLoading: boolean;
  logout: () => Promise<void>;
}

const AuthStateContext = createContext<AuthState>({
  firebaseUser: null,
  appUser: null,
  loading: true,
  queryLoading: true,
  firebaseLoading: true,
  logout: () => Promise.resolve(),
});

interface UseAuthParams {
  forceAuth?: boolean;
  forceUnauth?: boolean;
}
interface UseAuthResponse {
  firebaseUser?: FirebaseUser;
  appUser?: User;
  loading: boolean;
  queryLoading: boolean;
  firebaseLoading: boolean;
  logout: () => Promise<void>;
}
export function useAuth({
  forceAuth,
  forceUnauth,
}: UseAuthParams = {}): UseAuthResponse {
  const { replace, route, asPath, query, isReady } = useRouter();
  const { replace: modalReplace } = useModalRouter();

  const {
    firebaseLoading,
    queryLoading,
    appUser,
    firebaseUser,
    loading,
    logout,
  } = useContext(AuthStateContext);

  useEffect(() => {
    if (loading) return;
    if (forceAuth && !appUser) {
      Console.log({
        msg: '[USE_AUTH] redirect: force auth and no user',
        forceAuth,
        appUser,
        route,
        isReady,
        query,
      });
      if (!isReady) return;
      if (!query?.token) {
        void replace(getRoute('signin', { redirect: asPath ?? '/' }));
      }
    }
    if (forceUnauth && appUser) {
      Console.log({
        msg: '[USE_AUTH] redirect: force unauth and user',
        forceAuth,
        appUser,
        route,
      });
      void replace(getRoute('home'));
    }
  }, [
    loading,
    appUser,
    forceAuth,
    forceUnauth,
    replace,
    route,
    modalReplace,
    asPath,
    isReady,
    query.token,
  ]);

  return {
    firebaseLoading,
    queryLoading,
    appUser,
    firebaseUser,
    loading,
    logout,
  };
}
type AuthAction =
  | { type: 'init_firebase_auth' }
  | { type: 'finish_firebase_auth'; payload: { firebaseUser: FirebaseUser } }
  | { type: 'init_app_auth' }
  | { type: 'finish_app_auth'; payload: { appUser: User } }
  | { type: 'update_app_auth'; payload: { appUser: User } }
  | { type: 'set_state'; payload: Partial<AuthState> }
  | { type: 'logout' };
function reducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'init_firebase_auth': {
      return {
        ...state,
        firebaseUser: null,
        firebaseLoading: true,
        loading: true,
      };
    }
    case 'finish_firebase_auth': {
      return {
        ...state,
        firebaseUser: action.payload.firebaseUser,
        firebaseLoading: false,
        loading: Boolean(action.payload.firebaseUser),
      };
    }
    case 'init_app_auth': {
      return {
        ...state,
        appUser: null,
        queryLoading: true,
        loading: true,
      };
    }
    case 'finish_app_auth': {
      return {
        ...state,
        appUser: action.payload.appUser,
        queryLoading: false,
        loading: false,
      };
    }
    case 'update_app_auth': {
      return {
        ...state,
        appUser: action.payload.appUser,
      };
    }
    case 'set_state': {
      return { ...state, ...action.payload };
    }
    case 'logout': {
      return {
        ...state,
        firebaseUser: null,
        appUser: null,
        loading: false,
        queryLoading: false,
        firebaseLoading: false,
      };
    }
    default: {
      return state;
    }
  }
}
interface AuthStateProviderProps {
  children: React.ReactNode;
}
export function AuthStateProvider({ children }: AuthStateProviderProps) {
  const { addCustomEvent } = useTagManagerEvent();
  const { identify, reset, debugTrack } = useSegment();
  const { query, isReady, replace, pathname, asPath } = useRouter();
  const [
    { firebaseUser, firebaseLoading, appUser, queryLoading, loading, logout },
    dispatch,
  ] = useReducer(useLoggedReducer(reducer), {
    firebaseUser: null,
    appUser: null,
    loading: true,
    queryLoading: true,
    firebaseLoading: true,
    logout: () => Promise.resolve(),
  });
  const [executeQuery, { data, previousData }] =
    useLazyQuery<CurrentUserInfoResponse>(
      currentUserInfo('AuthStateProvider'),
      {
        fetchPolicy: 'network-only',
      },
    );
  const [validateAuthCode] = useMutation<
    ValidateAuthMailMutationResponse,
    ValidateAuthMailParams
  >(ValidateAuthMailMutation());
  const execAppLogin = useCallback(async () => {
    try {
      const response = await executeQuery();
      Console.log({
        msg: '[AuthStateProvider] success app query',
        response,
      });
      dispatch({
        type: 'finish_app_auth',
        payload: { appUser: response.data.currentUserInfo },
      });
    } catch {
      Console.log({
        msg: '[AuthStateProvider]error app query',
      });
      dispatch({
        type: 'finish_app_auth',
        payload: { appUser: null },
      });
    }
  }, [executeQuery]);
  const [sendIdentifyUser] = useMutation<
    IdentifyUserResponse,
    IdentifyUserParams
  >(identifyUser());
  const firebaseUserRef = useRef(firebaseUser);
  const appUserRef = useRef(appUser);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFirebaseUser = useMemo(
    () => firebaseUser,
    [firebaseUser?.email],
  );

  const saveToken = useCallback(async (firebaseUserResponse: FirebaseUser) => {
    const refreshToken = firebaseUserResponse.refreshToken;
    const cookieExpiration = new Date(+new Date() + 1000 * 60 * 60 * 24 * 30);
    nookies.set(undefined, 'token', refreshToken, {
      path: '/',
      expires: cookieExpiration,
    });
  }, []);

  useEffect(() => {
    const onIdTokenChange = () => {
      return onIdTokenChanged(getFirebaseAuth(), (firebaseUserResponse) => {
        dispatch({ type: 'init_firebase_auth' });
        Console.log({
          msg: '[AuthStateProvider] onComplete firebase query',
          firebaseUserResponse,
        });
        dispatch({
          type: 'finish_firebase_auth',
          payload: { firebaseUser: firebaseUserResponse },
        });
        if (firebaseUserResponse) {
          dispatch({ type: 'init_app_auth' });
          void execAppLogin();
          void saveToken(firebaseUserResponse);
        } else {
          nookies.set(undefined, 'token', '', { path: '/' });
        }
      });
    };
    const unsubscribe = onIdTokenChange();
    return () => {
      unsubscribe();
    };
  }, [execAppLogin, saveToken]);

  useEffect(() => {
    const handle = setInterval(() => {
      const user = firebaseUserRef.current;
      if (user) void saveToken(user);
    }, 10 * 60 * 1000);
    // clean up setInterval
    return () => clearInterval(handle);
  }, [saveToken]);

  useEffect(
    function handleRefetch() {
      if ((firebaseUser && !appUser) || (!firebaseUser && appUser)) {
        Console.log('[USE_AUTH] auth change refetch', {
          firebaseUser,
          appUser: appUserRef.current,
        });
        void execAppLogin();
      }
    },
    [appUser, firebaseUser, execAppLogin],
  );

  useEffect(
    function updateFirebaseUserRef() {
      Console.log('[USE_AUTH] firebaseUser updated', {
        prevFirebaseUser: firebaseUserRef.current,
        firebaseUser: memoizedFirebaseUser,
      });
      firebaseUserRef.current = memoizedFirebaseUser;
    },
    [memoizedFirebaseUser],
  );
  useEffect(
    function updateAppUserRef() {
      Console.log('[USE_AUTH] appUser updated', {
        prevAppUser: appUserRef.current,
        appUser,
      });
      appUserRef.current = appUser;
    },
    [appUser],
  );
  useEffect(
    function handleAuthChangeEvent() {
      const prev = window.userId;
      window.userId = appUser?.uuid;
      if (appUser?.uuid) {
        addCustomEvent('authComplete', {
          userID: appUser.uuid,
          email: appUser.email,
          name: `${appUser.name} ${appUser.lastNames}`,
        });
        identify(appUser.uuid, {
          email: appUser.email,
          name: `${appUser.name} ${appUser.lastNames}`,
          ...getUTM(),
        });
        void sendIdentifyUser();
        return;
      }
      if (prev && !appUser?.uuid) {
        reset();
        addCustomEvent('signoutComplete', {
          userID: null,
          email: null,
          name: null,
        });
      }
    },
    [
      addCustomEvent,
      appUser?.email,
      appUser?.lastNames,
      appUser?.name,
      appUser?.uuid,
      identify,
    ],
  );
  useEffect(function setLogoutFunction() {
    dispatch({
      type: 'set_state',
      payload: {
        logout: async () => {
          Console.log('[USE_AUTH] logout function');
          await GlobalApolloClient.logout();
          await getFirebaseAuth().signOut();
          dispatch({ type: 'logout' });
        },
      },
    });
  }, []);
  useEffect(
    function listenToApolloUserCache() {
      if (!previousData?.currentUserInfo && data?.currentUserInfo) {
        Console.log({
          msg: '[AuthStateProvider] listenToApolloUserCache',
          previousData,
          data,
        });
        dispatch({
          type: 'finish_app_auth',
          payload: { appUser: data.currentUserInfo },
        });
      } else if (data?.currentUserInfo) {
        Console.log({
          msg: '[AuthStateProvider] listenToApolloUserCache update user',
          previousData,
          data,
        });
        dispatch({
          type: 'update_app_auth',
          payload: { appUser: data.currentUserInfo },
        });
      }
    },
    [data, previousData],
  );
  useEffect(
    function authWithLinkToken() {
      Console.log({
        msg: 'authWithLinkToken',
        query: query.token,
        isReady,
      });
      if (!isReady) return;
      if (query?.token) {
        Console.log({
          msg: '[USE_AUTH] authWithLinkToken I have a token going to validate',
          token: query.token,
        });
        const fetchResponse = async () => {
          const { data: authMailData } = await validateAuthCode({
            variables: {
              params: {
                token: query.token as string,
              },
            },
          });
          if (authMailData.validateAuthMail.isValid) {
            const authToken = authMailData.validateAuthMail.token;
            try {
              await signInWithCustomToken(getFirebaseAuth(), authToken);
              Console.log({
                msg: '[USE_AUTH] authWithLinkToken: inicie session',
              });
            } catch (error) {
              Console.log({
                msg: "[USE_AUTH] authWithLinkToken Firebase User couldn't sign in with email link",
              });
              await getSentry().then((Sentry) => {
                Sentry.captureException(error);
              });
              const routerToken = (query?.token as string).replace(/\s.*/g, '');
              await replace(
                {
                  pathname: pathname,
                  query: removeUndefineds({
                    ...query,
                    token: routerToken,
                  }),
                },
                {
                  pathname: asPath.replace(/\?.*/, ''),
                  query: removeUndefineds({
                    ...query,
                    token: routerToken,
                  }),
                },
              );
            }
          } else {
            Console.log('[USE_AUTH] authWithLinkToken no valid token');
            const routerToken = (query?.token as string).replace(/\s.*/g, '');
            await replace(
              {
                pathname: pathname,
                query: removeUndefineds({
                  ...query,
                  token: routerToken,
                }),
              },
              {
                pathname: asPath.replace(/\?.*/, ''),
                query: removeUndefineds({
                  ...query,
                  token: routerToken,
                }),
              },
            );
          }
        };
        void fetchResponse();
      }
    },
    [isReady, validateAuthCode, asPath, pathname, query, replace],
  );
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const openRequest = indexedDB.open('firebaseLocalStorageDb');
    openRequest.onupgradeneeded = function (event) {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains('firebaseLocalStorage')) {
        db.createObjectStore('firebaseLocalStorage');
      }
    };
    openRequest.onsuccess = function (event) {
      const db = (event.target as IDBOpenDBRequest).result;
      const transaction = db.transaction(['firebaseLocalStorage'], 'readonly');
      const store = transaction.objectStore('firebaseLocalStorage');
      const request = store.get(
        `firebase:authUser:${FIREBASE_API_KEY}:[DEFAULT]`,
      );
      request.onsuccess = function () {
        // Si la operación es exitosa, hacer log del valor de 'Key'.
        debugTrack('Firebase', 'Data from indexedDB', request.result);
      };

      request.onerror = function () {};
    };

    openRequest.onerror = function () {};
  }, [debugTrack]);
  const authState = useMemo(
    () => ({
      firebaseUser: memoizedFirebaseUser,
      loading: loading,
      queryLoading,
      firebaseLoading,
      appUser,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(appUser),
      firebaseLoading,
      loading,
      logout,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(memoizedFirebaseUser),
      queryLoading,
    ],
  );

  return (
    <AuthStateContext.Provider value={authState}>
      {children}
    </AuthStateContext.Provider>
  );
}
