import { gql } from '@apollo/client';
import { UserInsurance } from '@interfaces/userInsurance.interface';

export const getUserInsurances = () => gql`
  query GetUserInsurances($params: GetUserInsurancesParams!) {
    getUserInsurances(params: $params) {
      id
      rut
      idUrl1
      idUrl2
      userId
      name
      usedAt
    }
  }
`;

export type GetUserInsurancesParams = {
  params: {
    userId: number;
    withUnacceptedTerms?: boolean;
  };
};

export type GetUserInsurancesResponse = {
  getUserInsurances: UserInsurance[];
};
