/*
 * File: nextOrders.ts
 * Project: meki
 * File Created: Monday, 21st February 2022 12:45:15 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Friday, 21st October 2022 1:39:08 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { gql } from '@apollo/client';
import { Order } from '@interfaces';

export const getNextOrders = () => gql`
  query NextOrders {
    nextOrders {
      id
      uuid
      orderGroupId
      deliveryDate
      deliveryType
      status
      orderDetails {
        id
        uuid
        price
        quantity
        product {
          id
          name
          activePrinciple
          quantity
          concentration
          availability {
            status
            statusWithoutStock
            stock
          }
          format
          slug
          thumbnailUrl
        }
      }
      orderNumber
    }
  }
`;

export type FetchNextOrdersQueryResponse = { nextOrders: Order[] };
