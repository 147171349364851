/*
 * File: currentUserInfo.tsx
 * Project: meki
 * File Created: Thursday, 7th January 2021 9:52:32 am
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Monday, 6th March 2022 11:20:14 am
 * Modified By: Nicolás Castillo (nicolas.castillo@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { User } from '@interfaces';

export const currentUserCredits = () => gql`
  query currentUserCredits {
    currentUserInfo {
      id
      credits
    }
  }
`;

export interface CurrentUserCreditsResponse {
  currentUserInfo: Pick<User, 'credits'>;
}
