/*
 * File: searchNotFound.tsx
 * Project: meki
 * File Created: Thursday, 1st October 2020 10:10:40 am
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Thursday, 16th November 2023 3:13:46 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useRouter } from 'next/router';
import Image from 'next/image';

const DivText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  paddingTop: 20,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 600,
  margin: 'auto',
  '@media (min-width: 1024px)': {
    paddingTop: '10%',
  },
});

const IconContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '151px',
  height: '146px',
  marginTop: '60px',
  marginBottom: '30px',
  [theme.breakpoints.down(570)]: {
    width: '114px',
    height: '110px',
  },
}));

const MainTextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0px 10px',
}));

const TextTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '30px',
  lineHeight: '45px',
  textAlign: 'center',
  color: '#414046',
  [theme.breakpoints.down(570)]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

const LinkContainer = styled('div')(() => ({
  display: 'flex',
  margin: '0px 10px',
}));

const LinkTypography = styled('a')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  color: '#0A7ABE',
  textDecoration: 'underline',
  cursor: 'pointer',
  [theme.breakpoints.down(570)]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

const SmallTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  color: '#414046',
  [theme.breakpoints.down(570)]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

export function SearchNotFound() {
  const router = useRouter();
  const { q } = router.query;
  const searchString = q ? decodeURI(q as string) : '';
  const whatsappRoute = useMemo(() => {
    const whatsappRoute = `https://api.whatsapp.com/send?phone=56977212932&text=${encodeURI(
      `Hola! Quería pedirles ayuda para encontrar ${searchString}`,
    )}`;
    return whatsappRoute;
  }, [searchString]);

  return (
    <DivText>
      <IconContainer>
        <Image
          src="/assets/images/searchLogo.png"
          alt="Not Found"
          layout="fill"
          objectFit="contain"
          priority
        />
      </IconContainer>
      <MainTextContainer>
        <TextTypography>
          No encontramos resultados para “{searchString}”
        </TextTypography>
      </MainTextContainer>
      <LinkContainer>
        <SmallTypography>
          Intenta con otra búsqueda o{' '}
          <LinkTypography href={whatsappRoute} target="_blank">
            escríbenos
          </LinkTypography>{' '}
          para ayudarte
        </SmallTypography>
      </LinkContainer>
    </DivText>
  );
}
