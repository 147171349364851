/*
 * File: usePersistState.ts
 * Project: meki
 * File Created: Monday, 5th October 2020 5:38:59 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Tuesday, 20th December 2022 2:47:30 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useCallback, useEffect, useRef, useState } from 'react';

const getLocalStorageValue = <T = unknown>(key: string): T | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  if (window?.localStorage) {
    try {
      const persistedValue = window.localStorage.getItem(key);
      const parsedPersistedValue = JSON.parse(persistedValue) as unknown as T;
      if (parsedPersistedValue !== undefined) {
        return parsedPersistedValue;
      }
    } catch (e) {
      window.localStorage.removeItem(key);
    }
  }
  return undefined;
};

export function usePersistState<T = unknown>(
  key: string,
  defaultValue: T,
): [T, (input: T) => void, () => void] {
  const [value, setValue] = useState<null | T>(
    getLocalStorageValue(key) ?? defaultValue ?? null,
  );
  const defaultValueRef = useRef(defaultValue);
  const handleChange = useCallback(
    (newValue: T) => {
      setValue(newValue);
      if (typeof window === 'undefined') {
        return undefined;
      }
      if (window.localStorage)
        window.localStorage.setItem(key, JSON.stringify(newValue));
    },
    [key, setValue],
  );
  const clear = useCallback(() => {
    setValue(defaultValueRef.current);
    if (typeof window === 'undefined') {
      return undefined;
    }
    window.localStorage.removeItem(key);
  }, [key]);
  useEffect(() => {
    const storageValue = getLocalStorageValue<T>(key);
    if (storageValue !== undefined) {
      setValue(storageValue);
    }
  }, [key]);
  useEffect(() => {
    defaultValueRef.current = defaultValue;
  }, [defaultValue]);
  return [value, handleChange, clear];
}
