/*
 * File: useSegment.ts
 * Project: meki
 * File Created: Wednesday, 17th August 2022 11:16:53 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Tuesday, 29th August 2023 5:33:09 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { NEXT_PUBLIC_AMPLITUDE_API_KEY } from '@config/environment';
import { Console } from '@utils';
import { useCallback, useEffect, useMemo } from 'react';
import { usePersistState } from './usePersistState';
import { useOpenReplayTracking } from '@services/openreplay';
import { v4 as uuid } from 'uuid';

type SegmentEvent =
  | 'add address succeed'
  | 'add payment succeed'
  | 'no_delivery_commune'
  | 'payment select'
  | 'address select'
  | 'prescription upload'
  | 'prescription request'
  | 'date select'
  | 'cart view'
  | 'product add to cart'
  | 'product remove from cart'
  | 'select_bioequivalent'
  | 'select_suggestion'
  | 'add_address'
  | 'add_card'
  | 'account creation attempt'
  | 'confirm_prescriptions'
  | 'checkout start'
  | 'checkout complete summary'
  | 'account already exist'
  | 'log in start'
  | 'log in start google'
  | 'log in start email'
  | 'log in start phone'
  | 'send code email'
  | 'send code phone'
  | 'register view'
  | 'register aditional info view'
  | 'use existing account'
  | 'change field'
  | 'signup_enter_data'
  | 'add payment start'
  | 'go to checkout'
  | 'log in succeed'
  | 'product select'
  | 'account creation start'
  | 'add address start'
  | 'rrss'
  | 'category'
  | 'subcategory'
  | 'product view'
  | 'order summary view'
  | 'order attempt'
  | 'consumption change'
  | 'transaction'
  | 'load app'
  | 'landing view'
  | 'product search'
  | 'date confirm'
  | 'pillbox product add to cart'
  | 'pillbox consumption change'
  | 'let me know when available'
  | 'request know availability sent'
  | 'prescription upload error'
  | 'address options change'
  | 'add address select actual location'
  | 'change credits use'
  | 'add payment error'
  | 'add payment error contact'
  | 'payment error'
  | 'payment error contact'
  | 'address confirm'
  | 'faq answers view'
  | 'bioequivalent info dialog opened'
  | 'cenabast products from home'
  | 'delivery zones view'
  | 'purchased products list view'
  | 'delete reminder'
  | 'contact'
  | 'last whim view'
  | 'prev order ask help'
  | 'prev order receipt'
  | 'prev order view details'
  | 'prev orders view'
  | 'purchased products list view'
  | 'referrals link post first purchase'
  | 'referrals ask help'
  | 'referrals detail list view'
  | 'referrals share button'
  | 'referrals link'
  | 'referrals view'
  | 'referrals ask help wpp'
  | 'fintoc event'
  | 'debug re create order'
  | 'missing required prescriptions'
  | 'fintoc cancel'
  | 'fintoc cancel error'
  | 'fintoc success'
  | 'fintoc exit'
  | 'prescription press next without products'
  | 'change send email'
  | 'change charge delivery cost'
  | 'banco estado success'
  | 'banco estado cancel error'
  | 'banco estado cancel'
  | 'biometric chosen'
  | 'biometric ignored'
  | 'biometric register error'
  | 'biometric register success'
  | 'biometric login attempt'
  | 'biometric login error'
  | 'biometric login success';
interface Plan {
  /** The tracking plan branch name e.g. "main" */
  branch?: string;
  /** The tracking plan source e.g. "web" */
  source?: string;
  /** The tracking plan version e.g. "1", "15" */
  version?: string;
  /** The tracking plan version Id e.g. "9ec23ba0-275f-468f-80d1-66b88bff9529" */
  versionId?: string;
}
interface IngestionMetadataEventProperty {
  /** The source name of ingestion metadata e.g. "ampli" */
  source_name?: string;
  /** The source version of ingestion metadata e.g. "2.0.0" */
  source_version?: string;
}
interface EventOptions {
  user_id?: string;
  device_id?: string;
  time?: number;
  location_lat?: number;
  location_lng?: number;
  app_version?: string;
  version_name?: string;
  library?: string;
  platform?: string;
  os_name?: string;
  os_version?: string;
  device_brand?: string;
  device_manufacturer?: string;
  device_model?: string;
  carrier?: string;
  country?: string;
  region?: string;
  city?: string;
  dma?: string;
  idfa?: string;
  idfv?: string;
  adid?: string;
  android_id?: string;
  language?: string;
  ip?: string;
  price?: number;
  quantity?: number;
  revenue?: number;
  productId?: string;
  revenueType?: string;
  event_id?: number;
  session_id?: number;
  insert_id?: string;
  plan?: Plan;
  ingestion_metadata?: IngestionMetadataEventProperty;
  partner_id?: string;
  user_properties?: {
    [key: string]: any;
  };
  extra?: {
    [key: string]: any;
  };
}
interface BaseEvent extends EventOptions {
  event_type: string;
  event_properties?: {
    [key: string]: any;
  };
  group_properties?: {
    [key: string]: any;
  };
  groups?: {
    [key: string]: any;
  };
}
interface AmplitudeReturn<T> {
  promise: Promise<T>;
}
interface Result {
  event: Event;
  code: number;
  message: string;
}
interface AmplitudElement {
  track: (
    eventInput: string | BaseEvent,
    eventProperties?: Record<string, any>,
    eventOptions?: EventOptions,
  ) => AmplitudeReturn<Result>;
  flush: () => void;
  setDeviceId: (id: string) => void;
  reset: () => void;
}
declare global {
  interface Window {
    amplitude: AmplitudElement;
  }
}
export function useSegment() {
  const [localId, setLocalId, clearLocalId] = usePersistState('akey', '');
  const [userId, setUserId, clearUserId] = usePersistState('ukey', '');
  const { trackEvent } = useOpenReplayTracking();
  const track = useCallback(
    (event: SegmentEvent, metadata?: Record<string, unknown>) => {
      Console.log('[SEGMENT][TRACK]', {
        event,
        metadata,
        sendAmplitude: !!NEXT_PUBLIC_AMPLITUDE_API_KEY,
        windowAmplitud: window.amplitude,
      });
      void trackEvent(event, { ...metadata, userId });
      if (NEXT_PUBLIC_AMPLITUDE_API_KEY)
        window.amplitude.track(event, { ...metadata, userId });
    },
    [userId, trackEvent],
  );
  const debugTrack = useCallback(
    (tag: string, message: string, metadata?: Record<string, unknown>) => {
      const event = `[${tag}] ${message}`;
      Console.log('[SEGMENT][DEBUG_TRACK]', {
        event,
        metadata,
        sendAmplitude: !!NEXT_PUBLIC_AMPLITUDE_API_KEY,
        windowAmplitud: window.amplitude,
      });
      void trackEvent(event, { ...metadata, userId });
      if (NEXT_PUBLIC_AMPLITUDE_API_KEY)
        window.amplitude.track(event, { ...metadata, userId });
    },
    [userId, trackEvent],
  );
  const flush = useCallback(() => {
    Console.log('[SEGMENT][TRACK]: flush', {
      sendAmplitude: !!NEXT_PUBLIC_AMPLITUDE_API_KEY,
      windowAmplitud: window.amplitude,
    });
    if (NEXT_PUBLIC_AMPLITUDE_API_KEY) window.amplitude.flush();
  }, []);
  const identify = useCallback(
    (newUserId: string, metadata: { email: string; name: string }) => {
      Console.log('[SEGMENT][TRACK] Indentify', {
        newUserId,
        metadata,
        sendAmplitude: !!NEXT_PUBLIC_AMPLITUDE_API_KEY,
      });
      setUserId(newUserId);
      if (NEXT_PUBLIC_AMPLITUDE_API_KEY) {
        window.amplitude.track(
          'Identify',
          {},
          {
            user_id: newUserId,
            user_properties: { ...metadata },
          },
        );
      }
    },
    [setUserId],
  );

  const setDeviceId = useCallback((id: string) => {
    Console.log('[SEGMENT][TRACK]: setDeviceId', {
      sendAmplitude: !!NEXT_PUBLIC_AMPLITUDE_API_KEY,
      windowAmplitud: window.amplitude,
    });
    if (NEXT_PUBLIC_AMPLITUDE_API_KEY) {
      window.amplitude.setDeviceId(id);
    }
  }, []);
  useEffect(() => {
    if (!localId) {
      const id = uuid();
      setLocalId(id);
      setDeviceId(id);
    }
  }, [localId, setDeviceId, setLocalId]);
  const reset = useCallback(() => {
    clearUserId();
    clearLocalId();
    if (!NEXT_PUBLIC_AMPLITUDE_API_KEY) {
      window.amplitude.reset();
      return;
    }
  }, [clearUserId, clearLocalId]);
  const functions = useMemo(() => {
    return { track, flush, identify, reset, debugTrack };
  }, [track, flush, identify, reset, debugTrack]);
  return functions;
}
