/*
 * File: logo-meki.tsx
 * Project: meki
 * File Created: Wednesday, 7th October 2020 10:48:08 am
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Tuesday, 22nd November 2022 3:53:16 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/system';
import { Palette } from '@mui/material';
interface MekiLogoProps {
  fill?: string;
  height: string;
  width: string;
  onClick?: () => void;
}
interface MekiIconsProps {
  fill?: string;
  height: string;
  width: string;
}
const MekiSvgIcon = styled(SvgIcon, {
  shouldForwardProp: (prop) =>
    !['fill', 'height', 'width'].includes(prop as string),
})<MekiIconsProps>(({ theme, fill, height, width }) => ({
  fill: fill ? fill : (theme.palette as Palette).common.white,
  height: height,
  width: width,
}));

export const MekiLogo = (props: MekiLogoProps) => {
  const { fill, height, width, onClick } = props;
  return (
    <MekiSvgIcon
      fill={fill}
      height={height}
      width={width}
      onClick={onClick}
      viewBox="0 0 71 25"
    >
      <defs>
        <path
          id="r1d60lmr0a"
          d="M0 0.169L26.774 0.169 26.774 20.496 0 20.496z"
        />
        <path
          id="32axyjlwsc"
          d="M0.188 0.109L14.496 0.109 14.496 16.03 0.188 16.03z"
        />
      </defs>
      <g fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-19 -25) translate(19 25) translate(0 2.298)">
              <path
                d="M24.244 2l2.53-.279V.17h-6.56L13.34 14.755 6.28.169H0V1.72l2.723.293 3.56 7.631 5.089 10.851h1.25L19.62 5.413v9.388c0 1.293-.02 2.582-.06 3.864l-2.533.28v1.551h9.748v-1.551l-2.53-.28c-.043-1.303-.064-2.601-.064-3.894V5.863c0-1.292.02-2.58.064-3.863"
                mask="url(#gjnxi8y02b)"
              />
            </g>
            <path
              d="M.97 22.184C.46 21.719.205 21.124.205 20.4s.254-1.314.765-1.77c.511-.454 1.119-.682 1.828-.682.727 0 1.342.228 1.843.682.499.456.75 1.046.75 1.77 0 .725-.251 1.32-.75 1.784-.5.466-1.116.698-1.843.698-.71 0-1.317-.232-1.828-.698z"
              transform="translate(-19 -25) translate(19 25)"
            />
            <g transform="translate(-19 -25) translate(19 25) translate(28.052 7.23)">
              <path
                d="M8.5 6.72c.75 0 1.263-.156 1.545-.466.282-.311.422-.796.422-1.46 0-.992-.209-1.762-.626-2.311-.416-.548-.958-.822-1.624-.822-.354 0-.72.083-1.093.248-.374.166-.725.445-1.048.838-.323.393-.602.915-.841 1.567-.242.651-.402 1.453-.486 2.405H8.5zM4.718 8.27c.06 1.842.516 3.192 1.357 4.05.844.859 1.902 1.288 3.173 1.288.915 0 1.67-.202 2.263-.606.595-.403 1.131-.977 1.61-1.721l1.062.588c-.417 1.367-1.198 2.401-2.342 3.104-1.146.704-2.511 1.056-4.093 1.056-1.126 0-2.156-.177-3.094-.528-.936-.352-1.734-.864-2.39-1.537-.656-.671-1.167-1.498-1.53-2.482C.37 10.5.188 9.378.188 8.116c0-1.18.198-2.255.595-3.228.394-.972.94-1.816 1.64-2.53.697-.713 1.525-1.266 2.484-1.66.956-.392 1.988-.59 3.091-.59 1 0 1.902.16 2.703.482.8.32 1.484.771 2.046 1.35.562.58.994 1.272 1.297 2.08.3.806.452 1.685.452 2.637 0 .58-.063 1.117-.188 1.614h-9.59z"
                mask="url(#oio1dqk65d)"
              />
            </g>
            <path
              d="M61.731 21.43v1.364h-5.873l-4.124-7.262-1.5 1.428c0 .62.006 1.293.018 2.017.01.725.016 1.418.016 2.08l1.904.372v1.365h-7.996V21.43l1.904-.372c.02-.787.03-1.614.03-2.483V3.397l-2.34-.31V1.846L49.921.449l.44.249-.128 4.407v9.962l5.624-5.463-2.31-.34V7.897h7.184v1.365l-2.842.373-3.219 3.072 4.78 8.193 2.28.528zM70.762 21.43v1.364h-7.999V21.43l1.907-.372c.02-.787.03-1.614.03-2.483v-3.849c0-.847-.01-1.556-.03-2.125-.023-.57-.043-1.165-.064-1.785l-2.092-.28V9.357l6.061-2.018.468.31-.094 4.438V18.589c0 .88.01 1.702.03 2.468l1.783.372zM66.211 4.934c-.71 0-1.317-.233-1.828-.699-.51-.464-.765-1.059-.765-1.784 0-.724.254-1.313.765-1.77C64.894.228 65.502 0 66.211 0c.727 0 1.342.227 1.843.682.499.456.75 1.045.75 1.769 0 .725-.251 1.32-.75 1.784-.5.466-1.116.699-1.843.699"
              transform="translate(-19 -25) translate(19 25)"
            />
          </g>
        </g>
      </g>
    </MekiSvgIcon>
  );
};
