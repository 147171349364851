import { styled } from '@mui/system';
import { IconButton, Palette, TextField, Typography } from '@mui/material';
interface RootProps {
  changeBackgroundColor?: boolean;
  fixedPosition?: boolean;
  desktop: boolean;
}
interface TextBoxProps {
  desktop: boolean;
  searchBoxWidthDesktop: number;
  searchBoxWidth: number;
  searchBoxHeight: number;
  searchBoxPadding: number;
  changeBackgroundColor: boolean;
  focusSearchBox: boolean;
}
interface TextBoxDivProps {
  desktop: boolean;
  focusSearchBox: boolean;
}
interface SuggestionsProps {
  desktop: boolean;
  searchBoxWidthDesktop: number;
  focusSearchBox: boolean;
}
interface SearchItemsProps {
  desktop: boolean;
  searchBoxWidthDesktop: number;
}
interface SearchSuggestionsDivProps {
  desktop: boolean;
}
const Root = styled('div', {
  shouldForwardProp: (prop: string) =>
    !['changeBackgroundColor', 'fixedPosition', 'desktop'].includes(prop),
})<RootProps>(({ theme, changeBackgroundColor, fixedPosition, desktop }) => ({
  position: fixedPosition ? 'fixed' : 'sticky',
  top: '13px',
  left: fixedPosition ? '50%' : '0px',
  transform: fixedPosition ? 'translate(-50%, 0%)' : 'none',
  height: 0,
  width: '320px',
  margin: 'auto',
  zIndex: 1100,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: desktop ? 'none' : '1px solid',
      borderColor: changeBackgroundColor
        ? '#EAEAEA'
        : (theme.palette as Palette).common.white,
    },
    '&:hover fieldset': {
      border: desktop ? 'none' : '1px solid',
      borderColor: changeBackgroundColor
        ? '#EAEAEA'
        : (theme.palette as Palette).common.white,
    },
    '&.Mui-focused fieldset': {
      border: desktop ? 'none' : '1px solid',
      borderColor: changeBackgroundColor
        ? '#EAEAEA'
        : (theme.palette as Palette).common.white,
    },
  },
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: '6px',
  },
}));

const SearchBoxDiv = styled('div', {
  shouldForwardProp: (prop: string) =>
    !['focusSearchBox', 'desktop'].includes(prop),
})<TextBoxDivProps>(({ desktop, focusSearchBox }) => ({
  borderRadius: focusSearchBox && desktop ? '19px' : '32px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: desktop ? '#fff' : '',
  pointerEvents: 'none',
  border: desktop ? '1px solid #EAEAEA' : '',
}));

const SearchForm = styled('form')({ pointerEvents: 'none' });

const TextBox = styled(TextField, {
  shouldForwardProp: (prop: string) =>
    ![
      'desktop',
      'searchBoxWidthDesktop',
      'searchBoxWidth',
      'searchBoxHeight',
      'changeBackgroundColor',
      'searchBoxPadding',
      'focusSearchBox',
    ].includes(prop),
})<TextBoxProps>(
  ({
    theme,
    desktop,
    searchBoxWidthDesktop,
    searchBoxWidth,
    searchBoxHeight,
    searchBoxPadding,
    focusSearchBox,
  }) => ({
    width: desktop
      ? searchBoxWidthDesktop.toString() + 'px'
      : searchBoxWidth.toString() + 'px',
    height: searchBoxHeight.toString() + 'px',
    pointerEvents: 'all',
    backgroundColor: (theme.palette as Palette).common.white,
    color: '#EAEAEA',
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.15px',
        color: 'black',
      },
      paddingTop: searchBoxPadding.toString() + 'px',
      paddingRight: '14px',
      paddingBottom: searchBoxPadding.toString() + 'px',
      paddingLeft: '24px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: focusSearchBox && desktop ? '19px 19px 0 0' : '32px',
    },
    borderRadius: focusSearchBox && desktop ? '19px 19px 0 0' : '32px',
  }),
);
const EndInputAdornmentIconButton = styled(IconButton)(({ theme }) => ({
  height: '24px',
  width: '24px',
  color: (theme.palette as Palette).text.secondary,
}));
const SearchSuggestionsDiv = styled('div', {
  shouldForwardProp: (prop: string) =>
    !['desktop', 'searchBoxWidthDesktop', 'focusSearchBox'].includes(prop),
})<SuggestionsProps>(
  ({ theme, desktop, searchBoxWidthDesktop, focusSearchBox }) => ({
    width: desktop ? searchBoxWidthDesktop.toString() + 'px' : '100vw',
    height: desktop ? '' : 'calc(100vh - 64px)',
    marginTop: desktop ? 0 : 12,
    borderTop: focusSearchBox && !desktop ? '1px solid black' : 'none',
    [theme.breakpoints.up(768)]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: 'none',
    },
    borderRadius: desktop ? '32px' : '',
    pointerEvents: desktop ? 'all' : focusSearchBox ? 'all' : 'none',
  }),
);
const SearchItemsDiv = styled('div', {
  shouldForwardProp: (prop: string) =>
    !['desktop', 'searchBoxWidthDesktop'].includes(prop),
})<SearchItemsProps>(({ theme, desktop, searchBoxWidthDesktop }) => ({
  width: desktop ? searchBoxWidthDesktop.toString() + 'px' : '100vw',
  backgroundColor: (theme.palette as Palette).common.white,
  height: desktop ? '' : '100vh',
  '&:last-child': {
    borderBottomLeftRadius: desktop ? '32px' : '',
    borderBottomRightRadius: desktop ? '32px' : '',
  },
}));
const SearchSuggestionHeaderDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'desktop',
})<SearchSuggestionsDivProps>(({ theme, desktop }) => ({
  height: '40px',
  padding: '7px 16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: desktop ? '32px' : '',
  backgroundColor: (theme.palette as Palette).common.white,
}));
const SuggestionsTitle = styled(Typography)({
  fontSize: '15px',
});
const Divider = styled('hr')({
  margin: '0px',
  border: '0.5px solid #e0e0e0',
});
export {
  Root,
  TextBox,
  EndInputAdornmentIconButton,
  SearchSuggestionsDiv,
  SearchItemsDiv,
  SearchSuggestionHeaderDiv,
  SuggestionsTitle,
  Divider,
  SearchBoxDiv,
  SearchForm,
};
