/*
 * File: index.tsx
 * Project: meki
 * File Created: Friday, 25th September 2020 5:19:51 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Tuesday, 14th December 2021 11:12:04 am
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2020 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

export * from './products';
export * from './highlightsProducts';
