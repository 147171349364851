import React, { useState, Fragment, useMemo } from 'react';
import { Collapse, List } from '@mui/material';
import { useQuery } from '@apollo/client';
import { currentTermsInfo, CurrentTermsResponse } from '@queries/terms/queries';
import { getRoute } from '@utils';
import Link from 'next/link';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import {
  MenuButtonListItem,
  MenuButtonListSubItem,
  MenuListItemButton,
  ListItemTypography,
  ListSubItemTypography,
} from '../styles';
import { useRouter } from 'next/router';

interface DrawerMenuContentProps {
  handleCloseDrawer: () => void;
}

export function InformationSection(props: DrawerMenuContentProps) {
  const { handleCloseDrawer } = props;
  const [openInfo, setOpenInfo] = useState(false);
  const { data } = useQuery<CurrentTermsResponse>(currentTermsInfo('terms'));

  const handleClickInfo = () => {
    setOpenInfo(!openInfo);
  };

  const handleCloseModal = () => {
    handleCloseDrawer();
  };

  const informationCategories = useMemo(
    () => [
      {
        name: 'Farmacia Meki',
        route: getRoute('contact'),
      },
      {
        name: 'Zonas de despacho',
        route: getRoute('deliveryZones'),
      },
      {
        name: 'Info. Paciente',
        route: getRoute('regulatoryInfoGeneral'),
      },
      {
        name: 'Info. Toxicológica',
        route: getRoute('regulatoryInfoToxicology'),
      },
      {
        name: 'Desabastecimiento',
        openFile: () =>
          window.open(
            'https://d1zphfo9y2yvu9.cloudfront.net/docs/carta-de-desabastecimiento.pdf',
            '_blank',
          ),
      },
      {
        name: 'Regulación',
        route: getRoute('regulatoryInfoDecrees'),
      },
      {
        name: 'Términos y condiciones',
        openFile: () => window.open(data?.currentTerms?.url, '_blank'),
      },
      {
        name: 'Política de privacidad',
        openFile: () =>
          window.open(
            'https://d1zphfo9y2yvu9.cloudfront.net/docs/Poli%CC%81tica+de+Privacidad+Meki.pdf',
            '_blank',
          ),
      },
    ],
    [data?.currentTerms],
  );

  const { pathname, query } = useRouter();

  return (
    <>
      <MenuButtonListItem
        name={'Información'}
        openItem={openInfo}
        onClick={handleClickInfo}
        lastItem={false}
      >
        <ListItemTypography>Información</ListItemTypography>
        {openInfo ? (
          <MenuListItemButton>
            <ExpandLess />
          </MenuListItemButton>
        ) : (
          <MenuListItemButton>
            <ExpandMore />
          </MenuListItemButton>
        )}
      </MenuButtonListItem>
      <Collapse in={openInfo} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {informationCategories.map((category) => (
            <Fragment key={category.name}>
              {category.route ? (
                <Link
                  {...category.route?.toModalLink(
                    pathname,
                    query as Record<string, string>,
                  )}
                >
                  <MenuButtonListSubItem
                    onClick={handleCloseModal}
                    lastItem={false}
                  >
                    <ListSubItemTypography>
                      {category.name}
                    </ListSubItemTypography>
                  </MenuButtonListSubItem>
                </Link>
              ) : (
                <MenuButtonListSubItem
                  onClick={category.openFile}
                  lastItem={false}
                >
                  <ListSubItemTypography>{category.name}</ListSubItemTypography>
                </MenuButtonListSubItem>
              )}
            </Fragment>
          ))}
        </List>
      </Collapse>
    </>
  );
}
