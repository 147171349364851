/*
 * File: validateAuthMail.ts
 * Project: meki
 * File Created: Monday, 8th January 2024 4:41:15 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Monday, 8th January 2024 4:45:22 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2024 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { gql } from '@apollo/client';
import { ResolverParams } from '@queries/queries.interfaces';

export const ValidateAuthMailMutation = () => gql`
  mutation ValidateAuthMail($params: ValidateAuthMailParams!) {
    validateAuthMail(params: $params) {
      isValid
      msg
      token
    }
  }
`;

export type ValidateAuthMailParams = ResolverParams<{
  token: string;
}>;

export type ValidateAuthMailMutationResponse = {
  validateAuthMail: {
    isValid: boolean;
    msg: string;
    token: string;
  };
};
