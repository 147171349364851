/*
 * File: useOrderState.tsx
 * Project: meki
 * File Created: Thursday, 29th October 2020 2:41:48 pm
 * Author: Vicente Melin (vicente@inventures.cl)
 * -----
 * Last Modified: Friday, 25th November 2022 8:24:59 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useMutation, useQuery, ApolloQueryResult } from '@apollo/client';
import { Order } from '@interfaces';
import {
  currentOrder,
  CurrentOrderQueryParams,
  CurrentOrderQueryResponse,
} from '@queries/order/queries/currentOrder';
import {
  AssignOrderMutation,
  AssignOrderMutationParams,
  AssignOrderMutationResponse,
} from '@queries/order/mutations';
import { Console } from '@utils';
import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAuth } from './useAuth';
import { useMekiState } from './useMekiState';
import { usePermissions } from './usePermissions';
import { usePersistState } from './usePersistState';
import { errorWithMessage } from '@utils/errors';

interface OrderState {
  orderUuid: string;
  setOrderUuid: (input: string) => void;
  order: Order;
  orderStatus: string;
  hasPaymentMethod: boolean;
  useMekiCredits: boolean;
  setUseMekiCredits: (value: boolean) => void;
  refetch: () => Promise<ApolloQueryResult<CurrentOrderQueryResponse>>;
  loadingCurrentOrder: boolean;
}
const OrderStateContext = createContext<OrderState>({
  orderUuid: null,
  setOrderUuid: () => {
    return;
  },
  order: null,
  orderStatus: '',
  hasPaymentMethod: false,
  useMekiCredits: false,
  setUseMekiCredits: () => {
    return;
  },
  refetch: () => {
    return null;
  },
  loadingCurrentOrder: false,
});
const TAG = '[USE_ORDER_STATE]';
export function useOrderState() {
  const orderState = useContext(OrderStateContext);
  return orderState;
}
interface OrderStateProviderProps {
  children: React.ReactNode;
}
export function OrderStateProvider({ children }: OrderStateProviderProps) {
  const {
    pathname,
    query: { pathname: queryPathname },
    asPath,
  } = useRouter();
  const { appUser: user, loading: authLoading } = useAuth();
  const { userCan } = usePermissions();
  const { userEmail } = useMekiState();

  const isAssistant = userCan('MANAGE_USER');
  const [orderUuid, setterOrderUuid] = usePersistState<string>('oid', '');
  const [useMekiCredits, setUseMekiCredits] = useState(false);

  const [fetchPolicy, setFetchPolicy] = useState<
    'cache-only' | 'cache-and-network'
  >('cache-and-network');

  const {
    data: { currentOrder: order } = {},
    loading,
    refetch,
    client,
  } = useQuery<CurrentOrderQueryResponse, CurrentOrderQueryParams>(
    currentOrder(),
    {
      variables: { params: { orderUuid: orderUuid || null } },
      skip: !orderUuid && !user,
      fetchPolicy,
    },
  );
  const setOrderUuid = useCallback(
    (newOrderUuid: string) => {
      Console.log({
        msg: TAG + 'use setOrderUuid',
        newOrderUuid,
        trace: new Error().stack,
      });
      if (newOrderUuid === orderUuid) return;
      setterOrderUuid(newOrderUuid);
      if (!newOrderUuid) {
        client.cache.writeQuery<
          CurrentOrderQueryResponse,
          CurrentOrderQueryParams
        >({
          query: currentOrder(),
          data: { currentOrder: null },
          variables: { params: {} },
        });
        client.cache.writeQuery<
          CurrentOrderQueryResponse,
          CurrentOrderQueryParams
        >({
          query: currentOrder(),
          data: { currentOrder: null },
        });
        client.cache.writeQuery<
          CurrentOrderQueryResponse,
          CurrentOrderQueryParams
        >({
          query: currentOrder(),
          data: { currentOrder: null },
          variables: { params: { orderUuid: null } },
        });
      }
    },
    [orderUuid, setterOrderUuid, client.cache],
  );

  const [assignOrder] = useMutation<
    AssignOrderMutationResponse,
    AssignOrderMutationParams
  >(AssignOrderMutation('assignInOrdersState'));

  Console.log('Current order uuid', {
    asPath,
    orderUuid,
    currentOrderQuery: order,
    fromStorage:
      typeof window !== 'undefined' ? window.localStorage.getItem('oid') : null,
  });
  const userIdRef = useRef(user?.uuid);
  const userRef = useRef(user);
  const userEmailRef = useRef(userEmail);
  const clearFromApolloCache = useCallback(() => {
    setOrderUuid('');
  }, [setOrderUuid]);

  useEffect(
    function handleClearOrderUuid() {
      const fromCache = Object.entries(
        (client.cache as unknown as { data: { data: Order[] } }).data.data,
      )
        .filter(([key]) => key.includes('Order'))
        .find(([, val]) => val.uuid === orderUuid)?.[1];
      const thisOrder =
        order ??
        (fromCache ? { ...fromCache, user: { ...fromCache.user } } : undefined);
      if (!thisOrder?.user?.id && thisOrder?.user?.__ref) {
        thisOrder.user.id = +(thisOrder.user.__ref as string).replace(
          'User:',
          '',
        );
      }
      // No draft orders selected
      Console.log(TAG + ' Clear', {
        order,
        thisOrder,
        noDraft: thisOrder?.status,
        user,
        userRef: userRef.current,
        authLoading,
      });
      if (authLoading) return;

      if (
        thisOrder &&
        thisOrder.status &&
        !['DRAFT', 'PAYMENT_VALIDATION'].includes(thisOrder.status)
      ) {
        Console.log(TAG + ' Clear order due DRAFT', {
          thisOrder,
          pathname,
          queryPathname,
        });
        setOrderUuid('');
      }
      // Handle logout
      if (!user && userRef.current) {
        Console.log(TAG + ' Clear order due logout', {
          user,
          ref: userRef.current,
        });

        userRef.current = null;
        setOrderUuid('');
      }
      const orderUserId = thisOrder?.user?.__ref
        ? +(thisOrder.user.__ref as string).replace('User:', '')
        : thisOrder?.user?.id;
      // Order with no user
      if (!isAssistant && thisOrder && user?.id && !orderUserId) {
        void assignOrder({ variables: { params: { orderUuid } } }).then(() =>
          refetch(),
        );
        return;
      }
      // Not my order
      if (!isAssistant && thisOrder && user?.id !== orderUserId) {
        Console.log(TAG + ' Clear order not my order', {
          user,
          thisOrder,
          orderUserId,
        });
        setOrderUuid('');

        void refetch();
      }
    },
    [
      assignOrder,
      authLoading,
      clearFromApolloCache,
      client.cache,
      isAssistant,
      order,
      orderUuid,
      pathname,
      queryPathname,
      refetch,
      setOrderUuid,
      user,
    ],
  );
  useEffect(
    function setQueryOrderUuidToState() {
      Console.log('[USE_ORDER_STATE]use setOrderUuid set', {
        order,
        user,
        orderUuid,
        loading,
      });
      if (loading) return;
      if (
        order?.uuid &&
        ['DRAFT', 'PAYMENT_VALIDATION'].includes(order?.status) &&
        !orderUuid &&
        (isAssistant ||
          (!user && !order.user) ||
          (user && user.id === order.user?.id))
      ) {
        setOrderUuid(order.uuid);
        return;
      }
      if (
        orderUuid &&
        user &&
        !loading &&
        (!order || (order?.uuid && order.uuid !== orderUuid)) &&
        !isAssistant
      ) {
        const cacheOrder = Object.entries(
          (client.cache as unknown as { data: { data: Order[] } }).data.data,
        )
          .filter(([key]) => key.includes('Order'))
          .find(([, val]) => val.uuid === orderUuid)?.[1];
        if (
          cacheOrder?.status &&
          !['DRAFT', 'PAYMENT_VALIDATION'].includes(cacheOrder?.status)
        ) {
          Console.log(
            TAG + 'clear due no order server and cached order is draft',
            { cacheOrder },
          );
          setOrderUuid(null);
          return;
        }
        assignOrder({ variables: { params: { orderUuid } } })
          .then(() => {
            return refetch();
          })
          .catch((e) => {
            if (errorWithMessage(e)) {
              if (e.message === 'NON_DRAFT_ORDER') {
                setOrderUuid(null);
                return;
              }
            }
            if (order?.uuid) setOrderUuid(order.uuid);
            Console.error(TAG + ' Error on assign/refetch', {
              e: e as unknown,
            });
          });
      }
    },
    [
      assignOrder,
      clearFromApolloCache,
      client.cache,
      isAssistant,
      loading,
      order,
      orderUuid,
      pathname,
      queryPathname,
      refetch,
      setOrderUuid,
      user,
    ],
  );
  useEffect(
    function handleRefetchCurrentOrder() {
      if (/\/pedidos\/.*\/cargar/.exec(asPath)) {
        Console.log(TAG + 'Do not refetch due path', {
          asPath,
          user: user?.uuid,
        });
        return;
      }
      if (user?.uuid !== userIdRef.current && user?.uuid) {
        // Auth Change
        Console.log(TAG + ' Refetch due auth change', {
          user: user?.uuid,
          userIdRef: userIdRef.current,
        });
        void refetch().then((res) => {
          Console.log(TAG + ' Refetched current order', { res });
        });
      }
      if (userEmail !== userEmailRef.current) {
        if (userEmail) {
          // Handle change from null to ''
          Console.log(TAG + ' Refetch due user email change', {
            userEmail,
            userEmailRef: userEmailRef.current,
          });
          setOrderUuid('');
          void refetch().then((res) => {
            Console.log(TAG + ' Refetched current order', { res });
          });
        }
        userEmailRef.current = userEmail;
      }
      userIdRef.current = user?.uuid;
    },
    [user?.uuid, refetch, userEmail, setOrderUuid, asPath],
  );

  const orderState = useMemo<OrderState>(() => {
    if (!orderUuid) {
      return {
        orderUuid,
        setOrderUuid,
        order: null,
        orderStatus: null,
        hasPaymentMethod: null,
        useMekiCredits,
        setUseMekiCredits,
        refetch,
        loadingCurrentOrder: false,
      };
    }
    return {
      orderUuid,
      setOrderUuid,
      order,
      orderStatus: order?.status,
      hasPaymentMethod: loading ? null : !!order?.paymentMethod,
      useMekiCredits,
      setUseMekiCredits,
      refetch,
      loadingCurrentOrder: loading,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, JSON.stringify(order), orderUuid, setOrderUuid, useMekiCredits]);

  useEffect(() => {
    const fromCache = Object.entries(
      (client.cache as unknown as { data: { data: Order[] } }).data.data,
    )
      .filter(([key]) => key.includes('Order'))
      .find(([, val]) => val.uuid === orderUuid)?.[1];
    const thisOrder =
      order ??
      (fromCache ? { ...fromCache, user: { ...fromCache.user } } : undefined);

    if (thisOrder?.status === 'PAYMENT_VALIDATION') {
      setFetchPolicy('cache-only');
    } else {
      setFetchPolicy('cache-and-network');
    }
  }, [client.cache, order, orderUuid]);

  return (
    <OrderStateContext.Provider value={orderState}>
      {children}
    </OrderStateContext.Provider>
  );
}
