/*
 * File: assignOrder.tsx
 * Project: meki
 * File Created: Friday, 23rd October 2020 5:25:01 pm
 * Author: Vicente Melin (vicente@inventures.cl)
 * -----
 * Last Modified: Thursday, 15th April 2021 12:52:24 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { ResolverParams } from '@queries/queries.interfaces';
import { Order } from '@interfaces';

export const AssignOrderMutation = (name: string) => gql`
  mutation ${name}($params: AssignOrderParams!) {
    assignOrder(params: $params) {
      id
      uuid
      user {
        id
      }
    }
  }
`;

export type AssignOrderMutationParams = ResolverParams<{
  orderUuid: string;
}>;

export type AssignOrderMutationResponse = { assignOrder: Order };
