/* eslint-disable no-console */
/*
 * File: logger.ts
 * Project: meki
 * File Created: Monday, 14th September 2020 1:11:30 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Monday, 31st May 2021 10:55:30 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { ALLOW_LOGS } from '@config/environment';

interface MyConsole extends Console {
  addLogger: (logger: Console) => void;
}
const ConsoleGenerator = () => {
  const loggers: Console[] = [];
  return {
    group: (...args) => {
      loggers
        .filter((logger) => !!logger.group)
        .forEach((logger) => {
          logger.group(...args);
        });
    },

    groupEnd: () => {
      loggers
        .filter((logger) => !!logger.groupEnd)
        .forEach((logger) => {
          logger.groupEnd();
        });
    },
    log: (...args) => {
      loggers.forEach((logger) => {
        logger.log(...args);
      });
    },
    error: (...args) => {
      loggers.forEach((logger) => {
        logger.error(...args);
      });
    },
    info: (...args) => {
      loggers.forEach((logger) => {
        logger.info(...args);
      });
    },
    warn: (...args) => {
      loggers.forEach((logger) => {
        logger.warn(...args);
      });
    },
    addLogger: (logger) => {
      loggers.push(logger);
    },
  } as MyConsole;
};

const ServerConsole = {
  log: (...args) => {
    if (typeof window !== 'undefined') return;
    console.log(...args);
  },
  error: (...args) => {
    if (typeof window !== 'undefined') return;
    console.error(...args);
  },
  info: (...args) => {
    if (typeof window !== 'undefined') return;
    console.info(...args);
  },
  warn: (...args) => {
    if (typeof window !== 'undefined') return;
    console.warn(...args);
  },
} as Console;

export const Console = ConsoleGenerator();
if (ALLOW_LOGS) {
  Console.addLogger(console);
}
Console.addLogger(ServerConsole);
