import React, { useCallback, useContext, useMemo, useState } from 'react';

interface OpenModalContextValue {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const OpenModalContext = React.createContext<OpenModalContextValue>({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
});

export const useOpenModal = () => {
  const value = useContext(OpenModalContext);
  return value;
};

interface OpenModalProviderProps {
  children: React.ReactNode;
}

export const OpenModalProvider = ({ children }: OpenModalProviderProps) => {
  const [open, setOpen] = useState(false);
  const openModalFunction = useCallback(() => {
    setOpen(true);
  }, []);
  const closeModalFunction = useCallback(() => {
    setOpen(false);
  }, []);
  const value = useMemo(() => {
    return {
      isModalOpen: open,
      openModal: openModalFunction,
      closeModal: closeModalFunction,
    };
  }, [open, openModalFunction, closeModalFunction]);
  return (
    <OpenModalContext.Provider value={value}>
      {children}
    </OpenModalContext.Provider>
  );
};
