/*
 * File: useModalRouter.ts
 * Project: meki
 * File Created: Tuesday, 11th May 2021 11:44:12 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Monday, 23rd August 2021 5:46:45 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Console, Url } from '@utils';
import { NextRouter, useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

export function useModalRouter(): NextRouter {
  const router = useRouter();
  const push = useCallback(
    (url: Url) => {
      Console.log({
        msg: '[useModalRouter] push',
        push: url.toModalPush(
          router.pathname,
          router.query as Record<string, string>,
        ),
      });
      return router.push(
        ...url.toModalPush(
          router.pathname,
          router.query as Record<string, string>,
        ),
      );
    },
    [router],
  );
  const replace = useCallback(
    (url: Url) => {
      Console.log({
        msg: '[useModalRouter] replace',
        replace: url.toModalPush(
          router.pathname,
          router.query as Record<string, string>,
        ),
      });
      return router.replace(
        ...url.toModalPush(
          router.pathname,
          router.query as Record<string, string>,
        ),
      );
    },
    [router],
  );
  const modalRouter = useMemo(() => {
    return { ...router, push, replace };
  }, [push, replace, router]);
  return modalRouter;
}
