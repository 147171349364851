/*
 * File: useDebouncedCallback.ts
 * Project: @inventures/react-lib
 * File Created: Wednesday, 7th October 2020 11:59:01 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Monday, 16th January 2023 3:21:37 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { useCallback, useRef } from 'react';
export function useDebouncedCallback<T, R>(
  callback: (...args: T[]) => R,
  time: number,
): (...args: T[]) => Promise<R> {
  const timeoutRef = useRef(setTimeout(() => null, 0));
  return useCallback(
    async (...args: T[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      let execute = false;
      await new Promise((res) => {
        timeoutRef.current = setTimeout(() => {
          res(null);
          execute = true;
        }, time);
      });

      if (execute) return callback(...args);
      return null;
    },
    [callback, time],
  );
}
