/*
 * File: useFacebookPixel.tsx
 * Project: meki
 * File Created: Monday, 7th November 2022 11:56:27 am
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Friday, 13th October 2023 4:25:30 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { Console } from '@utils';
import { useCallback } from 'react';
import {
  PixelEvent,
  ViewContentParameters,
  SearchParameters,
  AddToCartParameters,
  AddToWishlistParameters,
  InitiateCheckoutParameters,
  AddPaymentInfoParameters,
  PurchaseParameters,
  LeadParameters,
  CompleteRegistrationParameters,
  CustomParameters,
} from '@interfaces/facebookPixel.interface';
import { NEXT_PUBLIC_PIXEL_ID } from '@config/environment';

declare global {
  interface Window {
    fbq: PixelEvent;
  }
}
export function useFacebokPixelEvent() {
  const trackFBEvent = useCallback(
    (
      eventType: string,
      eventName: string,
      parameters:
        | ViewContentParameters
        | SearchParameters
        | AddToCartParameters
        | AddToWishlistParameters
        | InitiateCheckoutParameters
        | AddPaymentInfoParameters
        | PurchaseParameters
        | LeadParameters
        | CompleteRegistrationParameters
        | CustomParameters,
      eventId?: string,
    ) => {
      Console.log('[FACEBOOK PIXEL][TRACK]', {
        eventType,
        eventName,
        parameters,
        eventId,
        sendPixel: !!NEXT_PUBLIC_PIXEL_ID,
        windowfbq: window.fbq,
      });
      if (NEXT_PUBLIC_PIXEL_ID) {
        if (eventId) {
          window.fbq(eventType, eventName, parameters, { eventID: eventId });
        } else {
          window.fbq(eventType, eventName, parameters);
        }
      }
    },
    [],
  );

  return { trackFBEvent };
}
