import React, { useCallback, useMemo, useEffect } from 'react';
import dynamic from 'next/dynamic';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CircularProgress from '@mui/material/CircularProgress';

import { useQuery } from '@apollo/client';
import { useCartMenu } from '@hooks/useCartMenu';
import { useOrderState } from '@hooks/useOrderState';
import { useSegment } from '@hooks/useSegment';

import {
  fetchOrderCart,
  FetchOrderCartQueryResponse,
} from '@queries/order/queries/fetchOrderCart';

import {
  DrawerCartShoppingCarteBadge,
  DrawerCartShoppingCartIcon,
  DrawerCartSwipeableDrawer,
  DrawerCartAppBar,
  TitleTypography,
  DrawerCartCloseIconButton,
} from './styles/DrawerCart.styles';

interface CartProps {
  barChange?: boolean;
}

const CartIndex = dynamic(
  () => {
    const promise = import('./').then((mod) => mod.CartIndex);
    return promise;
  },
  { ssr: true, loading: () => null },
);

export function DrawerCart(props: CartProps) {
  const { barChange } = props;
  const { orderUuid } = useOrderState();
  const {
    isOpen: isCartMenuOpen,
    open: openCartMenu,
    close: closeCartMenu,
    isLoading: isCartMenuLoading,
    stopLoading: stopCartMenuLoading,
  } = useCartMenu();

  const { track } = useSegment();
  const toggleDrawer = isCartMenuOpen;

  // Cart Query Hooks:
  const { data: cartQueryData } = useQuery<FetchOrderCartQueryResponse>(
    fetchOrderCart('cartQuery'),
    {
      variables: {
        params: { uuid: orderUuid },
      },
      fetchPolicy: 'cache-first',
      skip: !orderUuid,
    },
  );

  const cartQuantity = useMemo(() => {
    return cartQueryData?.order?.orderDetails.reduce(
      (acc, { quantity }) => acc + quantity,
      0,
    );
  }, [cartQueryData?.order?.orderDetails]);

  const handleToggleDrawer = useCallback(
    (event?: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      if (toggleDrawer) {
        closeCartMenu();
      } else {
        openCartMenu();
        void track('cart view', {
          access: 'navbar icon',
          products: cartQueryData?.order?.orderDetails.length ?? 0,
        });
      }
    },
    [
      toggleDrawer,
      closeCartMenu,
      openCartMenu,
      track,
      cartQueryData?.order?.orderDetails.length,
    ],
  );

  useEffect(() => {
    stopCartMenuLoading();
  }, [stopCartMenuLoading, cartQuantity]);

  return (
    <div>
      <DrawerCartShoppingCartIcon
        color="inherit"
        onClick={handleToggleDrawer}
        cartQuantity={cartQuantity > 0}
        barChange={barChange}
        size="large"
      >
        <DrawerCartShoppingCarteBadge
          data-testid="cart-badge"
          badgeContent={
            isCartMenuLoading ? (
              <CircularProgress size={8} thickness={4} color="inherit" />
            ) : (
              cartQuantity
            )
          }
          color="error"
          barChange={barChange}
        >
          <ShoppingCartOutlinedIcon />
        </DrawerCartShoppingCarteBadge>
      </DrawerCartShoppingCartIcon>
      <DrawerCartSwipeableDrawer
        anchor={'right'}
        open={toggleDrawer}
        onClose={handleToggleDrawer}
        onOpen={handleToggleDrawer}
      >
        <DrawerCartAppBar>
          <TitleTypography variant="h6">Carro</TitleTypography>
          <DrawerCartCloseIconButton onClick={handleToggleDrawer}>
            <CloseRoundedIcon />
          </DrawerCartCloseIconButton>
        </DrawerCartAppBar>
        {toggleDrawer && <CartIndex />}
      </DrawerCartSwipeableDrawer>
    </div>
  );
}
