/*
 * File: fetchOrderCart.tsx
 * Project: meki
 * File Created: Monday, 18th July 2022 11:09 am
 * Author: Jose Fredes (jose@inventures.cl)
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { ResolverParams } from '@queries/queries.interfaces';

export const fetchOrderCart = (name: string) => gql`
  query ${name}($params: GetOrderParams!) {
  order(params: $params ){
    id
    uuid
    orderGroupId
    orderDetails {
      id
      uuid
      quantity
    }
  }
}
`;

export type OrderDetailCart = {
  id: number;
  uuid: string;
  quantity: number;
};

export type FetchOrderCartQueryResponse = {
  order: {
    uuid: string;
    orderDetails: OrderDetailCart[];
  };
};
export type FetchOrderCartQueryParams = ResolverParams<{
  uuid: string;
}>;
