/*
 * File: referralsNoNewUserDialog.ts
 * Project: meki
 * File Created: Monday, 19th June 2023 11:15:57 am
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Friday, 21st July 2023 3:32:33 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2023 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

const IconContainer = styled('div')({
  position: 'relative',
  height: '14px',
  width: '14px',
  marginLeft: '90%',
  marginTop: '20px',
});

const ReferredUserDiv = styled('div')({
  maxWidth: '336px',
});

const DialogTitleTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '17px',
  lineHeight: '150%',
  letterSpacing: '0.15px',
  color: '#414046',
  textAlign: 'center',
  marginBottom: '12px',
});

const DialogInfoTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  letterSpacing: '-0.011em',
  textAlign: 'center',
  color: '#414046',
  margin: '0px 18px 12px 18px',
});

const LinkTypography = styled(Typography)({
  color: '#0A7ABE',
  textDecoration: 'underline',
  cursor: 'pointer',
});

const BoldSpan = styled('span')({
  fontWeight: 600,
});

const ShareButton = styled(Button)({
  background: '#0A7ABE',
  borderRadius: '100px',
  color: '#FFFFFF',
  height: '42px',
  width: '199px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textDecoration: 'none',
  textTransform: 'none',
});

const ShareButtonDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '17px 0px',
});

export {
  IconContainer,
  ReferredUserDiv,
  DialogTitleTypography,
  DialogInfoTypography,
  BoldSpan,
  ShareButton,
  ShareButtonDiv,
  LinkTypography,
};
