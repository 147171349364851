/*
 * File: fetchOrder.tsx
 * Project: meki
 * File Created: Wednesday, 21st October 2020 10:17:42 am
 * Author: Vicente Melin (vicente@inventures.cl)
 * -----
 * Last Modified: Friday, 21st October 2022 1:39:05 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { Order } from '@interfaces';
import { ResolverParams } from '@queries/queries.interfaces';

export const fetchOrder = (name: string) => gql`
  query ${name}($params: GetOrderParams!) {
    order(params: $params) {
      id
      uuid
      orderGroupId
      isMine
      deliveryDate
      minDeliveryDate
      status
      user {
        id
        email
      }
      address {
        id
      }
      paymentMethod {
        id
        type
        data
      }
      deliveryCost
      orderNumber
      orderDetails {
        id
        createdAt
        uuid
        quantity
        status
        price
        prescription {
          id
          url
        }
        order {
          id
        }
        product {
          id
          uuid
          slug
          name
          price
          imagesUrl
          prescriptionType
          quantity
          unit
          format
          concentration
          container
          containerQuantity
          availability {
            status
            statusWithoutStock
            stock
          }
        }
      }
    }
  }
  `;

export type FetchOrderQueryResponse = { order: Order };
export type FetchOrderQueryParams = ResolverParams<{
  uuid: string;
}>;
