/*
 * File: logrocket.ts
 * Project: meki
 * File Created: Saturday, 16th January 2021 11:46:20 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Wednesday, 16th November 2022 5:45:41 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { ALWAYS_RECORD, NEXT_PUBLIC_LOGROCKET_ID } from '@config/environment';
import { Console } from './logger';
declare global {
  interface Window {
    logrocketInitialized: boolean;
  }
}
export const initializeLogRocket = () => {
  // only initialize when in the browser
  if (typeof window !== 'undefined' && NEXT_PUBLIC_LOGROCKET_ID) {
    window.logrocketInitialized = false;
    const interval = setInterval(() => {
      void (async () => {
        if (window.userId || ALWAYS_RECORD) {
          window.logrocketInitialized = true;
          clearInterval(interval);
          const LogRocket = (await import('logrocket')).default;
          const setupLogRocketReact = (await import('logrocket-react')).default;
          LogRocket.init(NEXT_PUBLIC_LOGROCKET_ID);
          // plugins should also only be initialized when in the browser
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          setupLogRocketReact(LogRocket);
          Console.addLogger({
            log: (...args) => LogRocket.log(...args),
            info: (...args) => LogRocket.info(...args),
            warn: (...args) => LogRocket.warn(...args),
            debug: (...args) => LogRocket.debug(...args),
            error: (...args) => LogRocket.error(...args),
          } as Console);
        }
      })();
    }, 500);
  }
};
