/*
 * File: openreplay.ts
 * Project: meki
 * File Created: Friday, 15th October 2021 7:23:06 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Tuesday, 28th March 2023 11:06:51 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import {
  OPEN_REPLAY_INGEST,
  OPEN_REPLAY_KEY,
  DISABLE_OPEN_REPLAY_SECURE_MODE,
} from '@config/environment';
import { useCallback, useEffect, useMemo } from 'react';
import { Console } from '@utils';

export const trackerGraphQL: { current?: (...args: unknown[]) => unknown } = {
  current: undefined,
};
const openreplay: {
  current: {
    start: () => Promise<unknown>;
    use: (...params: unknown[]) => (...args: unknown[]) => unknown;
    setUserID: (...params: unknown[]) => void;
    event(key: string, payload?: unknown, issue?: boolean): void;
    issue(key: string, payload?: unknown): void;
  };
} = { current: undefined };
const loading = {
  current: false,
};
export const getOpenreplay = (() => {
  return async () => {
    if (loading.current) {
      await new Promise((res) => {
        const interval = setInterval(() => {
          if (loading.current) return;
          clearInterval(interval);
          res(null);
        }, 100);
      });
      return openreplay.current;
    }
    if (!OPEN_REPLAY_KEY) return null;
    if (openreplay.current) return openreplay.current;

    loading.current = true;
    const Tracker = (await import('@openreplay/tracker/cjs')).default;
    openreplay.current = new Tracker({
      projectKey: OPEN_REPLAY_KEY,
      obscureInputEmails: false,
      obscureInputNumbers: false,
      obscureTextEmails: false,
      obscureTextNumbers: false,
      ingestPoint: OPEN_REPLAY_INGEST ?? undefined,
      respectDoNotTrack: false,
      __DISABLE_SECURE_MODE: DISABLE_OPEN_REPLAY_SECURE_MODE, //add this to be able to send events in localhost
    });
    const recordGraphQL = (await import('@openreplay/tracker-graphql/cjs'))
      .default;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    trackerGraphQL.current = openreplay.current?.use(recordGraphQL());
    loading.current = false;

    return openreplay.current;
  };
})();

export function useOpenReplay() {
  useEffect(() => {
    void getOpenreplay().then((openReplay) => {
      openReplay?.start();
    });
  }, []);
}
export const openReplayTrack = async (event: string, payload?: unknown) => {
  Console.log('[OPEN_REPLAY][TRACK_EVENT]', {
    event,
    payload,
  });
  if (OPEN_REPLAY_KEY) {
    await getOpenreplay().then((openReplay) => {
      openReplay?.event(event, payload);
    });
  }
};

export function useOpenReplayTracking() {
  const trackEvent = useCallback(async (event: string, payload?: unknown) => {
    Console.log('[OPEN_REPLAY][TRACK_EVENT]', {
      event,
      payload,
    });
    if (OPEN_REPLAY_KEY) {
      await getOpenreplay().then((openReplay) => {
        openReplay?.event(event, payload);
      });
    }
  }, []);
  const trackIssue = useCallback(async (issue: string, payload?: unknown) => {
    Console.log('[OPEN_REPLAY][TRACK_ISSUE]', {
      issue,
      payload,
    });
    if (OPEN_REPLAY_KEY) {
      await getOpenreplay().then((openReplay) => {
        openReplay?.issue(issue, payload);
      });
    }
  }, []);
  const functions = useMemo(() => {
    return { trackEvent, trackIssue };
  }, [trackEvent, trackIssue]);
  return functions;
}
