/*
 * File: SeachContainer.tsx
 * Project: meki
 * File Created: Thursday, 29th April 2021 6:33:18 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Thursday, 23rd November 2023 2:56:03 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

type SearchContentContainerProps = {
  children: React.ReactNode;
  bottomNavigation?: boolean;
};
import { styled } from '@mui/system';
interface CointeinerProps {
  bottomNavigation?: boolean;
}
const ContainerDiv = styled('div', {
  shouldForwardProp: (prop) => !['bottomNavigation'].includes(prop as string),
})<CointeinerProps>(({ bottomNavigation }) => ({
  paddingTop: 64,
  flexFlow: 'column',
  top: 0,
  bottom: 0,
  minHeight: '100vh',
  ...(bottomNavigation && { marginBottom: 52 }),
}));
export function SearchContentContainer(props: SearchContentContainerProps) {
  /**
   * Custom Meki container that includes top padding for the
   * Meki Header
   *
   * @param BottomActionTabProps - left and right react components.
   * @returns React Component
   *
   * @beta
   */
  return <ContainerDiv {...props}>{props.children}</ContainerDiv>;
}
