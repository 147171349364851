/*
 * File: useLoggedReducer.ts
 * Project: meki
 * File Created: Monday, 31st May 2021 10:49:47 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Monday, 31st May 2021 10:51:29 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Console } from '@utils';
import { useCallback } from 'react';

type Reducer<State = unknown, Action = unknown> = (
  state: State,
  action: Action,
) => State;

const getCurrentTimeFormatted = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const seconds = currentTime.getSeconds();
  const milliseconds = currentTime.getMilliseconds();
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
};
export function useLoggedReducer<R extends Reducer>(reducer: R): R {
  const reducerWithLogger = useCallback(
    (state: unknown, action: unknown) => {
      const next = reducer(state, action);
      Console.group?.(
        `%cAction: %c${
          (action as { type: string })?.type
        } %cat ${getCurrentTimeFormatted()}`,
        'color: lightgreen; font-weight: bold;',
        'color: white; font-weight: bold;',
        'color: lightblue; font-weight: lighter;',
      );

      Console.log(
        '%cPrevious State:',
        'color: #9E9E9E; font-weight: 700;',
        state,
      );
      Console.log('%cAction:', 'color: #00A7F7; font-weight: 700;', action);
      Console.log('%cNext State:', 'color: #47B04B; font-weight: 700;', next);
      Console.groupEnd?.();
      return next;
    },
    [reducer],
  );

  return reducerWithLogger as R;
}
