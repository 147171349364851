/*
 * File: index.tsx
 * Project: meki
 * File Created: Tuesday, 27th December 2022 12:08:04 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Tuesday, 27th December 2022 4:28:35 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React from 'react';
import { styled } from '@mui/system';
import { ProductCard } from './ProductCard';
import { Carousel } from './Carousel';
import { Product } from '@interfaces';

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 10,
  [theme.breakpoints.down(320)]: {
    padding: '10px 0',
  },
}));
const ProductListContainer = styled('div')({
  width: '100%',
  height: 'fit-content',
  maxWidth: 1200,
  margin: 'auto',
});
const ProductList = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 320,
  [theme.breakpoints.up(320)]: {
    width: 'fit-content',
    maxWidth: 'calc(100vw - 20px)',
  },
}));
const TitleContainer = styled('div')({
  width: '100%',
  background: 'white',
  padding: '12px 15px',
  border: '1px solid #eaeaea',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  display: 'block',
  flexDirection: 'column',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});
const Title = styled('span')({
  fontSize: 16,
  fontWeight: 600,
});
const List = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
});

export interface ProductsTypes {
  data: { product: Product; orderId?: number }[];
  loading: boolean;
  title: string;
  location?: string;
}

export function Products(props: ProductsTypes) {
  const { data, loading, title, location } = props;
  const existsDiscountInCarousel = data.some((e) => e.product.discount?.price);

  return (
    <Container>
      <ProductListContainer>
        <ProductList>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <List>
            {data?.map((dict, i, array) => (
              <ProductCard
                product={dict.product}
                key={dict.product.id}
                index={i}
                listLength={array.length}
                location={location}
                existsDiscountInCarousel={existsDiscountInCarousel}
              />
            ))}
            {loading &&
              Array.from({ length: 7 }).map((e, i) => {
                return (
                  <ProductCard.Skeleton
                    listLength={7 + (data?.length ?? 0)}
                    key={i}
                  />
                );
              })}
          </List>
        </ProductList>
      </ProductListContainer>
    </Container>
  );
}
Products.Carousel = Carousel;
