/*
 * File: errors.ts
 * Project: meki
 * File Created: Monday, 30th November 2020 6:03:49 pm
 * Author: Vicente Melin (vicente@inventures.cl)
 * -----
 * Last Modified: Friday, 17th December 2021 9:37:55 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

export enum TRANSBANK_ERROR {
  INVALID_CARD = 'La tarjeta ingresada es inválida. Por favor inténtalo nuevamente.',
  CONNECTION_ERROR = 'Tuvimos un problema de conexión con WebPay. Por favor inténtalo nuevamente.',
  EXCEEDS_MAXIMUN_AMOUNT = 'Al parecer el monto a pagar excede el máximo de tu tarjeta. Por favor inténtalo nuevamente.',
  INVALID_EXPERATION_DATE = 'La fecha de expiración de tu tarjeta es inválida. Por favor inténtalo nuevamente.',
  AUTHENTICATION_PROBLEM = 'WebPay tuvo un problema registrando tu tarjeta. Por favor inténtalo nuevamente.',
  GENERAL_REJECTION = 'WebPay tuvo un problema registrando tu tarjeta. Por favor inténtalo nuevamente.',
  LOCKED_CARD = 'Al parecer tu tarjeta está bloqueada. Por favor inténtalo nuevamente.',
  EXPIRED_CARD = 'Al parecer tu tarjeta está vencida. Por favor inténtalo nuevamente.',
  TRANSACTION_NOT_SUPPORTED = 'WebPay tuvo un problema con la transacción. Por favor inténtalo nuevamente.',
  TRANSACTION_PROBLEM = 'WebPay tuvo un problema con la transacción. Por favor inténtalo nuevamente.',
  TBK_USER_NOT_EXIST = 'WebPay tuvo un problema con la transacción. Por favor inténtalo nuevamente.',
  ONCLICK_MAXIMUN_DAILY_PAYMENT_AMOUNT_EXCEEDED = 'El monto del pedido excede el monto máximo diario de pago con Oneclick. Por favor, escríbenos al WhatsApp para darte alternativas de pago',
  ONCLICK_MAXIMUN_PAYMENT_AMOUNT_EXCEEDED = 'El monto del pedido excede el monto máximo de pago con Oneclick. Por favor, escríbenos al WhatsApp para darte alternativas de pago',
  ONCLICK_MAXIMUN_AMOUNT_DAILY_PAYMENTS_EXCEEDED = 'Has superado el máximo número de pagos hechos con Oneclick en el día. Por favor, escríbenos al WhatsApp para darte alternativas de pago',
}

export function errorWithMessage(e: unknown): e is Error {
  return !!(e as Error)?.message;
}
