import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
  useContext,
} from 'react';
import { Address } from '@interfaces';

interface AddressFormDataContextAttrs {
  data: Partial<Address>;
  setData: Dispatch<SetStateAction<Partial<Address>>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
}

interface AddressFormDataProviderProps {
  children: ReactNode;
}

const AddressFormDataContext = createContext<AddressFormDataContextAttrs>(null);

export function useAddressFormData() {
  const data = useContext(AddressFormDataContext);
  return data;
}

export function AddressFormDataProvider(props: AddressFormDataProviderProps) {
  const [data, setData] = useState<Partial<Address>>();
  const [query, setQuery] = useState('');
  const value = useMemo(
    () => ({
      data,
      setData,
      query,
      setQuery: (e) => {
        setQuery(e);
      },
    }),
    [data, query],
  );
  return (
    <AddressFormDataContext.Provider value={value}>
      {props.children}
    </AddressFormDataContext.Provider>
  );
}
