/*
 * File: pillbox.ts
 * Project: meki
 * File Created: Monday, 8th November 2021 11:32:33 am
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Wednesday, 30th November 2022 11:39:18 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { styled } from '@mui/system';
import { Palette, Typography, IconButton } from '@mui/material';
interface AddProductsProps {
  disabledProduct: boolean;
}
const UpdateQuantityBoxDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  top: '8px',
  right: '13px',
  height: '32px',
  width: '108',
  justifyContent: 'space-around',
  alignItems: 'center',
  backgroundColor: 'white',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
  borderRadius: '17.5px',
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '20px',
  textAlign: 'center',
  letterSpacing: '0.01em',
  margin: 'auto',
  zIndex: 1,
});
const DecrementIconButton = styled(IconButton)({
  color: '#0277BD',
  padding: 0,
  margin: '2px 12px 2px 2px',
  height: '27px',
  width: '27px',
});
const QuantityTextTypography = styled(Typography)({
  fontFamily: 'Poppins',
  width: '9px',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '27px',
  textAlign: 'center',
  letterSpacing: '1%',
  color: 'black',
});
const IncrementIconButton = styled(IconButton)({
  color: '#0277BD',
  padding: 0,
  margin: '2px 2px 2px 12px',
  height: '27px',
  width: '27px',
});
const ButtonContainerDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '80%',
  top: 10,
  width: 'fit-content',
  zIndex: 2,
  [theme.breakpoints.down(770)]: {
    left: '75%',
  },
}));
const PlusQuantityIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'disabledProduct',
})<AddProductsProps>(({ theme, disabledProduct }) => ({
  color: (theme.palette as Palette).primary.main,
  background: 'white',
  boxShadow: '0px 0px 5px 0px #D9D9D9',
  borderRadius: '17.5px',
  height: '27px',
  width: '27px',
  textAlign: 'center',
  fontSize: '13px',
  '&:hover': {
    background: disabledProduct
      ? (theme.palette as Palette).grey[200]
      : '#F5F5F5 !important',
  },
  ...(disabledProduct
    ? {
        backgroundColor: (theme.palette as Palette).grey[200],
        color: (theme.palette as Palette).grey[600],
        boxShadow: 'none',
        cursor: 'not-allowed',
      }
    : {}),
}));

export {
  ButtonContainerDiv,
  DecrementIconButton,
  IncrementIconButton,
  QuantityTextTypography,
  PlusQuantityIconButton,
  UpdateQuantityBoxDiv,
};
