/*
 * File: homeNavBar.tsx
 * Project: meki
 * File Created: Thursday, 28th July 2022 4:01:03 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Thursday, 20th July 2023 10:42:55 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {
  RootDiv,
  HeaderAppBar,
  HeaderToolbar,
  PositionDivLeft,
  PositionDivRight,
  HeaderLeftDiv,
  BackIconButton,
  HeaderRightComponent,
  LoginButton,
  MekiLogDiv,
} from './Header.styles';
import { MekiLogo } from '@components/icons/LogoMeki';
import { useRouter } from 'next/router';
import { useModalRouter } from '@hooks/useModalRouter';
import { getRoute, removeUndefineds } from '@utils';
import { useAuth } from '@hooks/useAuth';
import { useWindowSize } from '@hooks/useWindowSize';
import { DrawerCart } from '@screens/cart/DrawerCart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFocusSearchBox } from '@hooks/useFocusSearchBox';
import { SearchBox } from './searchBox';
import { DrawerMenu } from '@screens/menu';

interface HeaderProps {
  leftComponent?: React.ReactElement;
  alwaysElevation?: boolean;
  autoFocus?: boolean;
  className?: string;
  fixedPosition?: boolean;
}

export function Header(props: HeaderProps) {
  const {
    leftComponent,
    alwaysElevation,
    autoFocus,
    className,
    fixedPosition,
  } = props;
  const size = useWindowSize();
  const desktop = useMemo(() => {
    if (!size.width) return true;
    return size.width >= 768;
  }, [size.width]);
  const { appUser: user } = useAuth();
  const router = useRouter();
  const focusSearchBox = useFocusSearchBox();
  const modalRouter = useModalRouter();
  const [hideInfo, setHideInfo] = useState(false);
  const [boxWidth, setBoxWidth] = useState(262);
  const [atTop, setAtTop] = useState(
    typeof window !== 'undefined' ? window.scrollY === 0 : true,
  );
  const atTopRef = useRef(
    typeof window !== 'undefined' ? window.scrollY === 0 : true,
  );

  useEffect(() => {
    if (size.width <= 340) {
      setBoxWidth(235);
    } else {
      setBoxWidth(262);
    }
    if (size.width < 530) {
      setHideInfo(true);
    } else {
      setHideInfo(false);
    }
  }, [size.width]);
  useEffect(() => {
    const handleScroll = () => {
      const scroll = Math.max(window.scrollY, 0);
      if (scroll === 0 && !atTopRef.current) {
        setAtTop(true);
      }
      if (scroll > 0 && atTopRef.current) {
        setAtTop(false);
      }
      atTopRef.current = scroll === 0;
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClose = useCallback(() => {
    focusSearchBox.onblur();
  }, [focusSearchBox]);

  const elevation = !atTop || alwaysElevation ? 1 : 0;

  return (
    <>
      <RootDiv className={className}>
        <HeaderAppBar position="fixed" color="primary" elevation={elevation}>
          <HeaderToolbar>
            <PositionDivLeft desktop={desktop}>
              <HeaderLeftDiv>
                {!desktop && focusSearchBox.isFocus ? (
                  <BackIconButton
                    onClick={handleClose}
                    edge="start"
                    color="inherit"
                    aria-label="back"
                    size="large"
                  >
                    <ArrowBackIcon />
                  </BackIconButton>
                ) : (
                  <>
                    {leftComponent ? (
                      leftComponent
                    ) : (
                      <DrawerMenu menuIconColorChange={true} />
                    )}
                  </>
                )}
                {!hideInfo && (
                  <MekiLogDiv>
                    <MekiLogo
                      fill={'#000000de'}
                      height="16px"
                      width="auto"
                      onClick={() => {
                        void router.push(getRoute('home'));
                      }}
                    />
                  </MekiLogDiv>
                )}
              </HeaderLeftDiv>
            </PositionDivLeft>
            <PositionDivRight desktop={desktop}>
              <HeaderRightComponent>
                {!hideInfo && (
                  <>
                    {!user ? (
                      <LoginButton
                        size="medium"
                        onClick={() => {
                          void router.push(
                            {
                              pathname: router.pathname,
                              query: removeUndefineds({
                                ...router.query,
                                pathname: '/ingresar',
                                codigo: undefined,
                              }),
                            },
                            {
                              pathname: '/ingresar',
                              query: removeUndefineds({
                                ...modalRouter.query,
                                codigo: undefined,
                              }),
                            },
                          );
                        }}
                      >
                        {size.width >= 1110 ? 'Iniciar sesión' : 'Inicia'}
                      </LoginButton>
                    ) : (
                      <DrawerMenu
                        menuIconColorChange
                        barChange
                        isFromMenuName={`${user.name?.split(' ')[0]} ${
                          user.lastNames?.[0]?.toLocaleUpperCase() ?? ''
                        }.`}
                      ></DrawerMenu>
                    )}
                  </>
                )}
                <DrawerCart barChange={true} />
              </HeaderRightComponent>
            </PositionDivRight>
          </HeaderToolbar>
        </HeaderAppBar>
      </RootDiv>
      <SearchBox
        className={className}
        searchBoxWidth={boxWidth}
        searchBoxHeight={38}
        searchBoxPadding={7.5}
        changeBackgroundColor={true}
        autoScroll={false}
        autoFocusSearchBox={autoFocus}
        fixedPosition={fixedPosition}
      ></SearchBox>
    </>
  );
}
