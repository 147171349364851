import React, { useState, Fragment } from 'react';
import { Avatar, Collapse, List } from '@mui/material';
import Link from 'next/link';
import { getRoute } from '@utils';
import { useRouter } from 'next/router';
import { useSegment } from '@hooks/useSegment';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { MekiLogo } from '../../components/icons/LogoMeki';
import { LoginMenu } from './components/LoginMenu';
import { InformationSection } from './components/InformationSection';
import { HelpSection } from './components/HelpSection';
import {
  ClickableMekiDiv,
  CloseButton,
  DrawerMenuAppBar,
  DrawerMenuToolbar,
  HomeIconContainer,
  MenuButtonListItemWithAvatar,
  MenuButtonListSubItem,
  MenuSubContainer,
  MenuList,
  MenuListItemButton,
  ListItemTypography,
  ListSubItemTypography,
  SubTitleTypography,
  ToHomeButton,
} from './styles';
import { mainCategories } from './data';

interface DrawerMenuContentProps {
  handleCloseDrawer: () => void;
}

export function DrawerMenuContent(props: DrawerMenuContentProps) {
  const { handleCloseDrawer } = props;
  const { push } = useRouter();
  const { track } = useSegment();
  const [open, setOpen] = useState<Record<string, boolean>>({});

  const handleClick = (category: string) => {
    setOpen({ ...open, [category]: !open[category] });
  };

  const closeDrawer = () => {
    handleCloseDrawer();
  };

  const goHome = async () => {
    await push(getRoute('home'));
    handleCloseDrawer();
  };

  return (
    <>
      <DrawerMenuAppBar>
        <DrawerMenuToolbar>
          <ClickableMekiDiv
            onClick={async () => {
              await push(getRoute('home'));
            }}
          >
            <MekiLogo width="60px" height="30px" fill={'#414046'}></MekiLogo>
          </ClickableMekiDiv>
          <CloseButton
            onClick={handleCloseDrawer}
            edge="start"
            color="inherit"
            aria-label="back"
          >
            <CloseRoundedIcon />
          </CloseButton>
        </DrawerMenuToolbar>

        <MenuSubContainer>
          <ToHomeButton onClick={goHome}>
            <HomeIconContainer>
              <Avatar
                alt="home"
                src="/assets/images/menu/sign(cross).webp"
                sx={{ width: 24, height: 24 }}
              />
            </HomeIconContainer>
            <ListItemTypography>Página de inicio</ListItemTypography>

            <MenuListItemButton rotate="left">
              <ExpandMore />
            </MenuListItemButton>
          </ToHomeButton>
        </MenuSubContainer>

        <LoginMenu closeDrawerMenu={handleCloseDrawer} />

        <SubTitleTypography>Productos</SubTitleTypography>

        <MenuList>
          {mainCategories.map((category, index, row) => (
            <Fragment key={category.name}>
              <MenuButtonListItemWithAvatar
                onClick={() => handleClick(category.name)}
                openItem={open[category.name]}
                firstItem={index === 0}
                lastItem={index === row.length - 1}
              >
                <Avatar alt={category.name} src={category.icon} />
                <ListItemTypography>{category.name}</ListItemTypography>
                {open[category.name] ? (
                  <MenuListItemButton>
                    <ExpandLess />
                  </MenuListItemButton>
                ) : (
                  <MenuListItemButton>
                    <ExpandMore />
                  </MenuListItemButton>
                )}
              </MenuButtonListItemWithAvatar>
              <Collapse in={open[category.name]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {category.subCategories.map(
                    (subcategory, subindex, subrow) => (
                      <Fragment key={subcategory.name}>
                        {subcategory.typename === 'SubCategory' ? (
                          <Link
                            {...getRoute('subCategory', {
                              categorySlug: category.slug,
                              subCategorySlug: subcategory.slug,
                              access: 'menu',
                            }).toLink()}
                          >
                            <MenuButtonListSubItem
                              onClick={closeDrawer}
                              lastItem={
                                subindex === subrow.length - 1 &&
                                index !== row.length - 1
                              }
                            >
                              <ListSubItemTypography>
                                {subcategory.name}
                              </ListSubItemTypography>
                            </MenuButtonListSubItem>
                          </Link>
                        ) : (
                          <Link
                            {...getRoute('category', {
                              categorySlug: category.slug,
                            }).toLink()}
                          >
                            <MenuButtonListSubItem
                              onClick={() => {
                                void track('category', {
                                  category: category.name,
                                  location: 'menu',
                                });
                                closeDrawer();
                              }}
                            >
                              <ListSubItemTypography>
                                {subcategory.name}
                              </ListSubItemTypography>
                            </MenuButtonListSubItem>
                          </Link>
                        )}
                      </Fragment>
                    ),
                  )}
                </List>
              </Collapse>
            </Fragment>
          ))}
        </MenuList>

        <MenuList>
          <HelpSection />
          <InformationSection handleCloseDrawer={handleCloseDrawer} />
        </MenuList>
      </DrawerMenuAppBar>
    </>
  );
}
