import { Typography, Button, Dialog } from '@mui/material';
import { styled } from '@mui/system';

const IconContainer = styled('div')({
  position: 'relative',
  height: '14px',
  width: '14px',
  marginLeft: '90%',
  marginTop: '20px',
});

const ContentDiv = styled('div')({
  maxWidth: '336px',
  borderRadius: '10px',
});

const DialogStyled = styled(Dialog)({
  '& .MuiPaper-root': {
    borderRadius: '10px',
  },
});

const DialogTitleTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '17px',
  lineHeight: '150%',
  letterSpacing: '0.15px',
  color: '#414046',
  textAlign: 'center',
  margin: '17px',
});

const DialogInfoTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  letterSpacing: '-0.011em',
  textAlign: 'center',
  color: '#414046',
  margin: '0px 18px 12px 18px',
});

const BoldSpan = styled('span')({
  fontWeight: 600,
});

const ActionButton = styled(Button)({
  background: '#0A7ABE',
  borderRadius: '100px',
  color: '#FFFFFF',
  height: '42px',
  width: '199px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textDecoration: 'none',
  textTransform: 'none',
});

const ActionButtonDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '17px 0px',
});

const HelpButton = styled('a')({
  color: '#0277BD',
  borderColor: '#FFFFFF',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  textAlign: 'center',
  textDecorationLine: 'underline',
  textTransform: 'none',
});

export {
  IconContainer,
  ContentDiv,
  DialogTitleTypography,
  DialogInfoTypography,
  BoldSpan,
  ActionButton,
  ActionButtonDiv,
  HelpButton,
  DialogStyled,
};
