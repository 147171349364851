/*
 * File: useMekiState.tsx
 * Project: meki
 * File Created: Monday, 28th September 2020 9:31:33 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Thursday, 4th May 2023 1:06:42 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDebugStateChange } from './useDebugStateChange';
import { usePersistState } from './usePersistState';
import { useRouter } from 'next/router';
import { useAuth } from './useAuth';
import { ENV, TEST_ENV } from '@config/environment';
import { Prescription } from '@interfaces';

declare global {
  interface Window {
    mekiState: MekiState;
  }
}

type MekiState = {
  referralCode: string;
  setReferralCode: React.Dispatch<React.SetStateAction<string>>;
  lastSearch: string;
  setLastSearch: React.Dispatch<React.SetStateAction<string>>;
  registryForm: Record<string, string>;
  setRegistryFrom: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  uploadedPrescription: null | Prescription;
  setUploadedPrescription: React.Dispatch<
    React.SetStateAction<null | Prescription>
  >;
  plannerPreference: string;
  setPlannerPreference: React.Dispatch<React.SetStateAction<string>>;
  userEmail: string;
  newAccountEmail: string;
  setNewAccountEmail: React.Dispatch<React.SetStateAction<string>>;
  typeOfSingin: string;
  setTypeOfSingin: React.Dispatch<React.SetStateAction<string>>;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
  userToken: string;
  setUserToken: React.Dispatch<React.SetStateAction<string>>;
  insuranceId: number;
  setInsuranceId: React.Dispatch<React.SetStateAction<number>>;
  insuranceRut: string;
  setInsuranceRut: React.Dispatch<React.SetStateAction<string>>;
};
const MekiStateContext = createContext<MekiState>({
  referralCode: '',
  setReferralCode: () => null,
  lastSearch: '',
  setLastSearch: () => null,
  registryForm: {},
  setRegistryFrom: () => null,
  uploadedPrescription: null,
  setUploadedPrescription: () => null,
  plannerPreference: '',
  setPlannerPreference: () => null,
  newAccountEmail: '',
  setNewAccountEmail: () => null,
  typeOfSingin: 'unset',
  setTypeOfSingin: () => null,
  userEmail: '',
  setUserEmail: () => null, // For assistant management
  userToken: '',
  setUserToken: () => null,
  insuranceId: null,
  setInsuranceId: () => null,
  insuranceRut: '',
  setInsuranceRut: () => null,
});

export function useMekiState() {
  const mekiState = useContext(MekiStateContext);
  return mekiState;
}

type MekiStateProviderProps = {
  children: React.ReactNode;
};
export function MekiStateProvider({ children }: MekiStateProviderProps) {
  const { appUser: user, firebaseUser } = useAuth();
  const pathname = useRouter().pathname;
  const userRef = useRef(user);
  const [referralCode, setReferralCode] = usePersistState<string>('rc', '');
  const [lastSearch, setLastSearch] = useState('');
  const [registryForm, setRegistryFrom, clearRegistryForm] = usePersistState(
    'rf',
    {
      name: '',
      lastName: '',
      email: '',
      phonePrefix: '',
      phone: '',
      rut: '',
      code: '',
      source: '',
    },
  );
  const [uploadedPrescription, setUploadedPrescription] =
    useState<null | Prescription>(null);
  const [plannerPreference, setPlannerPreference] = useState<'YES' | 'NO' | ''>(
    '',
  );
  const [typeOfSingin, setTypeOfSingin, clearTypeOfSingin] =
    usePersistState<string>('st', 'unset');
  const [newAccountEmail, setNewAccountEmail] = useState<null | string>();
  const [userEmail, setUserEmail] = usePersistState<string>('useremail', '');
  const [userToken, setUserToken] = usePersistState<string>('usertoken', '');
  const [insuranceId, setInsuranceId] = usePersistState<number>(
    'insurance',
    null,
  );
  const [insuranceRut, setInsuranceRut] = usePersistState<string>(
    'insuranceRut',
    '',
  );

  useEffect(
    function clearLastSearch() {
      if (/(producto|buscar)/.exec(pathname)) return;
      setLastSearch('');
    },
    [pathname],
  );
  // Update the window with the userEmail when it changes:

  // Check if there is a token in a route other than login
  useEffect(() => {
    if (userToken && !pathname.includes('registro')) {
      setUserToken('');
    }
  }, [pathname, userToken, setUserToken]);

  useEffect(() => {
    if (user && firebaseUser) {
      clearTypeOfSingin();
      clearRegistryForm();
    }
  }, [clearTypeOfSingin, clearRegistryForm, firebaseUser, user]);

  useDebugStateChange('[MekiState] change userEmail', userEmail);

  const mekiState = useMemo<MekiState>(() => {
    return {
      registryForm,
      setRegistryFrom,
      uploadedPrescription,
      setUploadedPrescription,
      plannerPreference,
      setPlannerPreference,
      lastSearch,
      setLastSearch,
      userEmail,
      setUserEmail,
      userToken,
      setUserToken,
      newAccountEmail,
      setNewAccountEmail,
      typeOfSingin,
      setTypeOfSingin,
      referralCode,
      setReferralCode,
      insuranceId,
      setInsuranceId,
      insuranceRut,
      setInsuranceRut,
    };
  }, [
    registryForm,
    setRegistryFrom,
    uploadedPrescription,
    plannerPreference,
    lastSearch,
    userEmail,
    setUserEmail,
    userToken,
    setUserToken,
    newAccountEmail,
    typeOfSingin,
    setTypeOfSingin,
    referralCode,
    setReferralCode,
    insuranceId,
    setInsuranceId,
    insuranceRut,
    setInsuranceRut,
  ]);
  if ((ENV === 'development' || TEST_ENV) && typeof window !== 'undefined') {
    window.mekiState = mekiState;
  }

  useEffect(() => {
    const prevUser = userRef.current;
    userRef.current = user;
    if (prevUser && !user) {
      [
        clearRegistryForm,
        () => {
          setUploadedPrescription(null);
        },
        () => {
          setUserEmail('');
        },
        () => {
          setNewAccountEmail('');
        },
      ].forEach((clear) => clear());
    }
  }, [clearRegistryForm, user, setUserEmail]);
  return (
    <MekiStateContext.Provider value={mekiState}>
      {children}
    </MekiStateContext.Provider>
  );
}
