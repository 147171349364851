/*
 * File: currentUserInfo.tsx
 * Project: meki
 * File Created: Thursday, 7th January 2021 9:52:32 am
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Thursday, 28th December 2023 3:19:36 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { User } from '@interfaces';

export const currentUserInfo = (name = 'currentUserInfo') => gql`
  query ${name} {
    currentUserInfo {
      id
      uuid
      name
      lastNames
      email
      phone
      rut
      birthdate
      referedCode
      acceptedTerms
      hasPillBox
      credits
      referralUser {
        refererId
        userId
        referredUnderAmountCount
        referredOverAmountCount
        totalMekiCreditsEarned
        totalReferredCount
        userReferralCode
        firstOrderDate
        mekiCreditsAvailables
        firstOrderAmount
      }
      listReferredUsers {
        name
        lastNames
        firstOrderDate
      }
      blockedNotifications {
        news
        promotions
        reminders
      }
      hasUserInsurance
    }
  }
`;

export interface CurrentUserInfoResponse {
  currentUserInfo: User;
}
