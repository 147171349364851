/*
 * File: ModalNavigation.tsx
 * Project: meki
 * File Created: Monday, 10th May 2021 10:47:57 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Thursday, 25th January 2024 1:07:43 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import dynamic from 'next/dynamic';
import { Console, getRoute } from '@utils';
import { useRouter } from 'next/router';
import { useOpenModal } from '@hooks/useModalOpenState';
import { useSegment } from '@hooks/useSegment';
import { useEffect } from 'react';
import { useFacebokPixelEvent } from '@hooks/useFacebookPixel';

const ModalScreen = dynamic(
  () => {
    const promise = import('./ModalScreen').then((mod) => mod.ModalScreen);
    return promise;
  },
  { ssr: true, loading: () => null },
);
const ProductModal = dynamic(
  () => {
    const promise = import('@screens/product').then((mod) => mod.Product);
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const Contact = dynamic(
  () => {
    const promise = import('@screens/contact').then((mod) => mod.Contact);
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const Referral = dynamic(
  () => {
    const promise = import('@screens/referral').then((mod) => mod.Referral);
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const InscribeCardIndex = dynamic(
  () => {
    const promise = import('@screens/payment/inscribeCard').then(
      (mod) => mod.InscribeCardIndex,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const NewRegistryIndex = dynamic(
  () => {
    const promise = import('@screens/registry').then(
      (mod) => mod.NewRegistryIndex,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const RegistryAditionalInformation = dynamic(
  () => {
    const promise = import('@screens/registry/aditionalInformation').then(
      (mod) => mod.RegistryAditionalInformation,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const BiometricRegistry = dynamic(
  () => {
    const promise = import('@screens/registry/biometricRegistry').then(
      (mod) => mod.BiometricRegistry,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const AcountAlreadyExist = dynamic(
  () => {
    const promise = import('@screens/registry/acountAlreadyExist').then(
      (mod) => mod.AcountAlreadyExist,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const NewSignIn = dynamic(
  () => {
    const promise = import('@screens/signIn').then((mod) => mod.NewSignIn);
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const VerifyAuthCode = dynamic(
  () => {
    const promise = import('@screens/signIn/codeVerification').then(
      (mod) => mod.CodeVerificationSignIn,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const RegulatoryInfoGeneral = dynamic(
  () => {
    const promise = import('@screens/regulatory/general').then(
      (mod) => mod.GeneralScreen,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const RegulatoryInfoDecrees = dynamic(
  () => {
    const promise = import('@screens/regulatory/decrees').then(
      (mod) => mod.DecreesScreen,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const RegulatoryInfoShortage = dynamic(
  () => {
    const promise = import('@screens/regulatory/shortage').then(
      (mod) => mod.ShortageScreen,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const RegulatoryInfoToxicology = dynamic(
  () => {
    const promise = import('@screens/regulatory/toxicology').then(
      (mod) => mod.ToxicologyScreen,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const SuggestionEmail = dynamic(
  () => {
    const promise = import('@screens/suggestions').then(
      (mod) => mod.SuggestionEmail,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const SuggestionsList = dynamic(
  () => {
    const promise = import('@screens/suggestions/SuggestionsList').then(
      (mod) => mod.SuggestionsList,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const Devolutions = dynamic(
  () => {
    const promise = import('@screens/devolutions').then(
      (mod) => mod.Devolutions,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const Pharmaceutical = dynamic(
  () => {
    const promise = import('@screens/pharmaceutical').then(
      (mod) => mod.Pharmaceutical,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const AddressList = dynamic(
  () => {
    const promise = import('@screens/address/AddressList').then(
      (mod) => mod.AddressList,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const EditAddress = dynamic(
  () => {
    const promise = import('@screens/address/EditAddress').then(
      (mod) => mod.EditAddress,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const NewAddress = dynamic(
  () => {
    const promise = import('@screens/address/NewAddress').then(
      (mod) => mod.NewAddress,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const PersonalData = dynamic(
  () => {
    const promise = import('@screens/profile').then((mod) => mod.PersonalData);
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const PaymentMethodsList = dynamic(
  () => {
    const promise = import('@screens/payment/methods').then(
      (mod) => mod.PaymentMethodsList,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const OrdersIndex = dynamic(
  () => {
    const promise = import('@screens/orders').then((mod) => mod.OrdersIndex);
    return promise;
  },
  { ssr: false, loading: () => <ModalScreen /> },
);
const OrderInfo = dynamic(
  () => {
    const promise = import('@screens/orders/orderInfo').then(
      (mod) => mod.OrderInfo,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const OrderReceiptsScreen = dynamic(
  () => {
    const promise = import('@screens/orders/receipts').then(
      (mod) => mod.OrderReceipts,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const UploadPrescriptions = dynamic(
  () => {
    const promise = import('@screens/prescription').then(
      (mod) => mod.Prescriptions,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const MyPrescriptions = dynamic(
  () => {
    const promise = import('@screens/prescription/myPrescriptions').then(
      (mod) => mod.MyPrescriptions,
    );
    return promise;
  },
  { ssr: false, loading: () => <ModalScreen /> },
);

const CheckoutMain = dynamic(
  () => {
    const promise = import('@screens/checkout/main').then(
      (mod) => mod.CheckoutMain,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const CheckoutPaymentSuccess = dynamic(
  () => {
    const promise = import('@screens/checkout/paymentSuccess').then(
      (mod) => mod.CheckoutPaymentSuccess,
    );
    return promise;
  },
  { ssr: false, loading: () => <ModalScreen /> },
);

const CheckoutDelivery = dynamic(
  () => {
    const promise = import('@screens/checkout/delivery').then(
      (mod) => mod.CheckoutDelivery,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const CheckoutDetail = dynamic(
  () => {
    const promise = import('@screens/checkout/detail').then(
      (mod) => mod.CheckoutDetail,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const CheckoutPaymentMethod = dynamic(
  () => {
    const promise = import('@screens/checkout/paymentMethod').then(
      (mod) => mod.CheckoutPaymentMethodV2,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);
const CheckoutPaymentMethodLocal = dynamic(
  () => {
    const promise = import('@screens/checkout/paymentMethodLocal').then(
      (mod) => mod.CheckoutPaymentMethodLocal,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const CheckoutPaymentConfirm = dynamic(
  () => {
    const promise = import('@screens/checkout/paymentConfirm').then(
      (mod) => mod.PaymentConfirm,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const CheckoutPaymentinstallments = dynamic(
  () => {
    const promise = import('@screens/checkout/paymentInstallments').then(
      (mod) => mod.CheckoutPaymentInstallments,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const LastWhim = dynamic(
  () => {
    const promise = import('@screens/checkout/last-whim').then(
      (mod) => mod.LastWhim,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const DeliveryZones = dynamic(
  () => {
    const promise = import('@screens/delivery/deliveryZones').then(
      (mod) => mod.DeliveryZones,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const SettingsScreen = dynamic(
  () => {
    const promise = import('@screens/settings').then((mod) => mod.Settings);
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const InsuranceInformation = dynamic(
  () => {
    const promise = import('@screens/insurance/information').then(
      (mod) => mod.InsuranceInformation,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const InsuranceYourData = dynamic(
  () => {
    const promise = import('@screens/insurance/your-data').then(
      (mod) => mod.InsuranceYourData,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const InsuranceIntroduction = dynamic(
  () => {
    const promise = import('@screens/insurance/introduction').then(
      (mod) => mod.InsuranceIntroduction,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

const MyInsurances = dynamic(
  () => {
    const promise = import('@screens/insurance/my-insurances').then(
      (mod) => mod.MyInsurances,
    );
    return promise;
  },
  { ssr: true, loading: () => <ModalScreen /> },
);

interface ModalNavigationProps {
  [key: string]: unknown;
}
export function ModalNavigation(props: ModalNavigationProps) {
  const router = useRouter();
  const { track } = useSegment();
  const openModal = useOpenModal();
  const { trackFBEvent } = useFacebokPixelEvent();
  Console.log({
    msg: '[ModalNavigation] render',
    query: router.query,
    route: router.route,
    queryPathname: router.query.pathname,
    pathname: router.pathname,
  });
  const pathname = router.query.pathname ?? router.pathname;
  useEffect(
    function trackEventsWithPathChange() {
      if (pathname === '/registro') void track('account creation start');
      if (pathname.includes('direcciones/agregar')) {
        void track('add address start');
      }
      if (pathname.includes('registro/direccion')) {
        void track('add address start');
      }
      if (!pathname.includes('/producto/') && !pathname.includes('/buscar')) {
        trackFBEvent('track', 'ViewContent', {});
      }
    },
    [track, pathname, trackFBEvent],
  );
  useEffect(() => {
    if (
      ['/', '/inicio', '/buscar'].includes(pathname as string) ||
      pathname.includes('/categoria')
    )
      return openModal.closeModal();
    if (!pathname) return openModal.closeModal();
    openModal.openModal();
  }, [openModal, pathname]);

  if (['/', '/inicio'].includes(pathname as string)) return null;
  if (!pathname) return null;
  if (pathname === '/ingresar')
    return (
      <ModalScreen>
        <NewSignIn />
      </ModalScreen>
    );
  if (pathname === '/ingresar/verificar-codigo')
    return (
      <ModalScreen>
        <VerifyAuthCode />
      </ModalScreen>
    );
  if (pathname === '/registro')
    return (
      <ModalScreen>
        <NewRegistryIndex />
      </ModalScreen>
    );
  if (pathname === '/registro/cuenta-ya-existe')
    return (
      <ModalScreen>
        <AcountAlreadyExist />
      </ModalScreen>
    );
  if (pathname === '/registro/informacion-adicional')
    return (
      <ModalScreen>
        <RegistryAditionalInformation />
      </ModalScreen>
    );

  if (pathname === '/registro/biometrico')
    return (
      <ModalScreen>
        <BiometricRegistry />
      </ModalScreen>
    );
  if (pathname === '/boletas/[orderUuid]')
    return (
      <ModalScreen>
        <OrderReceiptsScreen />
      </ModalScreen>
    );
  if (pathname === '/checkout/direcciones/agregar')
    return (
      <ModalScreen>
        <NewAddress
          backPath={getRoute('checkoutDelivery')}
          assignOrder
          afterSubmitRoute={getRoute('checkoutDelivery')}
        />
      </ModalScreen>
    );
  if (pathname === '/resumen/metodos-pago')
    return (
      <ModalScreen>
        <PaymentMethodsList
          forwardPath={getRoute('checkout', { ready: 'true' })}
          addPath={getRoute('checkoutAddPaymentMethods')}
          selectable
        />
      </ModalScreen>
    );
  if (pathname === '/resumen/metodos-pago/agregar')
    return (
      <ModalScreen>
        <InscribeCardIndex
          backPath={getRoute('checkoutPaymentMethods')}
          forwardPath={getRoute('checkout', { ready: 'true' })}
        />
      </ModalScreen>
    );
  if (pathname === '/informacion-reglamentaria/decretos')
    return (
      <ModalScreen>
        <RegulatoryInfoDecrees />
      </ModalScreen>
    );
  if (pathname === '/informacion-reglamentaria/general')
    return (
      <ModalScreen>
        <RegulatoryInfoGeneral />
      </ModalScreen>
    );
  if (pathname === '/informacion-reglamentaria/desabastecimiento')
    return (
      <ModalScreen>
        <RegulatoryInfoShortage />
      </ModalScreen>
    );
  if (pathname === '/informacion-reglamentaria/toxicologia')
    return (
      <ModalScreen>
        <RegulatoryInfoToxicology />
      </ModalScreen>
    );
  if (pathname === '/contacto')
    return (
      <ModalScreen>
        <Contact />
      </ModalScreen>
    );
  if (pathname === '/referidos')
    return (
      <ModalScreen>
        <Referral />
      </ModalScreen>
    );
  if (pathname === '/sugerencias-reclamos')
    return (
      <ModalScreen>
        <SuggestionEmail />
      </ModalScreen>
    );
  if (pathname === '/sugerencias-reclamos/todos')
    return (
      <ModalScreen>
        <SuggestionsList />
      </ModalScreen>
    );
  if (pathname === '/devoluciones')
    return (
      <ModalScreen>
        <Devolutions />
      </ModalScreen>
    );
  if (pathname === '/quimico-farmaceutico')
    return (
      <ModalScreen>
        <Pharmaceutical />
      </ModalScreen>
    );
  if (pathname === '/producto/[prodSlug]')
    return (
      <ModalScreen>
        <ProductModal {...props} />
      </ModalScreen>
    );
  if (pathname === '/cuenta/perfil')
    return (
      <ModalScreen>
        <PersonalData />
      </ModalScreen>
    );
  if (pathname === '/cuenta/direcciones')
    return (
      <ModalScreen>
        <AddressList
          addPath={getRoute('accountAddAddresses')}
          backPath={getRoute('home')}
          editPath={(addressUuid) => {
            return getRoute('accountEditAddresses', { addressUuid });
          }}
        />
      </ModalScreen>
    );
  if (pathname === '/cuenta/direcciones/[addressUuid]/editar')
    return (
      <ModalScreen>
        <EditAddress
          afterSubmitPath={getRoute('accountAddresses')}
          submitText="GUARDAR"
        />
      </ModalScreen>
    );
  if (pathname === '/cuenta/direcciones/agregar')
    return (
      <ModalScreen>
        <NewAddress
          backPath={getRoute('accountAddresses')}
          afterSubmitRoute={getRoute('accountAddresses')}
        />
      </ModalScreen>
    );
  if (pathname === '/checkout/metodos-pago/agregar')
    return (
      <ModalScreen>
        <InscribeCardIndex
          backPath={getRoute('checkoutSummary')}
          forwardPath={getRoute('checkoutSummary')}
        />
      </ModalScreen>
    );
  if (pathname === '/cuenta/metodos-pago')
    return (
      <ModalScreen>
        <PaymentMethodsList
          addPath={getRoute('accountAddPaymentMethods')}
          forwardPath={getRoute('home')}
        />
      </ModalScreen>
    );
  if (pathname === '/cuenta/metodos-pago/agregar')
    return (
      <ModalScreen>
        <InscribeCardIndex
          backPath={getRoute('accountPaymentMethods')}
          forwardPath={getRoute('accountPaymentMethods')}
        />
      </ModalScreen>
    );
  if (pathname === '/pedidos')
    return (
      <ModalScreen>
        <OrdersIndex />
      </ModalScreen>
    );
  if (pathname === '/pedidos/[orderUuid]')
    return (
      <ModalScreen>
        <OrderInfo />
      </ModalScreen>
    );
  if (pathname === '/recetas')
    return (
      <ModalScreen>
        <MyPrescriptions />
      </ModalScreen>
    );
  if (pathname === '/checkout/recetas')
    return (
      <ModalScreen>
        <UploadPrescriptions redirectOnNonePending />
      </ModalScreen>
    );
  if (pathname === '/seguros/recetas')
    return (
      <ModalScreen>
        <UploadPrescriptions fromInsurance />
      </ModalScreen>
    );

  if (pathname === '/checkout')
    return (
      <ModalScreen>
        <CheckoutMain />
      </ModalScreen>
    );

  if (pathname === '/checkout/pago-exitoso/[orderUuid]')
    return (
      <ModalScreen>
        <CheckoutPaymentSuccess />
      </ModalScreen>
    );

  if (pathname === '/checkout/entrega')
    return (
      <ModalScreen>
        <CheckoutDelivery />
      </ModalScreen>
    );

  if (pathname === '/checkout/detalle')
    return (
      <ModalScreen>
        <CheckoutDetail />
      </ModalScreen>
    );

  if (pathname === '/checkout/metodo-pago')
    return (
      <ModalScreen>
        <CheckoutPaymentMethod />
      </ModalScreen>
    );
  if (pathname === '/checkout/metodo-pago-local')
    return (
      <ModalScreen>
        <CheckoutPaymentMethodLocal />
      </ModalScreen>
    );
  if (pathname === '/checkout/confirmar-pago')
    return (
      <ModalScreen>
        <CheckoutPaymentConfirm />
      </ModalScreen>
    );
  if (pathname === '/checkout/cuotas')
    return (
      <ModalScreen>
        <CheckoutPaymentinstallments />
      </ModalScreen>
    );
  if (pathname === '/zonas-de-despacho')
    return (
      <ModalScreen>
        <DeliveryZones />
      </ModalScreen>
    );

  if (pathname === '/checkout/ultimo-antojo')
    return (
      <ModalScreen>
        <LastWhim />
      </ModalScreen>
    );
  if (pathname === '/configuracion')
    return (
      <ModalScreen>
        <SettingsScreen />
      </ModalScreen>
    );
  if (pathname === '/seguros/informacion')
    return (
      <ModalScreen>
        <InsuranceInformation />
      </ModalScreen>
    );
  if (pathname === '/seguros/tus-datos')
    return (
      <ModalScreen>
        <InsuranceYourData />
      </ModalScreen>
    );
  if (pathname === '/seguros/introduccion')
    return (
      <ModalScreen>
        <InsuranceIntroduction />
      </ModalScreen>
    );
  if (pathname === '/seguros/mis-seguros')
    return (
      <ModalScreen>
        <MyInsurances />
      </ModalScreen>
    );

  return null;
}
