/*
 * File: searchBackdrop.tsx
 * Project: meki
 * File Created: Tuesday, 18th May 2021 10:07:59 am
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Friday, 2nd February 2024 10:19:26 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React from 'react';
import { styled } from '@mui/system';
import { Palette } from '@mui/material';

interface BackdropProps {
  toggleBackdrop: boolean;
  handleToggleClick: (tag?: unknown) => void;
  trasparent?: boolean;
}
interface BackdropDivProps {
  trasparent?: boolean;
}
const BackdropDiv = styled('div', {
  shouldForwardProp: (prop) => !['trasparent'].includes(prop as string),
})<BackdropDivProps>(({ theme, trasparent }) => ({
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100vh',
  position: 'fixed',
  background: trasparent ? '' : ' rgba(0,0,0,0.3)',
  zIndex: 995,
  top: 0,
  right: 0,
  left: 0,
  color: (theme.palette as Palette).primary.dark,
}));
export function SearchBackdrop({
  toggleBackdrop,
  handleToggleClick,
  trasparent,
}: BackdropProps) {
  // Hooks init:
  if (!toggleBackdrop) return null;
  return <BackdropDiv onClick={handleToggleClick} trasparent={trasparent} />;
}
