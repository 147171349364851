/*
 * File: useFocusSearchBox.tsx
 * Project: meki
 * File Created: Friday, 5th August 2022 1:49:14 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Wednesday, 24th August 2022 1:45:42 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, { useContext, useMemo, useState } from 'react';

const FocusSearchBoxContext = React.createContext({
  isFocus: false,
  onfocus: () => {},
  onblur: () => {},
});

export const useFocusSearchBox = () => {
  const value = useContext(FocusSearchBoxContext);
  return value;
};

interface FocusSearchBoxProviderProps {
  children: React.ReactNode;
}

export const FocusSearchBoxProvider = ({
  children,
}: FocusSearchBoxProviderProps) => {
  const [focus, setFocus] = useState(false);
  const value = useMemo(() => {
    return {
      isFocus: focus,
      onfocus: () => setFocus(true),
      onblur: () => setFocus(false),
    };
  }, [focus]);
  return (
    <FocusSearchBoxContext.Provider value={value}>
      {children}
    </FocusSearchBoxContext.Provider>
  );
};
