/*
 * File: useSessionId.ts
 * Project: meki
 * File Created: Wednesday, 10th February 2021 5:09:01 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Wednesday, 10th February 2021 5:11:14 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';

declare global {
  interface Window {
    sessionId: string;
  }
}

export function useSessionId() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.sessionId = uuid();
    }
  }, []);
}
