import { DivRoot } from '@screens/cart/styles/index.styles';
import { styled } from '@mui/system';
import { Dialog } from '@mui/material';

import {
  IconButton,
  Palette,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from '@mui/material';

interface MenuIconButtonProps {
  menuIconColorChange: boolean;
}

const MenuIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'menuIconColorChange',
})<MenuIconButtonProps>(({ theme, menuIconColorChange }) => ({
  color: menuIconColorChange ? (theme.palette as Palette).text.primary : '',
}));

interface MenuButtonListItemProps {
  openItem?: boolean;
  name?: string;
  lastItem?: boolean;
  firstItem?: boolean;
}

interface ArrowIconProps {
  rotate?: 'right' | 'left';
}

interface MenuButtonListSubItemProps {
  lastItem?: boolean;
}

const DrawerMenuSwipeableDrawer = styled(SwipeableDrawer)({
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: 340,
    '@media (min-width: 600px)': {
      width: 340,
    },
  },
});

const DrawerMenuAppBar = styled('div')({
  position: 'sticky',
  top: 0,
  bottom: 'auto',
  height: '100%',
  overflow: 'scroll',
  backgroundColor: '#F5F5F5',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
});

const DrawerMenuToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
  minHeight: 64,
  width: '100%',
  borderBottom: '3px solid #E0E0E0',
  boxShadow: '1px 4px 15px rgba(0,0,0,0.1)',
  backgroundColor: '#FFFFFF',
  '& .MuiToolbar-gutters': {
    paddingRight: 20,
  },
});

const ClickableMekiDiv = styled('div')({
  cursor: 'pointer',
});

const CloseButton = styled(IconButton)({
  color: '#414046',
});

const MenuListItemButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'rotate',
})<ArrowIconProps>(({ rotate }) => ({
  color: '#414046',
  marginLeft: 'auto',
  transform:
    rotate == 'left'
      ? 'rotate(-90deg)'
      : rotate == 'right'
      ? 'rotate(90deg)'
      : '',
}));

const TitleTypography = styled(Typography)({
  padding: '12px',
  fontFamily: 'Poppins',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '36px',
  letterSpacing: '0.15px',
  textAlign: 'left',
  color: '#414046',
});

const SubTitleTypography = styled(Typography)({
  padding: '5px 12px 17px 12px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '27px',
  letterSpacing: '0.15px',
  textAlign: 'left',
  color: '#414046',
});

const DescriptionTypography = styled(Typography)({
  marginBottom: '8px',
  paddingLeft: '12px',
  fontFamily: 'Poppins',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  textAlign: 'left',
  color: '#414046',
});

const CreditContainer = styled(DivRoot)({
  display: 'flex',
  backgroundColor: '#E6F3FA',
  margin: '10px',
  height: 'auto',
  padding: '5px 10px',
});

const CreditsTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '15.6px',
  letterSpacing: '0.15px',
  textAlign: 'left',
  color: '#414046',
  marginLeft: '10px',
});

const CreditsIconContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const HomeIconContainer = styled('div')({
  marginTop: '7px',
  marginRight: '8px',
  marginLeft: '5px',
});

const ListItemTypography = styled(Typography)({
  // This Typography is for List Items in the Menu
  // that include a left icon.
  fontFamily: 'Poppins',
  paddingTop: '8px',
  fontWeight: 500,
  fontsize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.16px',
  color: '#414046',
});

const ListSubItemTypography = styled(Typography)({
  // This Typography is for List Items in the Menu
  // with NO left icon
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontsize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.16px',
  color: '#414046',
  marginLeft: '10px',
  '&:hover': {
    fontWeight: 500,
  },
  paddingLeft: '21px',
});

const MenuSubContainer = styled(DivRoot)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 'auto',
  backgroundColor: '#FFFFFF',
  border: '1px solid #EAEAEA',
  borderRadius: '6px',
  margin: '12px',
});

const LoginButton = styled('button')({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.16px',
  color: '#0A7ABE',
  textTransform: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  padding: '10px',
  margin: '0px',
  justifyContent: 'center',
  alignItems: 'center',
});

const LogoutButton = styled('button')({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.16px',
  color: '#0A7ABE',
  textTransform: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  padding: '10px',
  margin: '0px',
  justifyContent: 'center',
  alignItems: 'center',
});

const DrawerMenuDivider = styled('hr')({
  class: 'solid',
  width: '99.9%',
  border: '0.5px solid #EAEAEA',
  margin: '0px',
});

const MenuButtonListItem = styled('button', {
  shouldForwardProp: (prop) =>
    prop !== 'openItem' && prop !== 'name' && prop !== 'lastItem',
})<MenuButtonListItemProps>(({ openItem, name, lastItem }) => ({
  display: 'flex',
  border: 'solid 0px #EAEAEA',
  borderWidth: lastItem ? '0px 0px 1px 0px' : '0px 0px 0px 0px',
  width: '100%',
  textAlign: 'left',
  flex: '1',
  borderRadius: '6px',
  borderEndStartRadius:
    (name == 'Nutrición y vitaminas' || name == 'Información') && !openItem
      ? '6px'
      : '0px',
  borderEndEndRadius:
    (name == 'Nutrición y vitaminas' || name == 'Información') && !openItem
      ? '6px'
      : '0px',
  backgroundColor: openItem ? '#EFF7FF !important' : 'white',
  cursor: 'pointer !important',
  padding: '6px 0px 6px 17px',
}));

const MenuButtonListSubItem = styled('button', {
  shouldForwardProp: (prop) => prop !== 'lastItem',
})<MenuButtonListSubItemProps>(({ lastItem }) => ({
  display: 'flex',
  width: '100%',
  textAlign: 'left',
  flex: '1',
  padding: '12px 10px',
  '&:hover p': {
    fontWeight: 500,
  },
  '&:focus p': {
    fontWeight: 500,
  },
  cursor: 'pointer',
  border: 'solid 0px #EAEAEA',
  borderRadius: lastItem ? '0px 0px 0px 0px' : '0px 0px 6px 6px',
  borderWidth: lastItem ? '0px 0px 1px 0px' : '0px 0px 0px 0px',
  backgroundColor: 'white',
}));

const ToHomeButton = styled('button')({
  display: 'flex',
  border: 'solid 0px #EAEAEA',
  borderRadius: '6px',
  width: '100%',
  textAlign: 'left',
  flex: '1',
  padding: '6px',
  backgroundColor: 'white',
  cursor: 'pointer',
});

// MenuButtonListItemWithAvatar has less padding to make up for the
// icon's empty left side
const MenuButtonListItemWithAvatar = styled('button', {
  shouldForwardProp: (prop) =>
    prop !== 'openItem' && prop !== 'lastItem' && prop !== 'firstItem',
})<MenuButtonListItemProps>(({ openItem, lastItem, firstItem }) => ({
  display: 'flex',
  border: 'solid 0px #EAEAEA',
  borderRadius:
    !firstItem && !lastItem
      ? '0px'
      : firstItem
      ? '6px 6px 0px 0px'
      : lastItem && !openItem
      ? '0px 0px 6px 6px'
      : '0px',
  width: '100%',
  textAlign: 'left',
  flex: '1',
  padding: '6px',
  backgroundColor: openItem ? '#EFF7FF !important' : 'white',
  cursor: 'pointer',
}));

const MenuList = styled('ul')({
  padding: '0px',
  height: 'auto',
  backgroundColor: '#FFFFFF',
  border: '1px solid #EAEAEA',
  borderRadius: '6px',
  margin: '0px 12px 12px 12px',
});

const DialogContainer = styled('div')({
  '-webkit-transform': 'translate3d(0,0,0)',
  zIndex: 999,
});

const CloseSesionDialog = styled(Dialog)({
  position: 'fixed',
  top: '0%',
  left: '0%',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiDialog-paper': {
    borderRadius: '10px',
    gap: '0px',
  },
  '& .MuiDialogContent-root': {
    justifyContent: 'center',
  },
  '& .MuiDialogActions-root': {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
});

const CloseSessionTextDialogTitle = styled('div')({
  color: '#414046',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '0.15px',
  textAlign: 'center',
  padding: '10px 15px 10px 15px',
});

const CancelModalButton = styled('button')({
  fontFamily: 'Poppins',
  backgroundColor: ' #FFFFFF',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#0277BD',
  border: 'none',
  height: 'auto',
  width: '200px',
  margin: '5px',
});

const CloseSessionButton = styled('button')({
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 400,
  margin: '5px',
  backgroundColor: '#0A7ABE',
  color: 'white',
  height: '50px',
  border: 'none',
  borderRadius: '100px',
  width: '200px',
  padding: '9px,45px,9px,45px',
  gap: '8px',
  cursor: 'pointer',
});

interface ButtonNameProps {
  barChange: boolean;
}

const ButtonName = styled('button', {
  shouldForwardProp: (prop) => prop !== 'barChange',
})<ButtonNameProps>(({ theme, barChange }) => ({
  height: 'fit-content',
  fontSize: '16px',
  fontWeight: 'normal',
  fontFamily: 'Poppins',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '0.15px',
  '@media (max-width: 767px)': {
    fontSize: 12,
  },
  border: 'none',
  backgroundColor: 'transparent',
  color: barChange
    ? (theme.palette as Palette).text.primary
    : (theme.palette as Palette).common.white,
}));

const LinkRedirect = styled('a')({
  textDecoration: 'none',
});

export {
  ButtonName,
  CancelModalButton,
  CloseSesionDialog,
  CloseSessionTextDialogTitle,
  CloseSessionButton,
  CreditContainer,
  ClickableMekiDiv,
  CloseButton,
  CreditsTypography,
  CreditsIconContainer,
  DescriptionTypography,
  DrawerMenuAppBar,
  DrawerMenuDivider,
  DrawerMenuSwipeableDrawer,
  DrawerMenuToolbar,
  HomeIconContainer,
  LoginButton,
  LogoutButton,
  ListItemTypography,
  ListSubItemTypography,
  MenuButtonListItem,
  MenuButtonListItemWithAvatar,
  MenuButtonListSubItem,
  MenuIconButton,
  MenuList,
  MenuListItemButton,
  MenuSubContainer,
  SubTitleTypography,
  TitleTypography,
  DialogContainer,
  ToHomeButton,
  LinkRedirect,
};
