/*
 * File: homeNavBa
 * Project: meki
 * File Created: Thursday, 28th July 2022 4:01:26 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Tuesday, 24th January 2023 5:40:39 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { styled } from '@mui/system';
import { AppBar, Toolbar, Button, IconButton, Palette } from '@mui/material';
interface PositionDivProps {
  desktop: boolean;
}
const RootDiv = styled('div')({
  flexGrow: 1,
  '& .MuiButton-root': {
    textTransform: 'None',
  },
});
const HeaderAppBar = styled(AppBar)((props) => ({
  top: 0,
  bottom: 'auto',
  height: 64,
  background: '#fff',
  backdropFilter: 'blur(5px)',
  boxShadow: props.elevation ? '0px 1px 10px rgb(0 0 0 / 12%)' : 'none',
  '@media (min-width: 800px)': {
    height: 'inherit',
  },
  '@media (max-width: 900px)': {
    position: 'fixed',
  },
}));
const HeaderToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
  minHeight: 64,
  backgroundColor: 'transparent',
  paddingRight: 0,
  paddingLeft: '12px',
});
const PositionDivLeft = styled('div', {
  shouldForwardProp: (prop) => prop !== 'desktop',
})<PositionDivProps>(({ desktop }) => ({
  flex: 0,
  position: desktop ? 'absolute' : 'unset',
  left: desktop ? 20 : 0,
  zIndex: desktop ? 1 : 0,
}));
const PositionDivRight = styled('div', {
  shouldForwardProp: (prop) => prop !== 'desktop',
})<PositionDivProps>(({ desktop }) => ({
  flex: 0,
  position: desktop ? 'absolute' : 'unset',
  right: desktop ? 12 : 0,
  zIndex: desktop ? 1 : 0,
}));
const HeaderLeftDiv = styled('div')({
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
});
const BackIconButton = styled(IconButton)(({ theme }) => ({
  color: (theme.palette as Palette).text.primary,
}));
const HeaderRightComponent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
const LoginButton = styled(Button)(({ theme }) => ({
  color: (theme.palette as Palette).primary.main,
  borderColor: (theme.palette as Palette).common.white,
  height: 19,
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '1px',
  maxWidth: 180,
  textDecoration: 'underline',
  '@media (max-width: 767px)': {
    fontSize: 12,
  },
}));
const LoginNameDiv = styled(Button)(({ theme }) => ({
  height: '19px',
  fontSize: '16px',
  marginRight: 15,
  fontWeight: 'normal',
  width: 'max-content',
  color: (theme.palette as Palette).text.primary,
  fontFamily: 'Poppins',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '0.15px',
  '@media (max-width: 767px)': {
    fontSize: 12,
  },
}));
const MekiLogDiv = styled('div')({
  cursor: 'pointer',
  marginLeft: 9,
  display: 'flex',
});

export {
  RootDiv,
  HeaderAppBar,
  HeaderToolbar,
  HeaderLeftDiv,
  PositionDivLeft,
  PositionDivRight,
  BackIconButton,
  HeaderRightComponent,
  LoginButton,
  LoginNameDiv,
  MekiLogDiv,
};
