/*
 * File: productBase.ts
 * Project: meki
 * File Created: Monday, 28th September 2020 3:00:03 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Wednesday, 15th December 2021 5:25:07 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2020 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';

export const ProductBaseFragment = gql`
  fragment ProductBase on Product {
    id
    uuid
    slug
    name
    slug
    imagesUrl
    description
    unit
    format
    price
    concentration
    laboratory
    activePrinciple
    prescriptionType
    isBioequivalent
    isMedicine
    quantity
    containerQuantity
    container
  }
`;
