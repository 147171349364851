/*
 * File: routes.ts
 * Project: meki
 * File Created: Monday, 12th April 2021 10:37:53 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----1012
 * Last Modified: Thursday, 28th December 2023 11:32:36 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { LinkProps } from 'next/link';
import { UrlObject } from 'url';
import { removeUndefineds } from './collection';
export class Url implements UrlObject {
  pathname: string;
  query: Record<string, string>;
  constructor(url: UrlObject) {
    this.pathname = url.pathname;
    this.query = url.query as Record<string, string>;
  }
  /**
   * Return the pathname formated, including replacing query into pathname when is needed
   */
  toString(): string {
    const { pathname, query } = Object.entries(this.query ?? {}).reduce(
      (all, [key, value]) => {
        if (all.pathname.includes(`[${key}]`)) {
          return {
            ...all,
            pathname: all.pathname.replace(`[${key}]`, value),
          };
        }
        return {
          ...all,
          query: {
            ...all.query,
            [key]: value,
          },
        };
      },
      { pathname: this.pathname, query: {} },
    );
    // Format the string and remove the "?" if no search params provided
    return `${pathname}?${new URLSearchParams(query).toString()}`.replace(
      /\?$/,
      '',
    );
  }
  toModalPush(
    pathname = '/',
    query: Record<string, string> = {},
  ): [Url, string, { scroll: boolean }] {
    return [
      new Url({
        pathname,
        query: { ...query, ...this.query, pathname: this.pathname },
      }),
      this.toString(),
      { scroll: false },
    ];
  }
  toModalLink(pathname = '/', query: Record<string, string> = {}): LinkProps {
    const [href, as, { scroll }] = this.toModalPush(pathname, query);
    return {
      href,
      as,
      scroll,
    };
  }
  toLink(): LinkProps {
    return {
      href: this.toString(),
    };
  }
}
const requiredQuery = (value: string) => {
  if (!value) throw new Error('missing query');
  return value;
};
const baseGenerator =
  ({ pathname }: { pathname: string }) =>
  (query: Record<string, string>) =>
    new Url({ pathname, query });
export const ROUTE_MAP = {
  home: baseGenerator({ pathname: '/' }),
  homeLoggedIn: baseGenerator({ pathname: '/inicio' }),
  cart: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/',
      query: { ...query, cart: 'open' },
    }),
  orderReceipts: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/boletas/[orderUuid]',
      query: { ...query, orderUuid: requiredQuery(query.orderUuid) },
    }),
  search: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/buscar',
      query,
    }),
  product: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/producto/[prodSlug]',
      query: { ...query, prodSlug: requiredQuery(query.prodSlug) },
    }),
  signin: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/ingresar',
      query: removeUndefineds({
        ...query,
        redirect: query.redirect,
      }),
    }),
  verifyCode: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/ingresar/verificar-codigo',
      query: removeUndefineds({
        ...query,
        redirect: query.redirect,
        biometricFailed: query.biometricFailed,
      }),
    }),
  register: baseGenerator({ pathname: '/registro' }),
  registerAditionalInfo: baseGenerator({
    pathname: '/registro/informacion-adicional',
  }),
  registerBiometric: baseGenerator({ pathname: '/registro/biometrico' }),
  userAlreadyExist: baseGenerator({ pathname: '/registro/cuenta-ya-existe' }),
  registerAddress: baseGenerator({ pathname: '/registro/direccion' }),
  checkout: baseGenerator({ pathname: '/checkout' }),
  checkoutDelivery: baseGenerator({ pathname: '/checkout/entrega' }),
  checkoutDetail: baseGenerator({ pathname: '/checkout/detalle' }),
  checkoutSummary: baseGenerator({ pathname: '/checkout/resumen' }),
  checkoutPaymentSuccess: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/checkout/pago-exitoso/[orderUuid]',
      query: {
        ...query,
        orderUuid: requiredQuery(query.orderUuid),
      },
    }),
  checkoutPaymentMethod: baseGenerator({ pathname: '/checkout/metodo-pago' }),
  checkoutPaymentMethodLocal: baseGenerator({
    pathname: '/checkout/metodo-pago-local',
  }),
  checkoutPaymentConfirm: baseGenerator({
    pathname: '/checkout/confirmar-pago',
  }),
  checkoutAddAddresses: () =>
    new Url({ pathname: '/checkout/direcciones/agregar' }),
  checkoutEditAddresses: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/resumen/direcciones/[addressUuid]/editar',
      query: { ...query, addressUuid: requiredQuery(query.addressUuid) },
    }),
  checkoutPaymentMethods: baseGenerator({ pathname: '/checkout/metodos-pago' }),
  checkoutAddPaymentMethods: baseGenerator({
    pathname: '/checkout/metodos-pago/agregar',
  }),
  checkoutPrescriptions: baseGenerator({ pathname: '/checkout/recetas' }),
  orders: baseGenerator({ pathname: '/pedidos' }),
  order: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/pedidos/[orderUuid]',
      query: { ...query, orderUuid: requiredQuery(query.orderUuid) },
    }),
  accountAddresses: baseGenerator({ pathname: '/cuenta/direcciones' }),
  accountAddAddresses: () =>
    new Url({ pathname: '/cuenta/direcciones/agregar' }),
  accountEditAddresses: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/cuenta/direcciones/[addressUuid]/editar',
      query: { ...query, addressUuid: requiredQuery(query.addressUuid) },
    }),
  accountPaymentMethods: baseGenerator({ pathname: '/cuenta/metodos-pago' }),
  accountAddPaymentMethods: baseGenerator({
    pathname: '/cuenta/metodos-pago/agregar',
  }),
  accountProfile: baseGenerator({ pathname: '/cuenta/perfil' }),
  recoverPasswordRequest: (query: Record<string, string>) =>
    new Url({ pathname: '/restablecer-contrasena', query }),
  recoverPasswordCreate: (query: Record<string, string>) =>
    new Url({ pathname: '/restablecer-contrasena/crear', query }),
  category: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/categoria/[categorySlug]/[page]',
      query: {
        ...query,
        categorySlug: requiredQuery(query.categorySlug),
        page: Number.isInteger(+query.categorySlug)
          ? Math.max(+query.categorySlug)
          : 1,
        sortBy: query.sortBy ?? 'mostSelling',
      },
    }),
  subCategories: (query: Record<string, string> = {}) =>
    new Url({
      pathname: '/categoria/[categorySlug]/sub-categorias',
      query: {
        ...query,
        categorySlug: requiredQuery(query.categorySlug),
      },
    }),
  subCategory: (query: Record<string, string> = {}) =>
    new Url({
      pathname:
        '/categoria/[categorySlug]/sub-categoria/[subCategorySlug]/[page]',
      query: {
        ...query,
        categorySlug: requiredQuery(query.categorySlug),
        subCategorySlug: requiredQuery(query.subCategorySlug),
        page: Number.isInteger(+query.categorySlug)
          ? Math.max(+query.categorySlug)
          : 1,
        sortBy: query.sortBy ?? 'mostSelling',
      },
    }),
  cartProductsAvailability: baseGenerator({
    pathname: '/carro/disponibilidad',
  }),
  contact: baseGenerator({ pathname: '/contacto' }),
  referral: baseGenerator({ pathname: '/referidos' }),
  settings: baseGenerator({ pathname: '/configuracion' }),
  regulatoryInfoToxicology: baseGenerator({
    pathname: '/informacion-reglamentaria/toxicologia',
  }),
  regulatoryInfoDecrees: baseGenerator({
    pathname: '/informacion-reglamentaria/decretos',
  }),
  regulatoryInfoShortage: baseGenerator({
    pathname: '/informacion-reglamentaria/desabastecimiento',
  }),
  regulatoryInfoGeneral: baseGenerator({
    pathname: '/informacion-reglamentaria/general',
  }),
  suggestions: baseGenerator({ pathname: '/sugerencias-reclamos' }),
  devolutions: baseGenerator({ pathname: '/devoluciones' }),
  pharmaceutical: baseGenerator({ pathname: '/quimico-farmaceutico' }),
  allSuggestions: baseGenerator({ pathname: '/sugerencias-reclamos/todos' }),
  prescriptionsUploadCartFromInsurance: baseGenerator({
    pathname: '/seguros/recetas',
  }),
  prescriptionsUpload: baseGenerator({ pathname: '/recetas' }),
  mekiLocationGoogleMap: baseGenerator({ pathname: '/ubicacion/meki' }),
  deliveryZones: baseGenerator({ pathname: '/zonas-de-despacho' }),
  purchases: baseGenerator({ pathname: '/mis-compras' }),
  lastWhim: baseGenerator({ pathname: '/checkout/ultimo-antojo' }),
  pillbox: baseGenerator({ pathname: '/pastillero' }),
  prescriptions: baseGenerator({ pathname: '/recetas' }),
  insuranceInfo: baseGenerator({
    pathname: '/seguros/informacion',
  }),
  insuranceNewData: baseGenerator({
    pathname: '/seguros/tus-datos',
  }),
  insuranceIntroduction: baseGenerator({
    pathname: '/seguros/introduccion',
  }),
  checkoutInstallments: baseGenerator({
    pathname: '/checkout/cuotas',
  }),
  myInsurances: baseGenerator({
    pathname: '/seguros/mis-seguros',
  }),
};
export function getRoute(
  route: keyof typeof ROUTE_MAP,
  query: Record<string, string> = {},
): Url {
  if (!ROUTE_MAP[route]) throw new Error('missing path');
  return ROUTE_MAP[route](query);
}

export type RouteKey = keyof typeof ROUTE_MAP;
