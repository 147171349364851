/*
 * File: useWindowSize.ts
 * Project: meki
 * File Created: Thursday, 10th December 2020 5:15:48 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Thursday, 12th August 2021 1:22:51 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2020 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useState, useEffect } from 'react';

interface WindowSize {
  width?: number | undefined;
  height?: number | undefined;
}

export function useWindowSize(): WindowSize {
  // useWindowSize hook from https://usehooks.com/useWindowSize/
  // Initialize state with undefined width/height so server and client renders match
  const [, setForceRerender] = useState<number>(0);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setForceRerender((n) => n + 1);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  if (typeof window === 'undefined')
    return { width: undefined, height: undefined };
  return { width: window.innerWidth, height: window.innerHeight };
}
