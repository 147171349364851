/*
 * File: sentry.ts
 * Project: meki
 * File Created: Saturday, 19th November 2022 1:24:46 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Wednesday, 11th January 2023 9:50:16 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { CaptureContext } from '@sentry/types';
import { BrowserOptions } from '@sentry/browser';

interface SentryInterface {
  configureScope: (...params: unknown[]) => unknown;
  setUser: (...params: unknown[]) => unknown;
  init: (param: BrowserOptions) => unknown;
  captureException: (exception: any, captureContext?: CaptureContext) => string;
}

export const getSentry = (() => {
  let sentry: SentryInterface;
  return async () => {
    if (sentry) return sentry;
    sentry = await import('@sentry/node');
    return sentry;
  };
})();
