/*
 * File: searchElementItem.styles.ts
 * Project: meki
 * File Created: Thursday, 28th July 2022 9:55:33 am
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Friday, 2nd February 2024 12:41:29 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { ListItem, Palette, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
type StyleProps = {
  desktop: boolean;
};
const ListItemStyled = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'desktop',
})<StyleProps>(({ theme, desktop }) => ({
  minWidth: '320px',
  width: desktop ? '100%' : '',
  height: '40px',
  backgroundColor: (theme.palette as Palette).common.white,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  ':hover': {
    backgroundColor: desktop ? '#f5f5f5' : '',
    cursor: desktop ? 'pointer' : '',
  },
  '&:last-child': {
    [theme.breakpoints.up(768)]: {
      borderBottomLeftRadius: '32px',
      borderBottomRightRadius: '32px',
    },
  },
}));
const SkeletonDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});
const TextSpan = styled('span')({
  display: 'flex',
  fontSize: '15px',
  fontWeight: 400,
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.54)',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});
const SearchIconStyled = styled(SearchIcon)({
  color: 'rgba(0, 0, 0, 0.54)',
  marginRight: '10px',
});
const SkeletonStyled = styled(Skeleton)({
  maxWidth: '200px',
  minWidth: '100px',
});
const Divider = styled('hr')({
  margin: '0px',
  border: '0.5px solid #e0e0e0',
});
export {
  ListItemStyled,
  SkeletonDiv,
  TextSpan,
  SearchIconStyled,
  SkeletonStyled,
  Divider,
};
