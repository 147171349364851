/*
 * File: objects.ts
 * Project: meki
 * File Created: Friday, 30th October 2020 1:53:06 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Monday, 12th April 2021 6:24:41 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

export const removeUndefineds = <T = unknown>(
  object: Record<string, T>,
): Record<string, T> => {
  const entries = Object.entries(object);
  return entries.reduce((all, [key, val]) => {
    if (val !== undefined) {
      return { ...all, [key]: val };
    }
    return all;
  }, {});
};

interface Omit {
  <T extends Record<string, unknown>, K extends [...(keyof T)[]]>(
    obj: T,
    ...keys: K
  ): {
    [K2 in Exclude<keyof T, K[number]>]: T[K2];
  };
}

export const removeUnuseds: Omit = (obj, ...keys) => {
  const ret = {} as {
    [K in keyof typeof obj]: typeof obj[K];
  };
  let key: keyof typeof obj;
  for (key in obj) {
    if (!keys.includes(key)) {
      ret[key] = obj[key];
    }
  }
  return ret;
};

export const dotProductOfKeys = <T extends Record<string, unknown>>(
  collection: T[],
  key1: string,
  key2: string,
): number => {
  return (
    collection.reduce(
      (prev, current) =>
        prev +
        Number(getNestedKeyValue(current, key1)) *
          Number(getNestedKeyValue(current, key2)),
      0,
    ) ?? 0
  );
};

export const sumOfKeys = (
  collection: Record<string, unknown>[],
  key: string,
): number => {
  return (
    collection.reduce(
      (prev, current) => prev + Number(getNestedKeyValue(current, key)),
      0,
    ) ?? 0
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const getNestedKeyValue = (object: object, keyChain: string) => {
  const arrayOfKeys = keyChain.split('.');

  let actualValue = object;

  for (const key of arrayOfKeys) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const nestedValue = actualValue[key];
    if (typeof nestedValue === 'object' && nestedValue !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      actualValue = nestedValue;
    } else if (nestedValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      actualValue = nestedValue;
      break;
    } else return 0;
  }

  return actualValue;
};
