/*
 * File: identifyUser.ts
 * Project: meki
 * File Created: Monday, 24th May 2021 1:05:37 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Friday, 19th August 2022 11:24:15 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { gql } from '@apollo/client';

export const identifyUser = () => gql`
  mutation identifyUser {
    identifyUser
  }
`;

export type IdentifyUserParams = undefined;

export type IdentifyUserResponse = {
  identifyUser: boolean;
};
