import { ProductQueryResponse } from '@queries/products/queries';
import { cleanQuery, cleanActivePrinciples } from '@utils';

export function getSearchTitleBox(
  searchString: string,
  pageData: ProductQueryResponse,
  loading: boolean,
): string {
  const query = cleanQuery(searchString);
  const perfectMatchProducts = pageData?.products?.perfectMatchProducts;
  const nodes = pageData?.products?.nodes ?? [];
  if (perfectMatchProducts?.length > 0) {
    return 'Te podría interesar';
  }

  if (nodes?.length > 1 || loading) {
    if (
      nodes[0]?.activePrinciple &&
      cleanActivePrinciples(query).includes(
        cleanActivePrinciples(
          nodes[0].activePrinciple + ' ' + nodes[0].concentration,
        ),
      )
    ) {
      return `Resultados ${nodes[0].activePrinciple} ${nodes[0].concentration}`;
    }
    if (
      nodes[0]?.activePrinciple &&
      cleanActivePrinciples(query).includes(
        cleanActivePrinciples(nodes[0].activePrinciple),
      )
    ) {
      return 'Resultados ' + nodes[0].activePrinciple;
    }
    return 'Resultados búsqueda';
  }
  return 'Resultado';
}
