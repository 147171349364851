/*
 * File: Loader.tsx
 * Project: meki
 * File Created: Wednesday, 6th January 2021 11:48:15 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Friday, 18th November 2022 4:56:16 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { Console } from '@utils';
import { styled } from '@mui/system';
import { Palette } from '@mui/material';

const ContentDiv = styled('div')(({ theme }) => ({
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100vh',
  position: 'fixed',
  background: ' rgba(0,0,0,0.6)',
  zIndex: 9999999,
  top: 0,
  right: 0,
  left: 0,
  color: (theme.palette as Palette).primary.dark,
}));
interface LoaderProps {
  tag?: string;
}
export function Loader(props: LoaderProps) {
  useEffect(
    function handleMount() {
      Console.log('Loader init', { tag: props.tag });
      return () => {
        Console.log('Loader finish', { tag: props.tag });
      };
    },
    [props.tag],
  );

  return (
    <ContentDiv>
      <CircularProgress />
    </ContentDiv>
  );
}
