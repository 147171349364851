/*
 * File: highlightsProducts.tsx
 * Project: meki
 * File Created: Tuesday, 14th December 2021 11:09:27 am
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Tuesday, 14th December 2021 12:03:27 pm
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { gql } from '@apollo/client';
import { Product } from '@interfaces';
import { ProductBaseSearchFragment } from '../fragments';

export const HighlightsProductsQuery = () => gql`
  query HighlightsProducts {
    highlightsProducts {
      ...ProductBaseSearch
    }
  }
  ${ProductBaseSearchFragment}
`;

export type HighlightsProductsQueryResponse = {
  highlightsProducts: Product[];
};
