import React, { useContext, useEffect, useRef } from 'react';
import { useOrderState } from '@hooks/useOrderState';
import { useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { Console, getRoute } from '@utils';
import {
  getFinalSummary,
  GetFinalSummaryQueryParams,
  GetFinalSummaryQueryResponse,
} from '@queries/checkout/queries/finalSummary';
import { useTrackPayment } from '../screens/checkout/paymentMethod/components/PayButton.hooks';
import { useFacebokPixelEvent } from '@hooks/useFacebookPixel';
import { usePermissions } from '@hooks/usePermissions';

const OrderInPaymentValidationContext = React.createContext({});

export const useOrderInPaymentValidation = () => {
  const value = useContext(OrderInPaymentValidationContext);
  return value;
};

interface OrderInPaymentValidationProps {
  children: React.ReactNode;
}

export const OrderInPaymentValidationProvider = ({
  children,
}: OrderInPaymentValidationProps) => {
  const { orderStatus, orderUuid, order, refetch } = useOrderState();
  const { userCan } = usePermissions();
  const trackPayment = useTrackPayment();
  const { trackFBEvent } = useFacebokPixelEvent();
  const orderGroupId = order?.orderGroupId;
  const isLocalVendor = userCan('LOCAL_VENDOR');
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const router = useRouter();
  const [prefetchOrder] = useLazyQuery<
    GetFinalSummaryQueryResponse,
    GetFinalSummaryQueryParams
  >(getFinalSummary());

  useEffect(() => {
    if (orderStatus === 'PAYMENT_VALIDATION' && !isLocalVendor) {
      if (router.asPath !== '/checkout/confirmar-pago') {
        void router.push(getRoute('checkoutPaymentConfirm'));
      }
      const orderOrGroupUuid = orderGroupId ?? orderUuid;
      intervalRef.current = setInterval(() => {
        void prefetchOrder({
          variables: {
            params: {
              status: ['PAYMENT_CONFIRMED', 'DRAFT'],
              uuid: orderOrGroupUuid,
            },
          },
        })
          .then((response) => {
            if (response.data) {
              const status = response.data.ordersByUuid[0].status;
              if (status === 'PAYMENT_CONFIRMED') {
                clearInterval(intervalRef.current);
                try {
                  trackPayment({
                    orderId: order?.id,
                    orders: [order],
                    totalPrice: order?.totalCost,
                    deliveryPrice: order?.deliveryCost,
                  });
                } catch (e) {
                  Console.error('Error tracking payment');
                  Console.error(e);
                }
                void router.push(
                  getRoute('checkoutPaymentSuccess', {
                    orderUuid: orderOrGroupUuid,
                  }),
                );
                void refetch();
                return;
              } else if (status === 'DRAFT') {
                clearInterval(intervalRef.current);
              }
            }
          })
          .catch(() => {
            Console.log('ORDER NOT CONRFIRMED BY OTHER PAYMENT METHOD');
          });
      }, 2000);
    }
    if (orderStatus === 'PAYMENT_VALIDATION' && isLocalVendor) {
      clearInterval(intervalRef.current);
      if (router.asPath !== '/checkout/confirmar-pago') {
        void router.push(getRoute('checkoutPaymentConfirm'));
      }
    }
  }, [
    order,
    orderGroupId,
    orderUuid,
    prefetchOrder,
    router,
    trackPayment,
    trackFBEvent,
    router.asPath,
    orderStatus,
    isLocalVendor,
    refetch,
  ]);

  return (
    <OrderInPaymentValidationContext.Provider value={{}}>
      {children}
    </OrderInPaymentValidationContext.Provider>
  );
};
