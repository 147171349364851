/*
 * File: useCartMenu.tsx
 * Project: meki
 * File Created: Friday, 22nd July 2022 2:24:11 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Tuesday, 15th November 2022 12:42:29 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Console } from '@utils';

interface ScrollContextValue {
  isScrollAvailable: boolean;
  allowScroll: (tag?: string) => void;
  disableScroll: (tag?: string) => void;
}

const ScrollContext = React.createContext<ScrollContextValue>({
  isScrollAvailable: true,
  allowScroll: () => {},
  disableScroll: () => {},
});

export const useScroll = () => {
  const value = useContext(ScrollContext);
  return value;
};

interface ScrollProviderProps {
  children: React.ReactNode;
}

export const ScrollProvider = ({ children }: ScrollProviderProps) => {
  const [scroll, setScroll] = useState(true);
  const allowScrollFunction = useCallback((tag?: string) => {
    setScroll(true);
    const element = document.querySelector('body');
    const current = element.style.overflow;
    const scrollY = element.style.top;
    Console.log('[useScroll]', tag, 'alow scroll', {
      current,
    });
    element.style.overflow = 'visible';
    element.style.width = '';
    window.scroll(0, parseInt(scrollY || '0') * -1);
    element.style.position = '';
  }, []);
  const disableScrollFunction = useCallback((tag?: string) => {
    setScroll(false);
    const element = document.querySelector('body');
    const current = element.style.overflow;
    Console.log('[useScroll]', tag, 'hide scroll', {
      current,
    });
    element.style.overflow = 'hidden';
    element.style.width = '100vw';
    element.style.top = `-${window.scrollY}px`;
    element.style.position = 'fixed';
  }, []);
  const value = useMemo(() => {
    return {
      isScrollAvailable: scroll,
      allowScroll: allowScrollFunction,
      disableScroll: disableScrollFunction,
    };
  }, [scroll, allowScrollFunction, disableScrollFunction]);
  return (
    <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
  );
};
