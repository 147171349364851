/*
 * File: useTagManagerEvent.ts
 * Project: meki
 * File Created: Monday, 9th August 2021 11:01:58 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Wednesday, 19th October 2022 11:55:56 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
/**
 * ? interfaces based on https://www.optimizesmart.com/how-to-set-up-ecommerce-tracking-in-ga4-google-analytics-4/#a6
 */

import { Console } from '@utils';
import { useCallback } from 'react';
type AuthEvent = 'authComplete' | 'signoutComplete';
type EcommerceEvent =
  | 'view_item_list'
  | 'select_item'
  | 'view_item'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'view_promotion'
  | 'select_promotion'
  | 'begin_checkout'
  | 'transaction'
  | 'refund';
type Event = AuthEvent | EcommerceEvent;

export interface EcommerceItems {
  item_name: string;
  item_id: string;
  price: number;
  item_brand: string;
  item_category?: string;
  item_category_2?: string;
  item_category_3?: string;
  item_category_4?: string;
  item_variant?: string;
  item_list_name?: string;
  item_list_id?: string;
  index?: number;
  quantity: number;
  prescription_type?: string;
  availability?: string;
}
interface EcommercePromotionItems extends EcommerceItems {
  promotion_id: string;
  promotion_name: string;
  creative_name: string;
  creative_slot: number;
  location_id: string;
}
export interface Ecommerce {
  items: EcommerceItems[];
}
interface EcommercePromotion {
  items: EcommercePromotionItems[];
}
interface EcommercePurchase extends Ecommerce {
  totalPrice: number;
  purchase: {
    transaction_id: string;
    affiliation: string;
    value: string;
    tax: string;
    shipping: string;
    currency: string;
  };
}
interface EcommerceRefund extends Ecommerce {
  transaction_id: string;
}
type EcommerceData<T extends EcommerceEvent> = T extends 'view_item_list'
  ? Ecommerce
  : T extends 'select_item'
  ? Ecommerce
  : T extends 'view_item'
  ? Ecommerce
  : T extends 'add_to_cart'
  ? Ecommerce
  : T extends 'remove_from_cart'
  ? Ecommerce
  : T extends 'view_promotion'
  ? EcommercePromotion
  : T extends 'select_promotion'
  ? EcommercePromotion
  : T extends 'begin_checkout'
  ? Ecommerce
  : T extends 'transaction'
  ? EcommercePurchase
  : T extends 'refund'
  ? EcommerceRefund
  : never;

interface DataLayerElement<T extends Event> {
  event?: T;
  ecommerce?: T extends EcommerceEvent ? null | EcommerceData<T> : undefined;
  segment?: Record<string, unknown>;
}
declare global {
  interface Window {
    dataLayer: DataLayerElement<Event>[];
  }
}
export function useTagManagerEvent() {
  const addEcommerceEvent = useCallback(
    <T extends EcommerceEvent>(
      event: T,
      data: EcommerceData<T>,
      extraData: Record<string, unknown> = {},
    ) => {
      Console.log('[TAG_MANAGER] add ecommerce event', { event, data });
      if (!window.dataLayer) return;
      if (extraData.segment) {
        window.dataLayer.push({ segment: null });
      }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({ ...extraData, event, ecommerce: data });
    },
    [],
  );
  const addCustomEvent = useCallback(
    (event: Event, data: Record<string, string | number | unknown> = {}) => {
      Console.log('[TAG_MANAGER] add custom event', { event, data });
      if (!window.dataLayer) return;
      if (data.segment) {
        window.dataLayer.push({ segment: null });
      }
      window.dataLayer.push({ event, ...data });
    },
    [],
  );
  const emittedEvent = useCallback((event: Event) => {
    if (!window.dataLayer) return false;
    return window.dataLayer.some((e) => e.event === event);
  }, []);
  return { addEcommerceEvent, addCustomEvent, emittedEvent };
}
