/*
 * File: index.tsx
 * Project: meki
 * File Created: Monday, 14th September 2020 11:27:11 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Wednesday, 15th March 2023 2:38:20 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, { useState, useRef } from 'react';
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
  ApolloProvider as ApolloProviderHooks,
  NormalizedCacheObject,
  NextLink,
  Operation,
} from '@apollo/client';
import { onError, ErrorResponse } from '@apollo/link-error';
import { RetryLink } from '@apollo/client/link/retry';

import { setContext } from 'apollo-link-context';
import { getFirebaseAuth } from '@services/firebase';

import { GRAPHQL_URI } from '../environment';
import { GraphQLError } from 'graphql';
import { useDeepEffect } from '@hooks/useDeepEffect';
import { currentOrder } from '@queries/order/queries/currentOrder';
import { Console } from '@utils';
import { Order, Prescription, Product } from '@interfaces';
import { getProductQueryParams } from '@queries/product/queries';

import { v4 as uuid } from 'uuid';
import { onAuthStateChanged, User } from 'firebase/auth';
import { trackerGraphQL } from '@services/openreplay';
interface GraphQlError extends GraphQLError {
  extensions: {
    response: {
      body: { errors: { extensions: { message: string; code: string } }[] };
    };
  };
}
class ApolloLoggerLink extends ApolloLink {
  request(operation: Operation, forward?: NextLink) {
    const context = operation.getContext();
    return forward(operation).map((response) => {
      Console.log(`[ApolloLogs] ${operation.operationName}`, {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        transactionId: context.headers?.['transaction-id'],
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        sessionId: context.headers?.['session-id'],
        operation,
        response,
      });
      return response;
    });
  }
}

interface LinkContext {
  headers: {
    authorization?: string;
    'transaction-id': string;
    useremail: string;
  };
}
export const GlobalApolloClient = { logout: () => Promise.resolve() };
let apolloClient: ApolloClient<NormalizedCacheObject>;

const waitForUser = () => {
  return new Promise<User>((res) => {
    const firebaseAuth = getFirebaseAuth();
    if (firebaseAuth.currentUser) {
      return res(firebaseAuth.currentUser);
    }
    if (typeof window !== 'undefined') {
      const unsuscribe = onAuthStateChanged(firebaseAuth, (user) => {
        unsuscribe();
        res(user);
      });
    } else {
      res(null);
    }
  });
};
const getToken = async () => {
  const currentUser = await waitForUser();

  if (!currentUser) {
    return '';
  }

  return currentUser.getIdToken();
};
const clearToken = () => {
  return getFirebaseAuth().signOut();
};

const createClient = () => {
  const httpLink = new HttpLink({
    uri: GRAPHQL_URI,
  });

  const authMiddleware = setContext(async (req, { headers }: LinkContext) => {
    const currentToken = await getToken();

    if (currentToken) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${currentToken}`,
        },
      };
    }

    return { headers };
  });
  const sessionMiddleware = setContext((req, { headers }: LinkContext) => {
    if (typeof window === 'undefined') return { headers };
    return {
      headers: {
        ...headers,
        'session-id': window.sessionId,
        'transaction-id': uuid(),
      },
    };
  });

  const userEmailMiddleware = setContext((req, { headers }: LinkContext) => {
    if (typeof window === 'undefined') return { headers };
    return {
      headers: {
        ...headers,
        useremail:
          (JSON.parse(window.localStorage.getItem('useremail')) as string) ??
          '',
      },
    };
  });

  const errorLink = onError(
    ({
      graphQLErrors,
      operation,
      networkError,
      response,
      forward,
    }: ErrorResponse) => {
      const context = operation.getContext();
      Console.log(`[ApolloLogs][Error] ${operation.operationName}`, {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        transactionId: context.headers?.['transaction-id'],
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        sessionId: context.headers?.['session-id'],
        operation,
        response,
        graphQLErrors,
        networkError,
      });
      if (graphQLErrors) {
        graphQLErrors.forEach((err) => {
          const extensions = err.extensions as GraphQlError['extensions'];
          const invalidAuth = extensions?.response?.body?.errors?.some((e) =>
            /invalid_token/.exec(e.extensions?.message),
          );

          if (invalidAuth) {
            void GlobalApolloClient.logout();
          }
        });
      }
      if (operation.operationName === 'SearchResults')
        return forward(operation);
    },
  );

  const trackerApolloLink = new ApolloLink((operation, forward) => {
    if (trackerGraphQL.current) {
      return forward(operation).map((result) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        trackerGraphQL.current(
          (
            operation.query.definitions[0] as unknown as {
              operation: string;
            }
          ).operation,
          operation.operationName,
          operation.variables,
          result,
        ),
      );
    }
    return forward(operation);
  });
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => {
        if (_operation.operationName === 'SearchResults') return !!error;
        return false;
      },
    },
  });
  const linkWithAuthenticationHeader = from(
    [
      trackerApolloLink,
      authMiddleware,
      errorLink,
      sessionMiddleware,
      userEmailMiddleware,
      new ApolloLoggerLink(),
      retryLink,
      httpLink,
    ].filter(Boolean) as ApolloLink[],
  );
  const cache = new InMemoryCache({
    typePolicies: {
      Order: {
        fields: {
          orderDetails: {
            merge: false,
          },
        },
      },
      OrderDetail: {
        merge: false,
      },
      Product: {
        fields: {
          availability: {
            merge: true,
          },
        },
      },
      Query: {
        fields: {
          product(
            currentProduct: Product,
            { args, toReference, cache: currentCache },
          ) {
            if (currentProduct) return currentProduct;
            const allCache = (
              currentCache as unknown as {
                data: { data: Record<string, Product> };
              }
            ).data.data;
            const product: [string, Product] | null = Object.entries(
              allCache,
            ).find(
              ([key, value]) =>
                key.startsWith('Product') &&
                value.slug === (args as getProductQueryParams).params.slug,
            );
            if (!product) return undefined;
            return toReference({ __typename: 'Product', id: product[1].id });
          },
          products: {
            keyArgs: ['params', ['query']],
            merge(
              existing: { nodes: Product[]; pageInfo: Record<string, unknown> },
              incoming: { nodes: Product[]; pageInfo: Record<string, unknown> },
            ) {
              const currentNodes = existing?.nodes ?? [];
              const usedIds = new Set(
                Array.from(currentNodes.map((e) => e.__ref)),
              );
              const nodes = incoming.nodes.reduce((all, node) => {
                if (usedIds.has(node.__ref)) return all;
                usedIds.add(node.__ref);
                return [...all, node];
              }, currentNodes);
              return {
                ...existing,
                ...incoming,
                nodes,
              };
            },
          },
          orders: {
            keyArgs: false,
            merge(
              existing: { nodes: Order[]; pageInfo: Record<string, unknown> },
              incoming: { nodes: Order[]; pageInfo: Record<string, unknown> },
            ) {
              const currentNodes = existing?.nodes ?? [];
              const usedIds = new Set(
                Array.from(currentNodes.map((e) => e.__ref)),
              );
              const nodes = incoming.nodes.reduce((all, node) => {
                if (usedIds.has(node.__ref)) return all;
                usedIds.add(node.__ref);
                return [...all, node];
              }, currentNodes);
              return {
                ...existing,
                ...incoming,
                nodes,
              };
            },
          },
          bestSellingProductsSubCategory: {
            keyArgs: ['params', ['slug', 'filterBy', 'sortBy']],
            merge(
              existing: { nodes: Product[]; pageInfo: Record<string, unknown> },
              incoming: { nodes: Product[]; pageInfo: Record<string, unknown> },
            ) {
              const currentNodes = existing?.nodes ?? [];
              const usedIds = new Set(
                Array.from(currentNodes.map((e) => e.__ref)),
              );
              const nodes = incoming.nodes.reduce((all, node) => {
                if (usedIds.has(node.__ref)) return all;
                usedIds.add(node.__ref);
                return [...all, node];
              }, currentNodes);
              return {
                ...existing,
                ...incoming,
                nodes,
              };
            },
          },
          bestSellingProductsCategory: {
            keyArgs: ['params', ['slug', 'filterBy', 'sortBy']],
            merge(
              existing: { nodes: Product[]; pageInfo: Record<string, unknown> },
              incoming: { nodes: Product[]; pageInfo: Record<string, unknown> },
            ) {
              const currentNodes = existing?.nodes ?? [];
              const usedIds = new Set(
                Array.from(currentNodes.map((e) => e.__ref)),
              );
              const nodes = incoming.nodes.reduce((all, node) => {
                if (usedIds.has(node.__ref)) return all;
                usedIds.add(node.__ref);
                return [...all, node];
              }, currentNodes);
              return {
                ...existing,
                ...incoming,
                nodes,
              };
            },
          },
          allPurchases: {
            keyArgs: false,
            merge(
              existing: {
                purchases: Product[];
                pageInfo: Record<string, unknown>;
              },
              incoming: {
                purchases: Product[];
                pageInfo: Record<string, unknown>;
              },
            ) {
              const currentNodes = existing?.purchases ?? [];
              const usedIds = new Set(
                Array.from(currentNodes.map((e) => e.__ref)),
              );
              const purchases = incoming.purchases.reduce((all, purchase) => {
                if (usedIds.has(purchase.__ref)) return all;
                usedIds.add(purchase.__ref);
                return [...all, purchase];
              }, currentNodes);
              return {
                ...existing,
                ...incoming,
                purchases,
              };
            },
          },
          getPrescriptions: {
            keyArgs: false,
            merge(
              existing: {
                prescriptions: Prescription[];
                pageInfo: Record<string, unknown>;
              },
              incoming: {
                prescriptions: Prescription[];
                pageInfo: Record<string, unknown>;
              },
            ) {
              const currentNodes = existing?.prescriptions ?? [];
              const usedIds = new Set(
                Array.from(currentNodes.map((e) => e.__ref)),
              );
              const prescriptions = incoming.prescriptions.reduce(
                (all, prescription) => {
                  if (usedIds.has(prescription.__ref)) return all;
                  usedIds.add(prescription.__ref);
                  return [...all, prescription];
                },
                currentNodes,
              );
              return {
                ...existing,
                ...incoming,
                prescriptions,
              };
            },
          },
        },
      },
    },
  });
  const apolloClient = new ApolloClient({
    link: linkWithAuthenticationHeader,
    cache,
    ssrMode: typeof window === 'undefined',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
  if (typeof window !== 'undefined') {
    GlobalApolloClient.logout = async () => {
      const currentToken = await getToken();
      // TODO: writeQuery to remove viewerInfo data
      // apolloClient.writeQuery({
      //   query: GET_USER_QUERY,
      //   data: { viewerInfo: null },
      // });
      Console.log('[APOLLO_CONFIG] logout', { currentToken });
      if (currentToken) {
        apolloClient.writeQuery({
          query: currentOrder(),
          data: { currentOrder: {} },
        });
        await apolloClient.clearStore();
        await apolloClient.cache.reset();
        await clearToken();
      }
    };
  }

  return apolloClient;
};

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

type ProviderProps = {
  children: React.ReactNode;
  initialApolloState: unknown;
};
export const ApolloProvider = ({
  children,
  initialApolloState,
}: ProviderProps) => {
  const clientRef = useRef(
    new ApolloClient({
      uri: GRAPHQL_URI,
      cache: new InMemoryCache(),
    }),
  );
  const [initialized, setInitialized] = useState(false);
  useDeepEffect(() => {
    const newClient = initializeApollo(initialApolloState);
    clientRef.current = newClient;
    setInitialized(true);
  }, [initialApolloState]);
  if (
    typeof window === 'undefined' ||
    (typeof window !== 'undefined' && initialized)
  )
    return (
      <ApolloProviderHooks client={clientRef.current}>
        {children}
      </ApolloProviderHooks>
    );
  return null;
};
