/*
 * File: useWorking.ts
 * Project: meki
 * File Created: Thursday, 22nd July 2021 11:05:04 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Monday, 27th November 2023 4:33:59 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Console } from '@utils';
import { MEKI_WORKING_ON } from '@config/environment';
export function useWorking() {
  const router = useRouter();
  const workingTreatment = MEKI_WORKING_ON;
  useEffect(
    function handleRedirect() {
      Console.log({
        msg: '[useWorking] handle redirect',
        treatment: workingTreatment,
        pathname: router.pathname,
      });
      if (workingTreatment && router.pathname !== '/') {
        void router.replace('/');
      }
    },
    [router, workingTreatment],
  );
  return workingTreatment;
}

export function WorkingComponent() {
  useWorking();
  return null;
}
