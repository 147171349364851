const mainCategories = [
  {
    name: 'Medicamentos',
    slug: 'medicamentos',
    icon: '/assets/images/categories/bottle_with_pills.webp',
    id: 17,
    subCategories: [
      {
        id: 100000,
        name: 'Todo en medicamentos',
        slug: 'todo-medicamentos',
        typename: 'Category',
      },
      {
        id: 36,
        name: 'Sistema nervioso',
        slug: 'sistema-nervioso',
        typename: 'SubCategory',
      },
      {
        id: 31,
        name: 'Cardiovasculares',
        slug: 'cardiovasculares',
        typename: 'SubCategory',
      },
      {
        id: 32,
        name: 'Analgésico y antiinflamatorios',
        slug: 'analgesico-y-antiinflamatorios',
        typename: 'SubCategory',
      },
      {
        id: 30,
        name: 'Gastrointestinales',
        slug: 'gastrointestinales',
        typename: 'SubCategory',
      },
      {
        id: 58,
        name: 'Cenabast',
        slug: 'cenabast',
        typename: 'SubCategory',
      },
      {
        id: 3,
        name: 'Antibióticos y antivirales',
        slug: 'antibioticos-y-antivirales',
        typename: 'SubCategory',
      },
      {
        id: 4,
        name: 'Respiratorios',
        slug: 'respiratorios',
        typename: 'SubCategory',
      },
      {
        id: 25,
        name: 'Oftalmológicos',
        slug: 'oftalmologicos',
        typename: 'SubCategory',
      },
      {
        id: 35,
        name: 'Dermatológicos',
        slug: 'dermatologicos',
        typename: 'SubCategory',
      },
      {
        id: 42,
        name: 'Salud Mujer',
        slug: 'salud-mujer',
        typename: 'SubCategory',
      },
      {
        id: 38,
        name: 'Inmunología y oncología',
        slug: 'inmunologia-y-oncologia',
        typename: 'SubCategory',
      },
      {
        id: 43,
        name: 'Diabetes',
        slug: 'diabetes',
        typename: 'SubCategory',
      },
      {
        id: 41,
        name: 'Antialérgicos',
        slug: 'antialergicos',
        typename: 'SubCategory',
      },
      {
        id: 1,
        name: 'Ostearticular y muscular',
        slug: 'ostearticular-y-muscular',
        typename: 'SubCategory',
      },
      {
        id: 19,
        name: 'Salud Hombre',
        slug: 'salud-hombre',
        typename: 'SubCategory',
      },
      {
        id: 40,
        name: 'Antimicoticos',
        slug: 'antimicoticos',
        typename: 'SubCategory',
      },
      {
        id: 39,
        name: 'Corticoides',
        slug: 'corticoides',
        typename: 'SubCategory',
      },
      {
        id: 9,
        name: 'Antigripales',
        slug: 'antigripales',
        typename: 'SubCategory',
      },
      {
        id: 45,
        name: 'Sistema endocrino',
        slug: 'sistema-endocrino',
        typename: 'SubCategory',
      },
      {
        id: 57,
        name: 'Veterinaria',
        slug: 'veterinaria',
        typename: 'SubCategory',
      },
      {
        id: 37,
        name: 'Antisepticos',
        slug: 'antisepticos',
        typename: 'SubCategory',
      },
      {
        id: 47,
        name: 'Óticos (oídos)',
        slug: 'oticos-(oidos)',
        typename: 'SubCategory',
      },
    ],
    typename: 'Category',
  },
  {
    name: 'Belleza',
    slug: 'belleza',
    icon: '/assets/images/categories/bottle_with_pipette.webp',
    id: 21,
    subCategories: [
      {
        id: 200000,
        name: 'Todo en belleza',
        slug: 'todo-belleza',
        typename: 'Category',
      },
      {
        id: 28,
        name: 'Maquillaje y accesorios',
        slug: 'maquillaje-y-accesorios',
        typename: 'SubCategory',
      },
      {
        id: 26,
        name: 'Manos y pies',
        slug: 'manos-y-pies',
        typename: 'SubCategory',
      },
      {
        id: 27,
        name: 'Perfumes',
        slug: 'perfumes',
        typename: 'SubCategory',
      },
      {
        id: 8,
        name: 'Coloración capilar',
        slug: 'coloracion-capilar',
        typename: 'SubCategory',
      },
    ],
    typename: 'Category',
  },
  {
    name: 'Accesorios y dispositivos',
    slug: 'accesorios-y-dispositivos',
    icon: '/assets/images/categories/thermometer.webp',
    id: 5,
    subCategories: [
      {
        id: 300000,
        name: 'Todo en accesorios y dispositivos',
        slug: 'todo-accesorios-y-dispositivos',
        typename: 'Category',
      },
      {
        id: 7,
        name: 'Primeros auxilios',
        slug: 'primeros-auxilios',
        typename: 'SubCategory',
      },
      {
        id: 50,
        name: 'Ortopedia',
        slug: 'ortopedia',
        typename: 'SubCategory',
      },
      {
        id: 51,
        name: 'Infantil y mamá',
        slug: 'infantil-y-mama',
        typename: 'SubCategory',
      },
      {
        id: 6,
        name: 'Otros',
        slug: 'otros',
        typename: 'SubCategory',
      },
      {
        id: 16,
        name: 'Control glicemia',
        slug: 'control-glicemia',
        typename: 'SubCategory',
      },
      {
        id: 5,
        name: 'Agujas y jeringas',
        slug: 'agujas-y-jeringas',
        typename: 'SubCategory',
      },
      {
        id: 53,
        name: 'Mascarillas y guantes',
        slug: 'mascarillas-y-guantes',
        typename: 'SubCategory',
      },
      {
        id: 56,
        name: 'Tests',
        slug: 'tests',
        typename: 'SubCategory',
      },
      {
        id: 49,
        name: 'Aerocámaras y nebulizadores',
        slug: 'aerocamaras-y-nebulizadores',
        typename: 'SubCategory',
      },
      {
        id: 54,
        name: 'Termómetros',
        slug: 'termometros',
        typename: 'SubCategory',
      },
      {
        id: 52,
        name: 'Control presión',
        slug: 'control-presion',
        typename: 'SubCategory',
      },
    ],
    typename: 'Category',
  },
  {
    name: 'Cuidado personal',
    slug: 'cuidado-personal',
    icon: '/assets/images/categories/tooth_brush.webp',
    id: 6,
    subCategories: [
      {
        id: 400000,
        name: 'Todo en cuidado personal',
        slug: 'todo-cuidado-personal',
        typename: 'Category',
      },
      {
        id: 13,
        name: 'Rostro',
        slug: 'rostro',
        typename: 'SubCategory',
      },
      {
        id: 12,
        name: 'Cuidado capilar',
        slug: 'cuidado-capilar',
        typename: 'SubCategory',
      },
      {
        id: 10,
        name: 'Higiene bucal',
        slug: 'higiene-bucal',
        typename: 'SubCategory',
      },
      {
        id: 18,
        name: 'Desodorantes y talcos',
        slug: 'desodorantes-y-talcos',
        typename: 'SubCategory',
      },
      {
        id: 14,
        name: 'Infantil',
        slug: 'infantil',
        typename: 'SubCategory',
      },
      {
        id: 11,
        name: 'Cuerpo',
        slug: 'cuerpo',
        typename: 'SubCategory',
      },
      {
        id: 23,
        name: 'Limpieza y jabones',
        slug: 'limpieza-y-jabones',
        typename: 'SubCategory',
      },
      {
        id: 29,
        name: 'Protección solar',
        slug: 'proteccion-solar',
        typename: 'SubCategory',
      },
      {
        id: 24,
        name: 'Cuidado de la mujer',
        slug: 'cuidado-de-la-mujer',
        typename: 'SubCategory',
      },
      {
        id: 22,
        name: 'Afeitado',
        slug: 'afeitado',
        typename: 'SubCategory',
      },
      {
        id: 48,
        name: 'Bienestar sexual',
        slug: 'bienestar-sexual',
        typename: 'SubCategory',
      },
      {
        id: 20,
        name: 'Incontinencia',
        slug: 'incontinencia',
        typename: 'SubCategory',
      },
      {
        id: 55,
        name: 'Depilación',
        slug: 'depilacion',
        typename: 'SubCategory',
      },
      {
        id: 34,
        name: 'Algodón y cotonitos',
        slug: 'algodon-y-cotonitos',
        typename: 'SubCategory',
      },
      {
        id: 21,
        name: 'Pañuelos desechables',
        slug: 'panuelos-desechables',
        typename: 'SubCategory',
      },
    ],
    typename: 'Category',
  },
  {
    name: 'Nutrición y vitaminas',
    slug: 'nutricion',
    icon: '/assets/images/categories/vitamins.webp',
    id: 4,
    subCategories: [
      {
        id: 500000,
        name: 'Todo en nutrición y vitaminas',
        slug: 'todo-nutricion-y-vitaminas',
        typename: 'Category',
      },
      {
        id: 2,
        name: 'Suplementos y multivitamínicos',
        slug: 'suplementos-y-multivitaminicos',
        typename: 'SubCategory',
      },
      {
        id: 15,
        name: 'Vitaminas y minerales',
        slug: 'vitaminas-y-minerales',
        typename: 'SubCategory',
      },
      {
        id: 46,
        name: 'Nutrición deportiva',
        slug: 'nutricion-deportiva',
        typename: 'SubCategory',
      },
      {
        id: 44,
        name: 'Nutrición Infantil',
        slug: 'nutricion-infantil',
        typename: 'SubCategory',
      },
      {
        id: 17,
        name: 'Probióticos',
        slug: 'probioticos',
        typename: 'SubCategory',
      },
    ],
    typename: 'Category',
  },
];

export { mainCategories };
