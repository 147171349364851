/*
 * File: useRefererParam.ts
 * Project: meki
 * File Created: Thursday, 21st October 2021 12:42:39 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Thursday, 21st October 2021 12:45:51 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { useEffect } from 'react';

export function useRefererParam() {
  useEffect(function () {
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search),
    );
    if (params.r) {
      localStorage.setItem('r', params.r);
    }
  }, []);
}
