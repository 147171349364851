/*
 * File: productBaseSearch.ts
 * Project: meki
 * File Created: Tuesday, 19th January 2021 4:48:03 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Tuesday, 9th November 2021 12:49:51 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';

export const ProductBaseSearchFragment = gql`
  fragment ProductBaseSearch on Product {
    id
    uuid
    slug
    name
    imagesUrl
    unit
    format
    price
    discount {
      price
      type
      stockRestriction
      dateRestriction
      listPrice
    }
    concentration
    activePrinciple
    prescriptionType
    quantity
    laboratory
    containerQuantity
    container
    availability {
      status
      stock
      statusWithoutStock
      discountStock
    }
    isCheapestActivePrinciple
  }
`;
