import { Product } from '@interfaces';

export const extractExtraInfo = (product: Product): string => {
  const unit = (
    new RegExp(product.unit, 'i').exec(product.format)
      ? `${product.quantity ?? ''} ${product.format ?? ''}`
      : `${product.quantity ?? ''} ${product.unit ?? ''} ${
          product.format ?? ''
        }`
  ).trim();
  if (product.concentration?.includes(';')) {
    if (product.concentration.split(';').length > 3) return unit;
    const concentration = product.concentration
      .split(';')
      .slice(0, 3)
      .map((text) => text.trim().replace(/\(.*\)/g, ''))
      .join(' / ');
    return `${concentration} · ${unit}`;
  }
  return `${product.concentration ?? ''} · ${unit}`.replace(/^ · /, '');
};

export const extractConcentrationsLabs = (product: Product): string => {
  if (product.concentration?.includes(';')) {
    if (product.concentration.split(';').length > 3)
      return product.laboratory ?? '';
    const concentration = product.concentration
      .split(';')
      .slice(0, 3)
      .map((text) => text.trim().replace(/\(.*\)/g, ''))
      .join(' ; ');
    return `${concentration ?? ''} · ${product.laboratory ?? ''}`.replace(
      /^ · /,
      '',
    );
  }
  return `${product.concentration ?? ''} · ${product.laboratory ?? ''}`.replace(
    /^ · /,
    '',
  );
};
export const extractUnits = (product: Product): string => {
  const unit = (
    new RegExp(product.unit, 'i').exec(product.format ?? '')
      ? `${product.quantity ?? ''} ${product.format ?? ''}`
      : `${product.quantity ?? ''} ${product.unit ?? ''} ${
          product.format ?? ''
        }`
  ).trim();
  return unit;
};
