/*
 * File: currentUserPermissions.tsx
 * Project: meki
 * File Created: Friday, 19th February 2021 12:34:18 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Friday, 25th June 2021 11:49:24 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { User } from '@interfaces';

export const currentUserPermissions = () => gql`
  query currentUserPermissions {
    currentUserInfo {
      id
      uuid
      email
      permissions {
        id
        name
      }
    }
  }
`;

export interface CurrentUserPermissionsResponse {
  currentUserInfo: User;
}
