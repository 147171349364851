/*
 * File: productUtils.ts
 * Project: meki
 * File Created: Thursday, 10th December 2020 9:53:25 am
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Friday, 25th November 2022 11:41:56 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2020 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { AvailabilityType, Product } from '@interfaces';
import { OrderDetailSearch } from '@queries/order/queries';

export interface ProductDescription {
  mainTitle: string;
  quantityUnitFormat: string;
  principleConcentration: string;
  unitFormat: string;
  unit: string;
}

export function parseProductInfo(product: Product): ProductDescription {
  const parsedProduct: ProductDescription = {
    get mainTitle() {
      return product.name;
    },
    get quantityUnitFormat() {
      // if the container of the product is bigger or different than 1 it should be showed (since it is not the standard)
      const withContainer =
        product.containerQuantity !== '1' && product.containerQuantity !== '';
      let mappedcontainers: string[];
      // products that have multiple containers contain a ';' character.
      // When that happens we want to display every container with its info
      if (
        product.containerQuantity?.includes(';') &&
        product.container?.includes(';')
      ) {
        const containerQuantities = product.containerQuantity.split(';');
        const containers = product.container.split(';');
        // if containers also have units inside them we have to match every container to its content
        if (
          product.unit?.includes(';') &&
          product.quantity?.toString().includes(';')
        ) {
          const units = product.unit.split(';');
          const quantities = product.quantity.toString().split(';');
          mappedcontainers = containerQuantities.map((contQuan, i) => {
            // if the product have units and quantities we want to show them
            if (quantities[i] !== '' && units[i] !== '') {
              return (
                contQuan +
                ' ' +
                containers[i] +
                ' de ' +
                quantities[i] +
                ' ' +
                units[i]
              );
              // if it doesn't have quantity and unit we only display the container
            } else {
              return contQuan + ' ' + containers[i];
            }
          });
        } else {
          // when the prod has a container but no content we only display the container info
          mappedcontainers = containerQuantities.map(
            (contQuan, i) => contQuan + ' ' + containers[i],
          );
        }
      }
      // when the prod doesn't have several containers but we have different types of content we have display the container with it's content
      else if (
        product.unit?.includes(';') &&
        product.quantity?.toString().includes(';')
      ) {
        const units = product.unit.split(';');
        const quantities = product.quantity.toString().split(';');
        mappedcontainers = units.map((unit, i) => quantities[i] + ' ' + unit);
        return `${product.containerQuantity} ${
          product.container
        } de ${mappedcontainers.join(' y ')}`;
      }
      if (mappedcontainers) {
        // we only define mappedcontainers when we have several containers, here we return how the should be displayed
        return mappedcontainers.join(' y ');
      }
      if (product.isMedicine) {
        // standard case. Display the content of the container and the container if it is atypical (lines 87-92)
        return (
          (withContainer
            ? `${product.containerQuantity ?? ''} ${
                product.container ?? ''
              }`.trim()
            : '') +
          (withContainer &&
          product.quantity &&
          product.unit !== '' &&
          (product.containerQuantity || product.container)
            ? ' de '
            : '') +
          (product.quantity ? product.quantity.toString() : '') +
          ' ' +
          (product.format
            ? /comprimido/i.exec(product.format?.toLowerCase() ?? '')
              ? 'Comprimidos'
              : /(cápsula|capsula)/i.exec(product.format?.toLowerCase() ?? '')
              ? 'Cápsulas'
              : /^un$/i.exec(product.unit)
              ? product.quantity === 1
                ? 'unidad'
                : 'unidades'
              : /^-$/i.exec(product.unit)
              ? `${withContainer ? '•' : ''} ${product.format}`
              : `${product.unit} ${withContainer ? '• ' : ''}${product.format}`
            : product.name || '')
        );
      }

      return withContainer
        ? `${product.containerQuantity ?? ''} ${product.container ?? ''} ${
            product.quantity.toString() ?? ''
          } ${product.unit ?? ''}`.trim()
        : product.containerQuantity === '1' || !product.containerQuantity
        ? `${product.container ?? ''} ${product.quantity.toString() ?? ''} ${
            product.unit ?? ''
          }`.trim()
        : '';
    },
    get principleConcentration() {
      const activePrinciples = product.activePrinciple?.split(';') ?? [];
      const concentration = product.concentration?.split(';') ?? [];

      return activePrinciples
        .map((principle, i) => {
          return `${principle ?? ''} ${concentration[i] ?? ''}`.trim();
        })
        .join(' • ');
    },
    get unitFormat() {
      return product.format
        ? /comprimido/i.exec(product.format?.toLowerCase() ?? '')
          ? 'Comprimidos'
          : /(cápsula|capsula)/i.exec(product.format?.toLowerCase() ?? '')
          ? 'Cápsulas'
          : /^un$/i.exec(product.unit)
          ? product.quantity === 1
            ? 'unidad'
            : 'unidades'
          : /^-$/i.exec(product.unit)
          ? product.format
          : `${product.unit} ${product.format}`
        : product.name || '';
    },
    get unit() {
      return product.unit?.toLowerCase() ?? 'unidad';
    },
  };

  return parsedProduct;
}

export function findProductInOrder(
  productUuid: string,
  orderDetail?: OrderDetailSearch[],
): number | null {
  if (orderDetail) {
    const found = orderDetail.find(
      (orderDet) => orderDet.product?.uuid === productUuid,
    );
    if (found) {
      return found.quantity;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export const availabilityToSchema: Record<AvailabilityType, string> = {
  AVAILABLE: 'InStock',
  REPRESENTATION: 'InStock',
  AVAILABLE_24_HRS: 'InStock',
  HIDDEN: 'Discontinued',
  TO_CONFIRM: 'LimitedAvailability',
  UNAVAILABLE: 'OutOfStock',
  SOLD_OUT: 'OutOfStock',
  CHECK: 'LimitedAvailability',
};
