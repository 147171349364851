/*
 * File: searchElementItem.tsx
 * Project: meki
 * File Created: Thursday, 28th July 2022 9:34:55 am
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Tuesday, 16th January 2024 2:39:13 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, { useMemo } from 'react';
import {
  ListItemStyled,
  SkeletonDiv,
  TextSpan,
  SearchIconStyled,
  SkeletonStyled,
  Divider,
} from './searchElementItem.styles';
import { useWindowSize } from '@hooks/useWindowSize';

interface SearchElementItemProps {
  value: string;
  onClick?: () => void;
  loading?: boolean;
  index?: number;
}

export function SearchElementItem(props: SearchElementItemProps) {
  const { value, onClick, loading, index } = props;
  const size = useWindowSize();
  const desktop = useMemo(() => {
    if (!size.width) return true;
    return size.width >= 800;
  }, [size.width]);

  return (
    <>
      {index != 0 && <Divider />}
      <ListItemStyled desktop={desktop} onClick={onClick}>
        {loading ? (
          <SkeletonDiv>
            <SearchIconStyled />
            <SkeletonStyled />
          </SkeletonDiv>
        ) : (
          <>
            <TextSpan>
              {' '}
              <SearchIconStyled /> {value}
            </TextSpan>
          </>
        )}
      </ListItemStyled>
    </>
  );
}
