/*
 * File: useOrderState.tsx
 * Project: meki
 * File Created: Monday, 24th January 2022 4:39:46 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Thursday, 5th January 2023 1:26:21 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useQuery } from '@apollo/client';
import { useAuth } from '@hooks/useAuth';
import { useOrderState } from '@hooks/useOrderState';
import { Order, PillBox, PillBoxDetail } from '@interfaces';
import {
  fetchOrder,
  FetchOrderQueryResponse,
} from '@queries/order/queries/fetchOrder';
import {
  FetchNextOrdersQueryResponse,
  getNextOrders,
} from '@queries/order/queries/nextOrders';
import {
  getPillBoxUser,
  GetPillBoxUserResponseQuery,
} from '@queries/pillBox/queries/getPillBoxUser';
import { Console } from '@utils/logger';
import { useEffect, useMemo, useRef } from 'react';
import { createContext, useContext } from 'react';

interface PillBoxData {
  cart?: FetchOrderQueryResponse;
  orderUuid?: string;
  pillBox?: PillBox;
  noMoreLeft?: PillBoxDetail[];
  noMoreLeftCorrectFormat?: PillBoxDetail[];
  stillHave?: PillBoxDetail[];
  fetchingPillBox?: boolean;
  featchingNextOrders?: boolean;
  nextOrders?: Order[];
}

const PillBoxContext = createContext<PillBoxData>({
  cart: null,
  orderUuid: null,
  pillBox: null,
  noMoreLeft: [null],
  noMoreLeftCorrectFormat: [null],
  stillHave: [null],
  fetchingPillBox: null,
  nextOrders: null,
});

export function usePillBoxState() {
  return useContext(PillBoxContext);
}
type PillBoxProviderProps = {
  children: React.ReactNode;
};
const TAG = '[PILL_BOX_STATE]';
export function PillBoxProvider({ children }: PillBoxProviderProps) {
  const { appUser } = useAuth();
  const {
    data: { pillBox } = {},
    loading: fetchingPillBox,
    refetch,
  } = useQuery<GetPillBoxUserResponseQuery>(getPillBoxUser(), {
    fetchPolicy: 'cache-and-network',
    skip: !appUser,
  });

  const { data: { nextOrders } = {}, loading: featchingNextOrders } =
    useQuery<FetchNextOrdersQueryResponse>(getNextOrders(), {
      fetchPolicy: 'cache-and-network',
      skip: !appUser,
    });

  const nextOrdersRef = useRef(nextOrders);

  const nextOrdersFiltered = nextOrders
    ?.map((order) => {
      const orderDetailsFiltered = order.orderDetails;
      if (orderDetailsFiltered.length > 0) {
        return {
          ...order,
          orderDetails: orderDetailsFiltered,
        };
      }
    })
    .filter(Boolean);

  const { orderUuid } = useOrderState();

  const { data: cart } = useQuery<FetchOrderQueryResponse>(
    fetchOrder('addFetchOrderQuery'),
    {
      variables: {
        params: { uuid: orderUuid },
      },
      skip: !orderUuid,
    },
  );

  const noMoreLeft = useMemo(() => {
    const pillBoxDetails = pillBox?.pillBoxDetails?.filter((detail) => {
      return (!detail.stillHave || !detail.allowedFormat) && !detail.hidden;
    });

    return pillBoxDetails;
  }, [pillBox]);

  const noMoreLeftCorrectFormat = useMemo(() => {
    const pillBoxDetails = pillBox?.pillBoxDetails?.filter((detail) => {
      return !detail.stillHave && detail.allowedFormat && !detail.hidden;
    });

    return pillBoxDetails;
  }, [pillBox]);

  const stillHave = useMemo(() => {
    const pillBoxDetails = pillBox?.pillBoxDetails?.filter((detail) => {
      return detail.stillHave && detail.allowedFormat && !detail.hidden;
    });

    return pillBoxDetails?.sort((detailA, detailB) => {
      return detailA.userStock - detailB.userStock;
    });
  }, [pillBox]);

  useEffect(() => {
    if (appUser && !fetchingPillBox && !pillBox) {
      Console.log(TAG + ' Refetch due user change', { appUser });

      void refetch().then((res) => {
        Console.log(TAG + ' Refetched current pillBox', { res });
      });
    }
  }, [appUser, fetchingPillBox, refetch, pillBox]);

  useEffect(() => {
    if (!nextOrdersRef.current) return;
    if (nextOrders !== nextOrdersRef.current) {
      // Handle change from null to ''
      Console.log(TAG + ' Refetch due nextOrders change', {
        nextOrders,
        nextOrdersRef: nextOrdersRef.current,
      });

      void refetch().then((res) => {
        Console.log(TAG + ' Refetched current nextOrders', { res });
      });
    }
    nextOrdersRef.current = nextOrders;
  });

  const pillBoxState = useMemo<PillBoxData>(() => {
    Console.log(TAG, {
      cart,
      orderUuid,
      pillBox,
      noMoreLeft,
      noMoreLeftCorrectFormat,
      stillHave,
      fetchingPillBox,
      featchingNextOrders,
      nextOrders,
    });
    return {
      cart,
      orderUuid,
      pillBox,
      noMoreLeft,
      noMoreLeftCorrectFormat,
      stillHave,
      fetchingPillBox,
      nextOrders: nextOrdersFiltered,
      featchingNextOrders,
    };
  }, [
    cart,
    orderUuid,
    pillBox,
    noMoreLeft,
    noMoreLeftCorrectFormat,
    stillHave,
    fetchingPillBox,
    featchingNextOrders,
    nextOrders,
    nextOrdersFiltered,
  ]);

  return (
    <PillBoxContext.Provider value={pillBoxState}>
      {children}
    </PillBoxContext.Provider>
  );
}
