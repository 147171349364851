/*
 * File: usePermissions.tsx
 * Project: meki
 * File Created: Friday, 19th February 2021 12:54:59 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Friday, 25th June 2021 11:57:24 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  currentUserPermissions,
  CurrentUserPermissionsResponse,
} from '@queries/user/queries';
import { useAuth } from './useAuth';

export type ActionType =
  | 'MANAGE_USER'
  | 'MANAGE_SELF'
  | 'MANAGE_PRODUCTS'
  | 'MANAGE_ORDERS'
  | 'MANAGE_PAYMENTS'
  | 'MANAGE_PRICES'
  | 'MANAGE_ALL'
  | 'LOCAL_VENDOR';

interface UsePermissionResponse {
  userCan: (action: ActionType) => boolean;
}

export function usePermissions(): UsePermissionResponse {
  const { appUser: user } = useAuth();
  // queries:
  const { data } = useQuery<CurrentUserPermissionsResponse>(
    currentUserPermissions(),
    { skip: !user, fetchPolicy: 'cache-first' },
  );

  // Other variables:
  const permissions = useMemo(() => {
    if (!data) return [];
    const permissions = data?.currentUserInfo?.permissions;
    return permissions ?? [];
  }, [data]);

  const userCan = useCallback(
    (action: ActionType) => {
      return permissions.some((p) => p.name === action);
    },
    [permissions],
  );

  return { userCan };
}
