/*
 * File: useAddProduct.tsx
 * Project: meki
 * File Created: Friday, 29th January 2021 5:27:23 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Friday, 13th January 2023 5:23:16 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useCallback } from 'react';
import { useToast } from '@hooks/toast.hook';
import {
  useMutation,
  MutationFunctionOptions,
  FetchResult,
  makeReference,
} from '@apollo/client';
import {
  updateProductInOrder,
  UpdateProductInOrderMutationParams,
  UpdateProductInOrderMutationResponse,
} from '@queries/product/mutations';
import { useOrderState } from './useOrderState';
import { useMekiState } from './useMekiState';
import { OrderDetail, Product } from '@interfaces';
import { Console } from '@utils';
import { useTagManagerEvent } from './useTagManagerEvent';
import { useSegment } from './useSegment';
import { useAuth } from './useAuth';
import { useCartMenu } from './useCartMenu';

interface HandleAddProductProps {
  puuid: string;
  prid?: string;
  retained?: string;
  orderDetail?: OrderDetail;
  product: Product;
}

interface UseAddProductReturn {
  addProduct: (
    options?: MutationFunctionOptions<
      UpdateProductInOrderMutationResponse,
      UpdateProductInOrderMutationParams
    >,
  ) => Promise<FetchResult<UpdateProductInOrderMutationResponse>>;
  handleAddProduct: ({
    puuid,
    prid,
    retained,
    orderDetail,
  }: HandleAddProductProps) => Promise<void>;
  loading: boolean;
}

interface UseAddProductProps {
  location: string;
  index?: number;
}

export const useAddProduct: (props: UseAddProductProps) => UseAddProductReturn =
  function (props: UseAddProductProps): UseAddProductReturn {
    // Hooks init:
    const { orderUuid, setOrderUuid } = useOrderState();
    const { setUploadedPrescription } = useMekiState();
    const { showToast } = useToast();
    const { addEcommerceEvent } = useTagManagerEvent();
    const { track } = useSegment();
    const { location, index } = props;
    const { appUser: user } = useAuth();
    const {
      startLoading: startCartMenuLoading,
      stopLoading: stopCartMenuLoading,
    } = useCartMenu();

    // add Product Mutation
    const [addProduct, { loading }] = useMutation<
      UpdateProductInOrderMutationResponse,
      UpdateProductInOrderMutationParams
    >(updateProductInOrder('useAddProduct'), {
      update(cache, { data: { updateProductInOrder } }) {
        const newOrderUuid = updateProductInOrder.order.uuid;

        if (!orderUuid || orderUuid !== newOrderUuid) {
          cache.modify({
            id: cache.identify(makeReference('ROOT_QUERY')),
            fields: {
              currentOrder() {
                return updateProductInOrder.order;
              },
            },
          });
          Console.log({
            msg: '[useAddProduct] change order uuid create',
            orderUuid: newOrderUuid,
          });

          setOrderUuid(newOrderUuid);
        }
      },
    });

    const handleAddProduct = useCallback(
      async ({ puuid, prid, orderDetail, product }: HandleAddProductProps) => {
        startCartMenuLoading();
        const params = {
          orderUuid,
          productUuid: puuid,
        };
        addEcommerceEvent('add_to_cart', {
          items: [
            {
              item_brand: product.laboratory,
              item_name: product.name,
              item_id: String(product.id),
              price: product.price,
              quantity: 1,
              prescription_type: product?.prescriptionType,
              availability: product?.availability?.status,
            },
          ],
        });

        void track('product add to cart', {
          id: product?.id,
          name: product?.name,
          brand: product?.makerBrand ?? product?.laboratory,
          prescriptionType: product?.prescriptionType ?? 'null',
          quantity: 1,
          price: product?.price,
          availability: product?.availability?.status,
          user_id: user?.id,
          location,
          index,
        });
        try {
          if (!orderDetail) {
            await addProduct({
              variables: {
                params,
              },
            });
          }
          if (prid) {
            setUploadedPrescription(null);
          }
        } catch (e) {
          showToast({
            type: 'error',
            message: 'Hubo un error al agregar el producto. Intenta de nuevo',
          });
          stopCartMenuLoading();
        }
        setTimeout(() => {
          stopCartMenuLoading();
        }, 1000);
      },
      [
        startCartMenuLoading,
        stopCartMenuLoading,
        orderUuid,
        addEcommerceEvent,
        track,
        user?.id,
        location,
        index,
        addProduct,
        setUploadedPrescription,
        showToast,
      ],
    );

    return { addProduct, handleAddProduct, loading };
  };
