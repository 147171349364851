/*
 * File: environment.ts
 * Project: meki
 * File Created: Monday, 14th September 2020 11:28:45 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Monday, 22nd January 2024 10:57:54 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

export const ENV: string = process.env.NODE_ENV || 'development';
export const MEKI_ENV: string = process.env.MEKI_ENV; // 'local' | 'staging' | 'production'
export const TEST_ENV = Boolean(
  /true/i.exec(process.env.NEXT_PUBLIC_TEST_ENV?.trim()),
);
export const GRAPHQL_URI =
  process.env.MEKI_GRAPHQL_URI ||
  'https://meki-api-gateway-staging.herokuapp.com';
export const API_SECRET = process.env.MEKI_API_SECRET;
export const BASE_URL = process.env.MEKI_BASE_URL || 'https://farmaciameki.cl';

export const ALLOW_LOGS =
  ENV === 'development' ||
  Boolean(/true/i.exec(process.env.MEKI_ALLOW_LOGS?.trim()));

export const FIREBASE_API_KEY = process.env.MEKI_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.MEKI_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE = process.env.MEKI_FIREBASE_DATABASE;
export const FIREBASE_PROJECT_ID = process.env.MEKI_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.MEKI_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_SENDER_ID = process.env.MEKI_FIREBASE_SENDER_ID;
export const FIREBASE_APP_ID = process.env.MEKI_FIREBASE_APP_ID;
export const GOOGLE_API_KEY = process.env.MEKI_GOOGLE_API_KEY;
export const CRISP_ID = TEST_ENV ? '' : process.env.MEKI_CRISP_ID;

export const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const NEXT_PUBLIC_SENTRY_ENVIRONMENT =
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
export const SENTRY_ORG = process.env.SENTRY_ORG;
export const SENTRY_PROJECT = process.env.SENTRY_PROJECT;
export const SENTRY_AUTH_TOKEN = process.env.SENTRY_AUTH_TOKEN;

export const MEILISEARCH_HOST = process.env.MEKI_MEILISEARCH_HOST;
export const MEILISEARCH_KEY = process.env.MEKI_MEILISEARCH_KEY;
export const USE_ALGOLIA_SUGGESTIONS = Boolean(
  /true/i.exec(process.env.MEKI_USE_ALGOLIA_SUGGESTIONS),
);
export const NEXT_PUBLIC_LOGROCKET_ID = process.env.NEXT_PUBLIC_LOGROCKET_ID;
export const GOOGLE_TAG_MANAGER = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER;
export const NEXT_PUBLIC_SEGMENT_ID = process.env.NEXT_PUBLIC_SEGMENT_ID;
export const NEXT_PUBLIC_AMPLITUDE_API_KEY =
  process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;

export const NEXT_PUBLIC_PIXEL_ID = process.env.NEXT_PUBLIC_PIXEL_ID;

export const NEXT_PUBLIC_NO_PRICE_FALLBACK =
  process.env.NEXT_PUBLIC_NO_PRICE_FALLBACK || '';

export const MEKI_WORKING_ON = Boolean(
  /true/i.exec(process.env.MEKI_WORKING_ON),
);
export const MEKI_ALLOW_ROBOTS = Boolean(
  /true/i.exec(process.env.MEKI_ALLOW_ROBOTS),
);

export const isSafari = (() => {
  if (typeof window === 'undefined') return false;
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.includes('chrome')) return false;
  if (userAgent.includes('safari')) return true;
  return false;
})();

export const OPEN_REPLAY_KEY = process.env.MEKI_OPEN_REPLAY_KEY;
export const OPEN_REPLAY_INGEST = process.env.MEKI_OPEN_REPLAY_INGEST;
export const DISABLE_OPEN_REPLAY_SECURE_MODE = Boolean(
  /true/i.exec(process.env.DISABLE_OPEN_REPLAY_SECURE_MODE),
);
export const NO_API_STATIC_GENERATION = Boolean(
  /true/i.exec(process.env.MEKI_NO_API_STATIC_GENERATION),
);

export const ALWAYS_RECORD = Boolean(
  /true/i.exec(process.env.NEXT_PUBLIC_ALWAYS_RECORD),
);

export const FINTOC_TOKEN = process.env.MEKI_FINTOC_TOKEN;
export const MEKI_FINTOC_WEEBHOOK = process.env.MEKI_FINTOC_WEEBHOOK;

export const BANCO_ESTADO_EMAILS_ALLOWED = process.env
  .MEKI_BANCO_ESTADO_EMAILS_ALLOWED
  ? process.env.MEKI_BANCO_ESTADO_EMAILS_ALLOWED.split(',')
  : [];

export const INSURANCE_EMAILS_ALLOWED = process.env
  .MEKI_INSURANCE_EMAILS_ALLOWED
  ? process.env.MEKI_INSURANCE_EMAILS_ALLOWED.split(',')
  : null;
