/*
 * File: currentOrder.ts
 * Project: meki
 * File Created: Tuesday, 4th May 2021 10:29:04 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Saturday, 15th October 2022 12:18:23 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { Order } from '@interfaces';
import { ResolverParams } from '@queries/queries.interfaces';

export const currentOrder = () => gql`
  query currentOrder($params: CurrentOrderParams) {
    currentOrder(params: $params) {
      id
      uuid
      orderGroupId
      status
      deliveryType
      orderDetails {
        id
        quantity
        product {
          id
          uuid
          imagesUrl
          prescriptionType
        }
        order {
          id
        }
      }
      paymentMethod {
        id
        uuid
        type
        data
      }
      address {
        id
        name
        street
        streetNumber
        commune
      }
      user {
        id
      }
      metadata {
        userInfo {
          email
          name
          rut
        }
        useMekiCredits
        installments
        localVendor {
          paymentMethod
          mixPayments
        }
        assistantVendor {
          paymentMethod
        }
        sendEmail
        chargeDeliveryCost
        insuranceInfo {
          claimId
          insuranceId
          rut
          totalReimbursement
          totalDeductible
          totalCopay
          reimbursedProducts {
            sku
            observation
            unitReimbursement
            unitCopay
            unitDeductible
            quantity
            coverage
          }
        }
      }
    }
  }
`;

export type CurrentOrderQueryResponse = { currentOrder: Order };

export type CurrentOrderQueryParams = ResolverParams<{ orderUuid?: string }>;
