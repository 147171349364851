/*
 * File: searchBar.tsx
 * Project: meki
 * File Created: Friday, 25th September 2020 3:38:53 pm
 * Author: Vicente Melin (vicente@inventures.cl)
 * -----
 * Last Modified: Wednesday, 3rd August 2022 3:47:08 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { ProductSuggestions } from '@interfaces';

export const searchBarQuery = (name: string) => gql`
  query ${name}($params: ProductsParams) {
    productSuggestions(params: $params ) {
      text
      type
    }
  }
  `;
export type SearchBarQueryResponse = {
  productSuggestions: ProductSuggestions[];
};
