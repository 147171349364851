import React, { useState, useEffect } from 'react';
import { useModalRouter } from '@hooks/useModalRouter';
import { useRouter } from 'next/router';
import { removeUndefineds } from '@utils';
import { useAuth } from '@hooks/useAuth';
import { useQuery } from '@apollo/client';
import {
  currentUserCredits,
  CurrentUserCreditsResponse,
} from '@queries/user/queries';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { UserInfoSection } from './UserInfoSection';
import { CurrencyFormatter } from '@utils/formatters/CurrencyFormatter';
import { NEXT_PUBLIC_NO_PRICE_FALLBACK } from '@config/environment';
import {
  CreditContainer,
  CreditsIconContainer,
  DrawerMenuDivider,
  DescriptionTypography,
  TitleTypography,
  MenuSubContainer,
  LoginButton,
  CreditsTypography,
} from '../styles';

interface LoginMenuProps {
  closeDrawerMenu: () => void;
}

export function LoginMenu(props: LoginMenuProps) {
  const { closeDrawerMenu } = props;
  const { appUser: user } = useAuth();

  const name = user?.name.charAt(0).toUpperCase() + user?.name.slice(1) || '';
  const [credits, setCredits] = useState(user?.credits || 0);
  const router = useRouter();
  const modalRouter = useModalRouter();

  const { data } = useQuery<CurrentUserCreditsResponse>(currentUserCredits(), {
    skip: !user,
  });

  const handleSignIn = () => {
    closeDrawerMenu();
    void router.push(
      {
        pathname: '/inicio',
        query: removeUndefineds({
          ...router.query,
          pathname: '/ingresar',
          codigo: undefined,
        }),
      },
      {
        pathname: '/ingresar',
        query: removeUndefineds({
          ...modalRouter.query,
          codigo: undefined,
        }),
      },
    );
  };

  const currFormat = new CurrencyFormatter({
    fallback: NEXT_PUBLIC_NO_PRICE_FALLBACK,
  });

  useEffect(() => {
    setCredits(data?.currentUserInfo?.credits || user?.credits || 0);
  }, [data, user?.credits]);

  return (
    <>
      {!user ? (
        <MenuSubContainer>
          <TitleTypography>¡Hola!</TitleTypography>
          <DescriptionTypography>
            Ingresa a tu cuenta para ver tus pedidos, boletas, etc.
          </DescriptionTypography>

          <DrawerMenuDivider />

          <LoginButton onClick={handleSignIn}>Iniciar sesión</LoginButton>
        </MenuSubContainer>
      ) : (
        <MenuSubContainer>
          <TitleTypography>¡Hola {name}!</TitleTypography>
          {credits !== 0 ? (
            <CreditContainer>
              <CreditsIconContainer>
                <InfoOutlinedIcon fontSize="small" />
              </CreditsIconContainer>
              <CreditsTypography>
                Tienes {currFormat.format(credits)} en creditos Meki para
                utilizarlos en tu compra
              </CreditsTypography>
            </CreditContainer>
          ) : (
            <></>
          )}

          <DrawerMenuDivider />
          <UserInfoSection closeDrawerMenu={closeDrawerMenu} />
        </MenuSubContainer>
      )}
    </>
  );
}
