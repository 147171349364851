/*
 * File: currentTerms.tsx
 * Project: meki
 * File Created: Thursday, 9th September 2021 4:22:39 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Wednesday, 15th September 2021 10:46:11 am
 * Modified By: Luis Aparicio (luis@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { gql } from '@apollo/client';
import { Terms } from '@interfaces';

export const currentTermsInfo = (name = 'currentTermsInfo') => gql`
  query ${name} {
    currentTerms {
      id
      uuid
      url
    }

    currentUserInfo {
      id
      acceptedTerms
    }
  }
`;

export interface CurrentTermsResponse {
  currentTerms: Terms;
  currentUserInfo: {
    acceptedTerms: boolean;
  };
}
