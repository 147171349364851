/*
 * File: useCartMenu.tsx
 * Project: meki
 * File Created: Friday, 22nd July 2022 2:24:11 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Tuesday, 26th July 2022 7:05:30 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, { useCallback, useContext, useState, useRef } from 'react';

interface CartMenu {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
  stopCartMenuRef: React.MutableRefObject<NodeJS.Timeout | null> | null;
}

const CartMenuContext = React.createContext<CartMenu>({
  isOpen: false,
  open: () => {},
  close: () => {},
  isLoading: false,
  startLoading: () => {},
  stopLoading: () => {},
  stopCartMenuRef: null,
});

export const useCartMenu = () => {
  const value = useContext(CartMenuContext);
  return value;
};

interface CartMenuProviderProps {
  children: React.ReactNode;
}

export const CartMenuProvider = ({ children }: CartMenuProviderProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const startLoading = useCallback(() => setLoading(true), []);
  const stopLoading = useCallback(() => setLoading(false), []);
  const stopCartMenuRef = useRef<NodeJS.Timeout>();

  return (
    <CartMenuContext.Provider
      value={{
        isOpen: open,
        open: () => setOpen(true),
        close: () => setOpen(false),
        isLoading: loading,
        startLoading,
        stopLoading,
        stopCartMenuRef,
      }}
    >
      {children}
    </CartMenuContext.Provider>
  );
};
