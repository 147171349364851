/*
 * File: useTrack.ts
 * Project: meki
 * File Created: Saturday, 16th January 2021 11:48:44 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Saturday, 19th November 2022 1:33:43 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { User } from 'firebase/auth';
import { useEffect, useRef } from 'react';
import { useAuth } from './useAuth';
import { initializeLogRocket } from '@utils/logrocket';

import {
  NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_LOGROCKET_ID,
} from '@config/environment';
import { getOpenreplay } from '@services/openreplay';
import { getSentry } from '@services/sentry';

const getLogRocket = (() => {
  let logrocket: {
    sessionURL: string;
    identify: (...params) => void;
    startNewSession: () => void;
  };
  return async () => {
    if (logrocket) return logrocket;
    logrocket = await import('logrocket').then((e) => e.default);
    return logrocket;
  };
})();
initializeLogRocket();
export function useTrack() {
  const userRef = useRef<null | User>(null);
  const { firebaseUser: user } = useAuth();
  useEffect(() => {
    void getSentry().then((Sentry) => {
      Sentry.configureScope(
        (scope: { setExtra: (...params: unknown[]) => void }) => {
          if (typeof window !== 'undefined') {
            const interval = setInterval(() => {
              if (window.logrocketInitialized) {
                clearInterval(interval);
                void (async () =>
                  scope.setExtra(
                    'sessionURL',
                    (await getLogRocket()).sessionURL,
                  ))();
              }
            }, 200);
          }
        },
      );
    });
  }, []);
  useEffect(() => {
    const trackUser = () => {
      if (NEXT_PUBLIC_LOGROCKET_ID) {
        const interval = setInterval(() => {
          if (window.logrocketInitialized) {
            clearInterval(interval);
            void (async () =>
              (await getLogRocket()).identify(user.uid, {
                email: user.email,
              }))();
          }
        }, 200);
      }

      if (NEXT_PUBLIC_SENTRY_DSN)
        void getSentry().then((Sentry) => {
          Sentry.setUser({ email: user.email });
        });
      void getOpenreplay().then((openReplay) => {
        openReplay?.setUserID(user.email);
      });
    };
    // user has signin
    if (!userRef.current && user) {
      trackUser();
    }

    const stopTrackingUser = () => {
      if (NEXT_PUBLIC_LOGROCKET_ID) {
        const interval = setInterval(() => {
          if (window.logrocketInitialized) {
            clearInterval(interval);
            void (async () => (await getLogRocket()).startNewSession());
          }
        }, 200);
      }
      if (NEXT_PUBLIC_SENTRY_DSN)
        void getSentry().then((Sentry) => {
          Sentry.configureScope(
            (scope: { setUser: (param: unknown) => void }) =>
              scope.setUser(null),
          );
        });
    };
    //user has signout
    if (userRef.current && !user) {
      stopTrackingUser();
    }
    userRef.current = user;
  }, [user]);
}
