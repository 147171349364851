/*
 * File: badges.tsx
 * Project: meki
 * File Created: Thursday, 29th April 2021 12:47:09 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Thursday, 16th November 2023 6:17:46 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import LocalPharmacyRoundedIcon from '@mui/icons-material/LocalPharmacyRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import { next24hrsDay, formatDayName, getDaysDifference } from '@utils/date';
import { styled } from '@mui/system';

const PharmaBadgeDiv = styled('div')({
  backgroundColor: '#2196f3',
  borderRadius: '20px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  color: '#FFFFFF',
  padding: '2px 4px',
});
const CheckBadgeTypography = styled(Typography)({
  fontSize: 14,
});
const LocalPharmacyRoundedIconStyled = styled(LocalPharmacyRoundedIcon)({
  height: '15px',
  width: '15px',
  alignSelf: 'baseline',
});
const UnavailableBadgeDiv = styled('div', {
  shouldForwardProp: (propName) => propName !== 'warn',
})<{ warn: boolean }>(({ warn }) => ({
  borderRadius: '20px',
  height: '20px',
  width: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  color: '#FFFFFF',
  padding: '2px',
  backgroundColor: warn ? '#ff9800' : 'red',
}));
const WarningRoundedIconStyled = styled(WarningRoundedIcon)({
  height: '15px',
  width: '15px',
  alignSelf: 'baseline',
});
const WarnigDiv = styled('div')({
  backgroundColor: '#ff9800',
  borderRadius: '20px',
  height: '20px',
  width: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  color: '#FFFFFF',
  padding: '2px',
});
const QueryBuilderOutlinedIconStyled = styled(QueryBuilderOutlinedIcon)({
  height: '15px',
  width: '15px',
  alignSelf: 'baseline',
});
const LocalShippingOutlinedIconStyled = styled(LocalShippingOutlinedIcon)({
  marginLeft: 4,
  transform: 'rotateY(180deg)',
  color: '#4caf50',
  height: '15px',
  width: '15px',
  alignSelf: 'center',
});
const DeliveryExpressDiv = styled('div', {
  shouldForwardProp: (propName) => propName !== 'displayInlineFlex',
})<{ displayInlineFlex: boolean }>(({ displayInlineFlex }) => ({
  backgroundColor: '#edf6ed',
  color: '#1e4620',
  borderRadius: 10.5,
  height: 21,
  display: displayInlineFlex ? 'inline-flex' : 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  padding: 2,
}));
const DeliveryTextTypography = styled(Typography)({ margin: '0 5px' });

export function CheckBadge() {
  return (
    <PharmaBadgeDiv>
      <LocalPharmacyRoundedIconStyled />
      <CheckBadgeTypography>Venta presencial</CheckBadgeTypography>
    </PharmaBadgeDiv>
  );
}
export function UnavailableBadge({ warn }: { warn?: boolean }) {
  return (
    <UnavailableBadgeDiv warn={warn}>
      <WarningRoundedIconStyled />
    </UnavailableBadgeDiv>
  );
}
export function ToConfirmBadge() {
  return (
    <WarnigDiv>
      <QueryBuilderOutlinedIconStyled />
    </WarnigDiv>
  );
}
export function PharmaBadge() {
  return (
    <PharmaBadgeDiv>
      <LocalPharmacyRoundedIconStyled />
      <Typography>Liga</Typography>
    </PharmaBadgeDiv>
  );
}
export function DeliveryExpressBadge({
  displayInlineFlex,
}: {
  displayInlineFlex?: boolean;
}) {
  const next24hrs = useMemo(() => {
    const nextDay = next24hrsDay();
    if (nextDay.toDateString() === new Date(Date.now()).toDateString()) {
      return 'Llega hoy';
    }
    const differenceInDays = Math.abs(getDaysDifference(nextDay));
    if (differenceInDays === 1) {
      return 'Llega mañana';
    }
    return `Llega el ${formatDayName(nextDay).toLowerCase()}`;
  }, []);

  return (
    <DeliveryExpressDiv displayInlineFlex={displayInlineFlex}>
      <LocalShippingOutlinedIconStyled />
      <DeliveryTextTypography variant="caption">
        {next24hrs}
      </DeliveryTextTypography>
    </DeliveryExpressDiv>
  );
}
