/*
 * File: products.tsx
 * Project: meki
 * File Created: Friday, 25th September 2020 5:20:40 pm
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Friday, 26th January 2024 5:48:01 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2020 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { ProductConnection } from '@interfaces';
import { ProductBaseSearchFragment } from '../fragments';

export const ProductQuery = (name: string) => gql`
    query ${name} ($params: ProductsParams){
        products(params: $params) {
          nodes{
            ...ProductBaseSearch
          }
          pageInfo{
            totalPages
            pageNumber
          }
          totalCount
          suggestionText
          query
          pageNumber
          suggestionNodes {
            ...ProductBaseSearch
          }
          perfectMatchProducts {
            ...ProductBaseSearch
          }
      }
    }
    ${ProductBaseSearchFragment}
`;

export type ProductQueryResponse = {
  products: ProductConnection;
};
export type ProductQueryParams = {
  params: {
    query: string;
    pageNumber?: number;
  };
};
